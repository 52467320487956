import {Component, OnInit, TrackByFunction} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Router} from '@angular/router';
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {UsersService} from "../../../../services/users.service";
import {ApiService} from "../../../../services/api.service";
import {NotificationService} from "../../../../services/notification.service";
import {UserInterface} from "../../../../core/models/User";
import {StrapiModel} from '../../../../core/models/StrapiModel';
import {GroupAttributes} from '../../../../core/models/definitions/GroupDefinition';
import {INotification} from "../../../../components/molecules/notification/notification.component";

export interface Activity {
  id: number;
  type: 'friend_request'|'friend_request_accept'|'friend_request_decline'|'new_post_in_group'|'new_comment_in_group_post' | 'new_subscription_invite' | 'denied_subscription_invite' | 'accept_subscription_invite' | 'delete_subscription_invite' | 'group_request_accepted';
  feedId: string;
  data: { [key: string]: any };
  read: boolean;
  created: string;
}

@Component({
  selector: 'app-my-page-notifications',
  templateUrl: './my-page-notifications.component.html',
  styleUrls: ['./my-page-notifications.component.scss']
})
export class MyPageNotificationsComponent implements OnInit {
  readonly localStorageUsersKey = 'users';
  users: {[userStrapiId: number]: UserInterface} = {};
  groups$: Observable<StrapiModel<GroupAttributes>[]>;
  notificationData$: Observable<{
    feedList: Activity[], 
    countFeed: number
  }>
  constructor(
    private usersService: UsersService,
    private apiService: ApiService,
    protected dialog: MatDialog,
    private router: Router,
    protected notificationService: NotificationService
  ) {
    const usersData = localStorage.getItem(this.localStorageUsersKey);
    if (usersData) {
      this.users = JSON.parse(usersData);
    }
    this.groups$ = this.usersService.currentGroups();
  }

  ngOnInit() {
    this.notificationData$ = combineLatest([
      this.notificationService.getFeedList(),
      this.notificationService.getCountFeed()
    ]).pipe(
      map(([
        feedList, countFeed
      ]) => ({ 
        feedList, countFeed
      }))
    )
  }

  trackBy(): TrackByFunction<any> {
    return (index, item) => {
      return item.id;
    }
  }

  activityToNotification(activity: Activity, user: UserInterface): INotification {
    return {
      label: this.getActivityLabel(activity),
      id: activity.id,
      user: user,
      date: activity.created,
      isRead: activity.read
    }
  }

  onNotificationClick(activity: Activity) {
    if (!activity.read) {
      this.apiService.api.notifications().markAsRead(activity.id).executePromise();
      activity.read = true;
      this.notificationService.reduceCountFeed(activity);
    }
    switch (activity.type) {
      case 'new_post_in_group':
      case 'new_comment_in_group_post':
        if(typeof(activity?.data?.groupId) !== 'undefined'){
          this.router.navigate(["/groups/group/" + activity?.data?.groupId], {
            queryParams: {
              tabIndex: 1
            },
          });
        }
        break;
      case 'new_subscription_invite':
        this.router.navigate(["/profile/plans"]);
        break;
      case 'group_request_accepted':
        this.router.navigate(["/groups/group/" + activity?.data?.groupId]);
        break;
      default:
        if (['friend_request','friend_request_accept','friend_request_decline'].indexOf(activity.type) !== -1) {
          this.usersService.openDefaultProfileDialog({user: this.users[activity.data.userId] as any});
        }
        break;
    }
  }

  getActivityLabel(activity: Activity): string {
    const user = this.users?.[activity?.data?.userId];
    const name = user?.id > 0 ? `${user.first_name} ${user.last_name}` : '??';
    switch (activity.type) {
      case "friend_request":
        return `Venneanmodning fra ${name}`;
      case "friend_request_accept":
        return `${name} har accepteret din venneanmodning`;
      case "friend_request_decline":
        return `${name} har afvist din venneanmodning`;
      case "new_post_in_group":
      case "new_comment_in_group_post":
        let msg;
        let msgContent;
        switch (activity.type) {
          case 'new_post_in_group':
            msgContent = 'Har lagt et nyt indlæg op i gruppen';
            break;
          case 'new_comment_in_group_post':
            msgContent = 'Svarede på et indlæg i gruppen';
            break;
          default:
            break;
        }
        this.groups$.subscribe(res => {
          if(res?.length > 0){
            if(typeof(activity?.data?.groupId) !== 'undefined'){
              let findGroup = res.find(g => g.id === activity?.data?.groupId);
              if(typeof(findGroup) !== 'undefined'){
                msg = `${name} ${msgContent} ${findGroup['attributes']['name']}`;
              }
            }
          }
        })
        return msg;

      case "new_subscription_invite":
        return `${name} vil tilføje dig til sit abonnement`;
      case "denied_subscription_invite":
        return `${name} har afslået invitationen til dit abonnement`;
      case "accept_subscription_invite":
        return `${name} har accepteret invitationen til dit abonnement`;
      case "delete_subscription_invite":
        return `${name} har slettet invitationen til deres abonnement`;
      case "group_request_accepted":
        return `${name} har godkendt anmodningen om at blive medlem af gruppen`;
      default:
        return '???'
    }
  }
}
