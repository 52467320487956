import { Injector } from '@angular/core';

export class AppInjector {
    private static instance: AppInjector;
    private injector: Injector;

    constructor() {
        //throw new Error("Singleton - can't instantiate.");
    }

    public static getInstance() {
        if (!AppInjector.instance) {
            AppInjector.instance = new AppInjector();
        }

        return AppInjector.instance;
    }

    public setInjector(injector: Injector) {
        this.injector = injector;
    }

    public getInjector(): Injector {
        return this.injector;
    }
}
