/**
 * Created by ModelParser
 * Date: 09-06-2020.
 * Time: 08:36.
 */
import { ChannelDefinition, ChannelDefinitionInterface } from './definitions/ChannelDefinition';

export interface ChannelInterface extends ChannelDefinitionInterface {

}

export class Channel extends ChannelDefinition implements ChannelInterface {

    constructor(json?: any) {
        super(json);
    }

}
