/**
 * Created by ModelParser
 */
import { Message, MessageInterface } from '../Message';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface MessageRecipientDefinitionInterface {
    message_id?: number;
    message?: MessageInterface;
    user_id?: number;
    user?: UserInterface;
    read?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class MessageRecipientDefinition extends BaseStoredModel implements MessageRecipientDefinitionInterface {
    message_id?: number;
    message?: Message;
    user_id?: number;
    user?: User;
    read?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.message_id;
            delete this.message;
            delete this.user_id;
            delete this.user;
            delete this.read;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.message_id != null) {
            this.message_id = data.message_id;
        }
        if (data.message != null) {
            this.message = new Message(data.message);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.read != null) {
            this.read = data.read;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
