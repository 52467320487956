<div
    class="sign-up-page bg-blue"
    *ngIf="me$|async as me"
>
    <div class="header-splash flex justify-content-center">
    </div>

    <div class="section-wrapper" *ngIf="!isUserComplete(me)">
        <header class="md:w-auto w-full">
            <div class="bg-white p-4 flex justify-center items-center w-full py-12 
              md:px-48 md:rounded-[14px]
              px-4 rounded-none"
            >
              <ng-container *ngIf="getPage(me) as page">
                <div class="flex flex-col items-center max-w-[290px] text-center py-[180px]" *ngIf="page === 'verify'">
                  <app-icon class="h-20" icon="logo-famoba" color="orange"></app-icon>
                  <h2 class="mt-4">Verificer din email</h2>
                  <p class="mt-1">Tak for din tilmelding! Så snart du har verificeret din email kan du udforske Famobas forældre univers.</p>
                </div>
                <app-signup-name-form *ngIf="page === 'name'" [user]="me" class="max-w-[350px]"></app-signup-name-form>
                <app-signup-city-form *ngIf="page === 'city'" [user]="me"></app-signup-city-form>
              </ng-container>
            </div>
        </header>
    </div>
</div>
