/**
 * Created by ModelParser
 * Date: 16-07-2019.
 * Time: 01:53.
 */
import { AttendeeDefinition, AttendeeDefinitionInterface } from './definitions/AttendeeDefinition';
import { User } from './User';

export interface AttendeeInterface extends AttendeeDefinitionInterface {

}

export class Attendee extends AttendeeDefinition implements AttendeeInterface {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        const item = new Attendee();
        item.id = 0;
        // item.user_id = 0;
        item.user = User.createDefault();

        return item;
    }

    public set paymentStatus(paid: boolean) {
        this.status = paid ? 1 : 0;
    }

    public get paymentStatus(): boolean {
        return this.status === 1;
    }

    // TODO: Status: 0, 1 (betalt)

}
