<ng-container *ngIf="user$|async as user; else loading">
  <app-navigation-bar class="h-14 md:h-[70px] " [class.!bg-red-600]="isStage" [user]="user"></app-navigation-bar>
  <div class="router-wrap">
    <section class="main-wrapper -flex-fill -flex -w-full h-full">
      <router-outlet class="position-relative"></router-outlet>
    </section>
  </div>
  <app-footer></app-footer>
</ng-container>


<ng-template #loading>
  <div class="w-[100vw] h-[100vh]">
    <app-spinner></app-spinner>
  </div>
</ng-template>
