/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { EventsTagDefinition, EventsTagDefinitionInterface } from './definitions/EventsTagDefinition';

export interface EventsTagInterface extends EventsTagDefinitionInterface {

}

export class EventsTag extends EventsTagDefinition implements EventsTagInterface {

    constructor(json?: any) {
        super(json);
    }

}
