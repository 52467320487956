<app-user-profile-icon class="inline-block h-24 w-24" [user]="user"></app-user-profile-icon>
<div class="ml-4 flex flex-col">
  <h4 class="font-bold mb-1 text-sm" *ngIf="user?.first_name">
    {{ user?.first_name }} {{user.last_name}}
  </h4>
  <app-icon-label class="my-0.5" icon="location w-5 h-[26px]">{{ user?.address?.city }}</app-icon-label>
  <app-icon-label class="my-0.5" icon="child">{{genderLabel|titlecase}} til {{ user?.children?.length || 0 }}</app-icon-label>
  <p *ngIf="showDebugInfo">Afstand fra dig: ~{{distanceFromMe}}km</p>
  <p *ngIf="showDebugInfo">Matchende tags: {{matchingTagsCount}}</p>
  <p *ngIf="showDebugInfo">Alder: {{user.birthdate|age}}</p>
  <ng-container *ngIf="showDebugInfo">
    <p *ngFor="let child of user.children; let i = index">{{i + 1}}. barns alder: {{child.attributes.birthdate|age}}</p>
  </ng-container>
  <ng-content select="app-product-card-information-tap"></ng-content>
</div>
<app-matched-card
  [componentName]="componentName"
  [tagsMatched]="tagsMatched"
  [tagsNoMatched]="tagsNoMatched"
  [isShowMatched]="isShowMatched"
  (toggleShowMatched)="toggleShowMatched()">
</app-matched-card>
