export class BaseModel {

  constructor(data?: any) {
    this.populate(data);
  }
    public clone(): any {
        var cloneObj = new (<any>this.constructor());
        for (var attribut in this) {
            if (typeof this[attribut] === "object") {
                cloneObj[attribut] = this.clone();
            } else {
                cloneObj[attribut] = this[attribut];
            }
        }
        return cloneObj;
    }

    protected dataFields() {
      return [];
    }

    public populate(data?: any, patch: boolean = false) {
        if(!data) return;
        const fields = this.dataFields();
        if (patch) {
          for (const field of fields) {
            delete this[field];
          }
        }
        for (const field of fields) {
          if (data[field] != null) {
            this[field] = data[field];
          }
        }
    }

}
