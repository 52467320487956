import { NgModule } from '@angular/core';
import { NoPreloading, Router, RouterModule, Routes } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { MyPageComponent } from './pages/my-page/my-page.component';
import Constants from './core/constants';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { ExploreComponent } from './pages/explore/explore.component';
import { SignupGuard } from './core/guards/signup.guard';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import {EmailAlreadyUsedComponent} from "./shared/email-already-used/email-already-used.component";

const routes: Routes = [
  {
    path: 'coming-soon',
    canActivate: [SignupGuard],
    component: ComingSoonComponent
  },
  {
    path: 'email-already-used',
    canActivate: [],
    component: EmailAlreadyUsedComponent
  },
  {
    path: 'groups',
    canActivate: [SignupGuard],
    canActivateChild: [SignupGuard],
    loadChildren: () => import('./pages/groups/groups.module').then(mod => mod.GroupsModule),
    data: {
      animation: 'routeFadeAnimation',
      scopes: []
    }
  },
  {
    path: 'profile',
    canActivate: [],
    canActivateChild: [],
    loadChildren: () => import('./pages/profile/profile.module').then(mod => mod.ProfileModule),
    data: {
      animation: 'routeFadeAnimation',
      scopes: [Constants.Scopes.Administrator, Constants.Scopes.Member]
    }
  },
  {
    path: '',
    redirectTo: 'explore/like-minded'
  },
  {
    path: 'signup',
    data: {
      title: 'Signup',
      animation: 'Page'
    },
    children: [
      {path: ':step', pathMatch: 'full', component: SignupPageComponent},
      {path: '**', component: SignupPageComponent},
    ]
  },
  {
    canActivate: [SignupGuard],
    path: 'explore',
    redirectTo: 'explore/like-minded'
  },
  {
    canActivate: [SignupGuard],
    path: 'explore/like-minded',
    component: ExploreComponent,
  },
  {
    canActivate: [SignupGuard],
    path: 'explore/groups',
    component: ExploreComponent,
  },
  {
    canActivate: [SignupGuard],
    path: 'my-page',
    component: MyPageComponent
  },
  {
    canActivate: [SignupGuard],
    path: '**',
    redirectTo: 'my-page'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected'
  })],
  exports: [RouterModule],
  providers: [],
  entryComponents: []
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private logger: NGXLogger
  ) {
    this.router.errorHandler = (error: any) => {

      console.warn('app-routing.module : error :', error);
      // this.router.navigate(['404']); // or redirect to default route
    };
  }
}
