import { Component, Input, OnInit } from '@angular/core';
import { ROUTE } from '../shell.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  classListOmOs: string = "!font-['Metropolis'] text-[15px] text-[#313131] font-light !leading-relaxed"
  @Input() routes: ROUTE[];

  ngOnInit() {
  }

}
