import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class DiffAgeService {
  private _minAge: number = 18;
  private _maxAge: number = 99;

  public get minAge(){ return this._minAge; }

  public get maxAge(){ return this._maxAge; }
  
  handleDiffAge(birthdate: string) {
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  ageValidator(): ValidatorFn  {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthdate = control?.value;
      if(birthdate === undefined || birthdate?.length === 0) return {birthdate: {value: control.value}};
      const age = this.handleDiffAge(birthdate);
      if(age < this.minAge || age > this.maxAge) return {birthdate: {value: control.value}};
      return null;
    };    
  }

}