<mat-icon mat-dialog-close="close" class="dialog-close-icon">close</mat-icon>
<ng-container *ngIf="user$|async as user">
  <div class="py-24 pl-5 pr-11 border-gray border-r-1 border-solid h-full hidden md:flex flex-col" *ngIf="messages.length > 0">
    <app-user-simple-card class="flex-grow w-[122px]" imageSize="medium" [user]="user"></app-user-simple-card>
    <h4 class="w-full text-center underline font-semibold underline-offset-4 cursor-pointer" (click)="openProfile(user)">Se profil</h4>
  </div>
  <h4 class="md:hidden" (click)="openProfile(user)">{{user.first_name}} {{user.last_name}}</h4>
  <app-divider class="flex md:hidden" *ngIf="messages.length > 0"></app-divider>
</ng-container>

<div class="flex-grow flex flex-col md:ml-[33px] max-h-full min-h-0 h-full w-full">
  <ng-container *ngIf="conversation$|async as conversation; else loading">
    <div class="flex justify-center flex-grow flex-col items-center" *ngIf="messages.length === 0">
      <div class="flex">
        <ng-container *ngIf="user$|async as user">
          <div class="flex-col">
            <app-user-simple-card class="h-auto" imageSize="medium" [user]="user"></app-user-simple-card>
            <h4 class="w-full text-center underline font-semibold underline-offset-4 cursor-pointer" (click)="openProfile(user)">Se profil</h4>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="w-full h-full chat-window flex flex-col-reverse my-5 pretty-scrollbar overflow-auto min-h-0 flex-grow" *ngIf="messages.length > 0">
      <app-message-card
        *ngFor="let message of messages; trackBy: tracker()"
        class="my-2.5"
        [sender]="getUserObservable(message.author)|async"
        [message]="message"
        [alignment]="isAuthorSelf(message) ? 'right' : 'left'"
      ></app-message-card>
    </div>
    <div *ngIf="typing" class="flex items-end">
      <app-user-profile-icon class="inline-block w-9 h-9 mx-2" [user]="getUserObservable(typing.identity)|async"></app-user-profile-icon>
      <p class="italic bg-slgray p-4">Taster...</p>
    </div>
    <form (submit)="submit(conversation, $event)" class="flex items-baseline">
      <div class="w-full grid ml:grid-cols-[auto_91px] ml:grid-rows-1 ml:gap-x-3 grid-cols-1 grid-rows-1 gap-y-3">
        <mat-form-field class="h-11">
          <textarea rows="1" class="pretty-scrollbar w-full" (keydown)="triggerTyping(conversation, $event)"
            (input)="resizeMessageField()" matInput name="message" #messageInput></textarea>
        </mat-form-field>
        <button type="submit" class="h-11 !m-0 !p-0 w-[91px]">Send</button>
      </div>
    </form>
  </ng-container>
</div>

<ng-template #loading>
  <div class="flex w-full h-full">
    <div class="m-auto w-32 h-32">
      <app-spinner></app-spinner>
    </div>
  </div>
</ng-template>

