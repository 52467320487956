import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StrapiModel } from '../../../core/models/StrapiModel';
import { GroupAttributes } from '../../../core/models/definitions/GroupDefinition';
import { StrapiService } from '../../../services/strapi.service';
import { Interest } from '../../../core/models';

@Component({
  selector: 'app-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupCardComponent implements OnInit {
  @Input() componentName: string = 'group-card';
  membersCount;
  @Input() group: StrapiModel<GroupAttributes>;
  @Input() tagsMatched: Interest[];
  @Input() tagsNoMatched: Interest[];

  constructor(
    public strapiService: StrapiService,
  ) { }

  ngOnInit(): void {
    this.membersCount = this.getMemberCount();
  }

  getMemberCount() {
    let count = this.group?.attributes.membersCount;
    if (count > 0 && count !== undefined) {
      return count
    } else {
      return 'Ingen';
    }
  }
}
