<ng-container  *ngIf="notification.label">
  <div class="pr-4 mb-5">
    <app-user-profile-icon class="inline-block w-10 h-10" [user]="notification?.user"></app-user-profile-icon>
  </div>
  
  <div class="flex flex-grow mb-5 pr-2">
    <div>
      <div class="flex items-center">
        <p [class]="isPostSeen() ? 'opacity-40 text-sm' : 'text-sm'">{{notification.label}}</p>
        <p *ngIf="!notification?.isRead" class="ml-2 bg-green w-2 h-2 rounded-full"></p>
      </div>
      <p class="italic text-xs opacity-40 capitalize" *ngIf="notification.date">{{notification.date|dateFormatPipe}}
      </p>
    </div>
  </div>
</ng-container>