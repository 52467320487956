/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { GroupDefinition } from './definitions/GroupDefinition';
import { ApiService } from '../../services/api.service';

export class Group extends GroupDefinition {

    public static createDefault() {
        const item = new Group();
        item.id = 0;
        item.attributes = {} as any;
        return item;
    }

}
