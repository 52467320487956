import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { CoreComponent } from './core.component';
import { AuthenticationService } from '../services/authentication.service';
import { ExploreService } from '../services/explore.service';
import { EventService } from '../services/event.service';
import { AuthInterceptor } from './http/auth.interceptor';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule],
  providers: [HttpClientModule, AuthenticationService, ExploreService, EventService, HttpCacheService, ApiPrefixInterceptor, ErrorHandlerInterceptor, {
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true,
  }, CacheInterceptor, {
    provide: HttpClient, useClass: HttpService,
  }, {
    provide: RouteReuseStrategy, useClass: RouteReusableStrategy,
  }],
  declarations: [CoreComponent],
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
