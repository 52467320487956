/**
 * Created by ModelParser
 */
import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';

export interface InterestAttributes {
  label: string;
  type: 'interest'
}

export class InterestDefinition extends BaseStoredModel implements StrapiModel<InterestAttributes> {
    id: number;
    attributes: InterestAttributes;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.id;
            delete this.attributes;
        }

        if (!data) return;
        this.attributes = {} as any;
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.attributes) {
          this.attributes = data.attributes;
        }
    }

}
