<app-user-profile-icon class="inline-block h-20 w-20" [user]="user"></app-user-profile-icon>
<div class="ml-4 flex flex-col truncate">
  <h4 class="text-left font-bold !mb-[10px] text-sm" *ngIf="user?.first_name">
    {{ user?.first_name }} {{user.last_name}}
  </h4>
  <app-icon-label class="my-0.5" icon="location w-5 h-[26px]">{{ user?.address?.city }}</app-icon-label>
  <app-icon-label class="my-0.5" icon="child">{{genderLabel|titlecase}} til {{ user?.children?.length || 0 }}</app-icon-label>
  <ng-content select="app-product-card-information-tap"></ng-content>
  <ng-container *ngIf="component === 'friend-request'">
    <app-friend-request-card class="md:hidden pt-6" [user]="user" (emitFilterUsers)="emitFilterUsers()"></app-friend-request-card>
  </ng-container>
</div>
<ng-container *ngIf="component === 'friend-request'">
  <app-friend-request-card class="absolute right-0 md:inline-table hidden" [user]="user" (emitFilterUsers)="emitFilterUsers()"></app-friend-request-card>
</ng-container> 