import { BaseResourceApi } from './../BaseResourceApi';
import { ApiCollectionBase } from '../ApiCollectionBase';
import { BaseGetResourceApi } from '../BaseGetResourceApi';
import { FamobaProduct } from '../../../models/definitions/SubscriptionDefinition';

export class ApiSubscriptionInvite extends ApiCollectionBase {

  public get($search): BaseGetResourceApi<[FamobaProduct[]]> {
    return new BaseGetResourceApi<[FamobaProduct[]]>(this.config, {
      uri: '/subscriptions-invite/list-can-invite?search=' + $search,
    });
  }

  public sendInvite(): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/subscriptions-invite/send-invite`,
      method: 'post'
    })
  }

  public getInvited(): BaseGetResourceApi<[FamobaProduct[]]> {
    return new BaseGetResourceApi<[FamobaProduct[]]>(this.config, {
      uri: '/subscriptions-invite/list-invited',
    });
  }

  public deleteInvited(): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/subscriptions-invite/delete-invited`,
      method: 'post'
    })
  }

  public getRequestsInvite(): BaseGetResourceApi<[FamobaProduct[]]> {
    return new BaseGetResourceApi<[FamobaProduct[]]>(this.config, {
      uri: '/subscriptions-invite/list-requests-invite',
    });
  }

  public deniedRequestInvite(): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/subscriptions-invite/denied-request-invite`,
      method: 'post'
    })
  }

  public acceptRequestInvite(): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/subscriptions-invite/accept-request-invite`,
      method: 'post'
    })
  }
}
