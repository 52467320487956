import { Component, OnInit, TrackByFunction, ViewChild, ElementRef } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {map, take} from "rxjs/operators";
import {NewChatDialogComponent} from "../../../../shared/dialogs/new-chat-dialog/new-chat-dialog.component";
import {combineLatest, from, Observable, of} from "rxjs";
import {Conversation, Message, Paginator} from "@twilio/conversations";
import {TwilioService} from "../../../../services/twilio.service";
import {SingleChatDialogComponent} from "../../../../shared/dialogs/single-chat-dialog/single-chat-dialog.component";
import {User} from "../../../../core/models";

@Component({
  selector: 'app-my-page-like-minded',
  templateUrl: './my-page-like-minded.component.html',
  styleUrls: ['./my-page-like-minded.component.scss']
})
export class MyPageLikeMindedComponent implements OnInit {
  userObservables: {[userId: string]: Observable<User>} = {};
  lastMessages: {[sidMessageIndex: string]: Observable<Message>} = {};
  //search
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>
  searchEvent$: Observable<any>;
  hasSearchConversations: boolean = false;
  isFetching: boolean = false;
  notificationData$: Observable<{
    subscribedConversations: Paginator<Conversation>, 
    countConversations: number,
  }>

  constructor(
    private dialog: MatDialog,
    private twilioService: TwilioService,
  ) { }

  ngOnInit() {
    this.notificationData$ = combineLatest([
      this.twilioService.asyncGetSubscribedConversations(),
      this.twilioService.getCountConversations(),
    ]).pipe(
      map(([
        subscribedConversations, countConversations
      ]) => ({ 
        subscribedConversations, countConversations
      }))
    )
  }

  handleInputSearch(): void {
    const input = this.searchInput?.nativeElement;
    const value = input?.value?.trim();
    this.handleToSearchMessages(value);
  }

  sortedConversations(list: Conversation[]): Conversation[] {
    return list.sort(this.sortConversations())
  }

  sortConversations() {
    return (a: Conversation, b: Conversation) => {
      const date1 = (a.lastMessage?.dateCreated || a.dateCreated) as Date;
      const date2 = (b.lastMessage?.dateCreated || b.dateCreated) as Date;
      return date2.getTime() - date1.getTime()
    }
  }

  newChat() {
    this.dialog.open(NewChatDialogComponent, {
      width: "962px",
      height: "586px"
    });
  }

  hasUnreadMessages(conversation: Conversation) {
    return (conversation.lastMessage?.index ?? 0) != (conversation.lastReadMessageIndex ?? 0)
  }

  openConversation(conversation: Conversation) {
    this.twilioService.updateIsOpenModal(true, conversation)
    conversation.setAllMessagesRead()
    this.dialog.open(SingleChatDialogComponent, {
      data: of({sid: conversation.sid})
    }).afterClosed().pipe(take(1)).subscribe(_ => {
      this.twilioService.updateIsOpenModal(false, conversation)
    })
  }

  getUserFullName(conv: Conversation): Observable<string|undefined> {
    return this.getUser(conv).pipe(
      map(d => d ? `${d.first_name} ${d.last_name}`: undefined)
    )
  }

  getLastMessage(conv: Conversation): Observable<Message> {
    const lastMessage = conv.lastMessage;
    const id = `${conv.sid}-${lastMessage.index}`;
    if (!this.lastMessages[id]) {
      this.lastMessages[id] = from(
        conv.getMessages(1).then(res => {
          return res.items[0]
        })
      )
    }
    return this.lastMessages[id];
  }

  trackBy(): TrackByFunction<Conversation> {
    return (index, item) => {
      return item.sid;
    }
  }

  getUser(conv: Conversation): Observable<User|undefined> {
    if (!this.userObservables[conv.sid]) {
      this.userObservables[conv.sid] = this.twilioService.getUserByConversationUniqueName(conv)
    }

    return this.userObservables[conv.sid];
  }

  async handleToSearchMessages(searchValue:string){
    if(!this.hasSearchConversations){
      this.hasSearchConversations = true;
    }
    this.isFetching = true;
    this.notificationData$ = combineLatest([
      this.twilioService.asyncGetSubscribedConversations().pipe(
        map(conversations => {
          conversations?.items?.filter((conversation , index) => {
              this.getUserFullName(conversation).pipe(take(1)).subscribe(
                fullName => {
                  const fullNameData = fullName?.toLocaleLowerCase();
                  const searchValueData = searchValue?.toLocaleLowerCase();
                  if(!fullNameData.includes(searchValueData)){
                    conversations.items = conversations.items.filter(c => c.sid !== conversation.sid);
                  }
                }
              );
          })
          return conversations;
        }),
        take(1)
      ),
      this.twilioService.getCountConversations()
    ]).pipe(
      map(([
        subscribedConversations, countConversations
      ]) => ({ 
        subscribedConversations, countConversations
      }))
    )
    this.isFetching = false;
  }

}
