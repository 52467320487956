import { Component, Input, OnInit } from '@angular/core';
import { IconName } from '../../atoms/icon/icon.component';

@Component({
  selector: 'app-icon-label',
  templateUrl: './icon-label.component.html',
  styleUrls: ['./icon-label.component.scss']
})
export class IconLabelComponent implements OnInit {

  @Input() icon: IconName

  constructor() { }

  ngOnInit(): void {
  }

}
