import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { DawaAddressChange } from '../../address-search-dawa/address-search-dawa.component';
import { DawaAutocomplete } from '../../address-search-dawa/dawa-autocomplete';
import { AddressDefinitionInterface } from '../../../core/models/definitions/AddressDefinition';

// https://medium.com/@a.yurich.zuev/angular-nested-template-driven-form-4a3de2042475

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

  _address: AddressDefinitionInterface;
  readonly = true;
  dawa: DawaAutocomplete;
  @Input() isAccessComponent:boolean = true;
  @Input() address: AddressDefinitionInterface;
  @Output() addressChange: EventEmitter<AddressDefinitionInterface> = new EventEmitter<AddressDefinitionInterface>();

  constructor(
    public zone: NgZone
  ) {
  }

  ngOnInit() {
    if (this.address) {
      this._address = this.address;
    }
  }

  selectAddress(dawaAddressChange: DawaAddressChange) {
    const {address, dawa} = dawaAddressChange;
    this.dawa = dawa;
    this.readonly = false;
    this._address = address;
    this.addressChange.emit(this.addressChangeData);
  }

  get addressChangeData() {
    return {
      city: this.city,
      zip: this.zip,
      street: this.street,
      dawaId: this.dawa?.data?.id,
      lat: this.dawa?.data?.y,
      long: this.dawa?.data?.x,
    } as AddressDefinitionInterface
  }

  get zip() {
    return this._address?.zip;
  }

  set zip(value) {
    if (!this._address) {
      this._address = {};
    }
    this._address.zip = value;
    this.addressChange.emit(this.addressChangeData);
  }

  get street() {
    return this._address?.street;
  }

  set street(value) {
    if (!this._address) {
      this._address = {};
    }
    this._address.street = value;
    this.addressChange.emit(this.addressChangeData);
  }

  get city() {
    return this._address?.city;
  }

  set city(value) {
    if (!this._address) {
      this._address = {};
    }
    this._address.city = value;
    this.addressChange.emit(this.addressChangeData);
  }

}
