import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  QueryList,
  TrackByFunction,
  ViewChildren
} from '@angular/core';
import {NgAisInfiniteHits} from "angular-instantsearch/infinite-hits/infinite-hits";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ExploreComponent} from "../../explore.component";
import {AlgoliaHitMember} from "../../../../services/algolia.service";
import {InstantSearchConfig} from 'angular-instantsearch/instantsearch/instantsearch';
import {ConfigureConnectorParams} from 'instantsearch.js/es/connectors/configure/connectConfigure';
import {Filter} from '../filter/filter.component';

export interface GeneratedFilters {
  config: InstantSearchConfig;
  searchParams: ConfigureConnectorParams['searchParameters'],
  title?: string
}

export type GenerateFilterFn = (filter: Filter) => GeneratedFilters[];

@Component({
  selector: 'app-extended-infinite-hits',
  templateUrl: './extended-infinite-hits.component.html',
  styleUrls: ['./extended-infinite-hits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendedInfiniteHitsComponent implements AfterViewInit {

  test = [0];

  private i = 0;
  getIndex() {
    this.i++;
    return this.i;
  }

  protected filterCache: {[index: number]: any} = {};

  get generateFilters(): GenerateFilterFn {
    return this._generateFilters;
  }
  @Input()
  set generateFilters(value: GenerateFilterFn) {
    this._generateFilters = value;
  }
  @ViewChildren('hitsElement') hitsElements: QueryList<NgAisInfiniteHits>;

  private pageSize = 20;

  private _filter$: Observable<any>;

  activatedFilters: number[] = [];

  protected autoReadMoreIndexes = [];

  filters = [];

  private _generateFilters: GenerateFilterFn;

  constructor(
    @Inject(forwardRef(() => ExploreComponent)) public exploreComponent: ExploreComponent,
    protected changeDetector: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    if (this.exploreComponent) {
      this.changeDetector.markForCheck();
    }
  }

  get automaticRadius() {
    const automaticRadius = this.hitsElements?.first?.instantSearchInstance?.instantSearchInstance?.helper?.lastResults?.automaticRadius
    if (!automaticRadius) {
      return undefined;
    }
    return parseInt(automaticRadius);
  }

  checkCurrentPageSize(currentIndex) {
    const currentPageHitsLength = this.getInfiniteHits(currentIndex)?.state.currentPageHits.length;
    if (this.autoReadMoreIndexes.indexOf(currentIndex) !== -1) {
      return;
    }
    if (!this.getInfiniteHits(currentIndex)?.state.results) {
      return;
    }
    if (currentPageHitsLength === undefined) {
      return;
    }
    if (currentPageHitsLength >= this.pageSize) {
      return;
    }
    this.showMore();
  }

  change(event, index) {
    this.checkCurrentPageSize(index)
  }

  protected getUniqueFilters(filters: GeneratedFilters[]): GeneratedFilters[] {
    const uniqueFilters = [];
    const filterStrings = filters.map(d => JSON.stringify(d.searchParams));

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      const index = filterStrings.findIndex(d => d === JSON.stringify(filter.searchParams))
      if (i !== index) {
        continue;
      }
      uniqueFilters.push(filter);
    }
    return uniqueFilters;
  }

  get filter$(): Observable<any> {
    if (this._filter$) {
      return this._filter$;
    }
    this._filter$ = this.exploreComponent?.filterComponent?.filter.pipe(tap(d => {
      this.filters = this.getUniqueFilters(this.generateFilters(d));
      this.resetAlgoliaInstances();
      if (this.filters.length > 0) {
        this.activatedFilters = [0];
      } else {
        this.activatedFilters = [];
      }
      this.filterCache = {};
      this.changeDetector.detectChanges()
    }))
    return this._filter$;
  }

  getFilter(index: number) {
    if (this.filterCache[index]) {
      return this.filterCache[index];
    }
    const filter = this.filters[index].searchParams;
    const hits = Array(index).fill(null).map((d, i) => this.getHitsByIndex(i).map(d => d.objectID)).reduce((p, c) => {
      p.push(...c);
      return p;
    }, []);
    if (!filter.facetFilters) {
      filter.facetFilters = []
    }
    filter.facetFilters.push(...hits.map(d => `objectID:-${d}`))

    this.filterCache[index] = filter;
    return filter;
  }

  getHitsByIndex(index: number) {
    const hitsElement = this?.hitsElements.get(index) as NgAisInfiniteHits
    return hitsElement?.state?.hits ?? [];
  }

  get currentActivatedFilterIndex() {
    return this.activatedFilters[this.activatedFilters.length - 1];
  }

  getInfiniteHits(index: number) {
    return this.hitsElements?.get(index);
  }

  canShowMore() {
    const currentFilterIndex = this.currentActivatedFilterIndex;
    const infiniteHits = this.getInfiniteHits(currentFilterIndex);
    if (!infiniteHits) {
      return false;
    }
    const nextFilterIndex = currentFilterIndex + 1;
    const isNextFilterActivated = infiniteHits.state.isLastPage && this.activatedFilters[nextFilterIndex];
    if (isNextFilterActivated) {
      return false;
    }
    if (!infiniteHits.state.isLastPage) {
      return true;
    }
    if (this.filters[nextFilterIndex]) {
      return true;
    }
    return false;
  }

  showMore(currentFilterIndex?: number) {

    if (currentFilterIndex === undefined) {
      currentFilterIndex = this.currentActivatedFilterIndex;
    }

    const infiniteHits = this.getInfiniteHits(currentFilterIndex);
    if (!infiniteHits) {
      return;
    }
    if (!infiniteHits.state.isLastPage) {
      infiniteHits.state.showMore()
      return;
    }
    const nextFilterIndex = currentFilterIndex + 1;
    if (this.filters[nextFilterIndex]) {
      this.activatedFilters.push(nextFilterIndex)
    }
  }

  get combinedHits(): AlgoliaHitMember[][] {
    return this.hitsElements?.map(d => d.state.hits) as any;
  }

  trackByFilter(): TrackByFunction<number> {
    return (index, item) => {
      return item;
    }
  }

  trackBy(): TrackByFunction<any> {
    return (index, item) => {
      return item.objectID;
    }
  }

  resetAlgoliaInstances() {
    for (const index of this.activatedFilters) {
      const element = this.getInfiniteHits(index);
      element.instantSearchInstance.instantSearchInstance.helper.setPage(0);
    }
  }

}
