<div *ngIf="!getImageUrl()" class="absolute inset-0 flex justify-center items-center">
  <app-icon icon="logo-famoba" class="h-[20%]" color="green"></app-icon>
</div>
<div class="absolute inset-x-0 bottom-0 group-gradient" [ngClass]="{'group-gradient-matched':  isShowMatched}">
  <div class="top-gradient"></div>
  <div class="bottom-gradient"></div>
</div>

<div class="z-10">
  <app-matched-big-card [tagsMatched]="tagsMatched" [tagsNoMatched]="tagsNoMatched" [isShowMatched]="isShowMatched"
    (toggleShowMatched)="toggleShowMatched()">
  </app-matched-big-card>
  <p class="mb-1">{{memberCount}} {{ memberCount == 1 ? 'medlem' : 'medlemmer'}}</p>
  <h3 class="text-green mb-1">{{groupName}}</h3>
  <p class="mb-1">{{city}}</p>
</div>