import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-card-container',
  templateUrl: './group-card-container.component.html',
  styleUrls: ['./group-card-container.component.scss'],
})
export class GroupCardContainerComponent implements OnInit {

  @HostBinding('class') class = 'w-full';

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
