<mat-icon mat-dialog-close="close" class="dialog-close-icon">close</mat-icon>
<h3 class="w-full text-center my-6 md:mt-4">Upload og rediger billede</h3>
<div class="container">
  <div class="flex flex-col items-center pb-4">
    <div>
      <p class="text-sm pb-2">Upload nyt billede</p>
      <label class="block">
        <span class="sr-only">Vælg profilbillede</span>
        <input type="file" (change)="fileChangeEvent($event)" class="block w-full text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-cyan file:text-black
                hover:file:bg-cyan
              " />
      </label>
    </div>
  </div>
  <div class="flex flex-col justify-center md:flex-row md:justify-around gap-4" *ngIf="imageChangedEvent">
    <div>
      <p class="text-sm pb-2">Forhåndsvisning</p>
      <div class="md:mx-0 mx-auto h-40 w-40">
        <app-user-profile-icon [user]="croppedImage ? data.previewUser(data.me, croppedImage) : data.me"
          class="inline-block w-40 h-40">
        </app-user-profile-icon>
      </div>
    </div>
    <div>
      <p class="text-sm pb-2">Billedebeskæring</p>
      <div class="w-full" [style.max-width]="data.width + 'px'">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="data.maintainAspectRatio"
          [aspectRatio]="data.aspectRatio" [resizeToWidth]="data.width" [cropperMinWidth]="128" format="png"
          (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
    </div>
  </div>
</div>
<div *ngIf="imageChangedEvent" class="mt-6 flex justify-center">
  <button class="h-[44px]" [disabled]="!croppedImage" (click)="saveFile()">Gem</button>
</div>