<div class="flex flex-col mr-3 items-center" *ngIf="showIcon">
  <app-profile-icon icon="child"></app-profile-icon>
  <ng-container *ngIf="isAccessComponent">
    <p class="underline text-center cursor-pointer" (click)="removeChild()">Fjern</p>
  </ng-container>
</div>
<form [formGroup]="formGroup">
  <input type="hidden" class="hidden" formControlName="id">
  <mat-form-field class="w-full" color="accent">
    <mat-label>Navn</mat-label>
    <input matInput formControlName="name" required [readonly]="!isAccessComponent">
  </mat-form-field>
  <div class="grid gap-2 grid-cols-2">
    <mat-form-field class="w-full" color="accent">
      <mat-label>Fødselsdato/terminsdato</mat-label>
      <input matInput type="date" formControlName="birthdate"  max="9999-12-31" required>
    </mat-form-field>
    <mat-form-field class="w-full" color="accent">
      <mat-label>Køn</mat-label>
      <select formControlName="gender" matNativeControl required>
        <option value="" disabled>Køn</option>
        <option value="boy" [disabled]="!isAccessComponent">Dreng</option>
        <option value="girl" [disabled]="!isAccessComponent">Pige</option>
        <option value="other" [disabled]="!isAccessComponent">Andet</option>
      </select>
    </mat-form-field>
  </div>
  <div class="flex justify-end" *ngIf="!showIcon">
    <div class="inline-block">
      <p class="underline cursor-pointer text-right mt-1" (click)="removeChild()">Fjern</p>
    </div>
  </div>
</form>
