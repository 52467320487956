import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AuthService, User as AuthUser } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from '../core/models';

export interface Credentials {
  // Customize received credentials here
  email: string;
  token?: string;
  first_name?: string;
  id?: number;
  last_name?: string;
  user_type_id?: number;

}

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}


const credentialsKey = 'credentials';


/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

  // As in OAuthConfig.
  public storage: Storage = sessionStorage;

  // private selectedItem = new BehaviorSubject<User>(null);

  /**
   * Behavior subjects of the selectedItem's status & data.
   */

  private _initialized = false;
  private _lastAuthUser: AuthUser;


  constructor(
    private auth: AuthService
  ) {
  }

  public assessToken(): Observable<string> {
    return this.auth.idTokenClaims$.pipe(
      map(d => d?.__raw)
    );
  }

  public userAsync(): Observable<AuthUser> {
    return this.auth.user$.pipe(
      tap(d => {
        this._lastAuthUser = d;
        this._initialized = true;
        if (!d) {
          this.auth.loginWithRedirect();
        }
      })
    );
  }

  get lastAuthUser() {
    return this._lastAuthUser;
  }

  get initialized(): boolean {
    return this._initialized;
  }

  public scopeAvailable(user: User, scope: string) {
    return user.scope === scope;
  }

  public init(): void {
  }

  public signin(scope?: string): Promise<void> {
    return undefined;
  }

  public signout(): void {
    return this.auth.logout({
      returnTo: "https://famoba.dk"
    });
  }

  public refreshSession(): void {
  }

  public getItem(key: string): any {
    return JSON.parse(this.storage.getItem(key));
  }

  public setItem(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  public removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  public getAuthorizationHeader(): HttpHeaders {
    // Creates header for the auth requests.
    let headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return headers;
  }

  public getAuthorizationToken(): string {
    return undefined;
  }

  public isInGroup(group: string): boolean {
    return true;
  }

  private encodeParams(params: any): string {
    let body = '';
    for (const key of Object.keys(params)) {
      if (body.length) {
        body += '&';
      }
      body += key + '=';
      body += encodeURIComponent(params[key]);
    }
    return body;
  }

}
