import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ExploreComponent} from "./explore.component";
import {CardComponent} from "./components/card/card.component";
import {CardSectionComponent} from "./components/card-section/card-section.component";
import {FilterComponent} from "./components/filter/filter.component";
import {HeaderComponent} from "./components/header/header.component";
import {AtomicModule} from "../../components/atomic.module";
import {MatTabsModule} from "@angular/material/tabs";
import {NgAisModule} from "angular-instantsearch";
import {RouterModule} from "@angular/router";
import {ShellModule} from "../../core/shell/shell.module";
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {SharedModule} from "../../shared";
import { ExploreLikeMindedComponent } from './components/explore-like-minded/explore-like-minded.component';
import { ExploreGroupsComponent } from './components/explore-groups/explore-groups.component';
import { ExploreContentComponent } from './components/explore-content/explore-content.component';
import { ExtendedInfiniteHitsComponent } from './components/extended-infinite-hits/extended-infinite-hits.component';

@NgModule({
  declarations: [
    ExploreComponent,
    CardComponent,
    CardSectionComponent,
    FilterComponent,
    HeaderComponent,
    ExploreLikeMindedComponent,
    ExploreGroupsComponent,
    ExploreContentComponent,
    ExtendedInfiniteHitsComponent
  ],
  exports: [
    ExploreComponent
  ], imports: [
    CommonModule, AtomicModule, SharedModule, MatTabsModule, NgAisModule, RouterModule, ShellModule, NgxSliderModule
  ]
})
export class ExploreModule { }
