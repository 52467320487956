import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInjector } from '../../services/app-injector.service';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

    // https://alligator.io/angular/service-worker-updates/
    constructor(private router: Router,
                private snackbar: MatSnackBar) {

    }

    // TODO: Håndter serverfejl / dialog logud mv.

    // Customize the default error handler here if needed
    private isSilentRefreshing = false;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
    }
    private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (!environment.production) {
            // Do something with the error
            // log.error('Request error', response);
        }
        const errorMsg = response.error?.message ?? response.message;

        switch (response.status) {
            case 500:
            case 503:
                console.error('500: Server fejl: ', event);
                console.log(JSON.stringify(response))
                const snackError = this.snackbar.open(`(${response.status}) Ups! Der skete en fejl i vores ende. (${errorMsg})`, 'Luk', {duration: 8000, panelClass: 'alert-danger'});
                snackError
                    .onAction()
                    .subscribe((e) => {
                        console.log('action ', e);
                        snackError.dismiss();
                    });
                //this.messageService.addErrors(event.toString());
                break;
            default:
                console.log('unknown status : ', event);
                break;
        }



        throw response;
    }

}
