import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChildDefinitionAttributes } from '../../../core/models/definitions/ChildDefinition';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StrapiModel } from '../../../core/models/StrapiModel';

@Component({
  selector: 'app-edit-child-form',
  templateUrl: './edit-child-form.component.html',
  styleUrls: ['./edit-child-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'flex md:flex-row flex-col'
  }
})
export class EditChildFormComponent implements OnInit {

  __initialized = false;

  @Input() showIcon = true;
  @Input() isAccessComponent:boolean = true;
  private _child: StrapiModel<ChildDefinitionAttributes>;


  private _formGroup: FormGroup;
  private _defaultFormGroup: FormGroup;

  @Output() childRemoved: EventEmitter<void> = new EventEmitter<void>();

  get formGroup(): FormGroup {
    if (!this._formGroup && !this._defaultFormGroup) {
      this.initDefaultGroup();
    }
    return this._formGroup ?? this._defaultFormGroup;
  }

  @Input() set formGroup(value: FormGroup) {
    this._formGroup = value;
    this.initFormGroup(value);
    this.applyChildValues();
  }

  get child(): StrapiModel<ChildDefinitionAttributes> {
    return this._child;
  }

  @Input() set child(value: StrapiModel<ChildDefinitionAttributes>) {
    this._child = value;
    this.applyChildValues();
  }

  constructor(private formBuilder: FormBuilder) {}

  protected initDefaultGroup() {
    this._defaultFormGroup = this.formBuilder.group({});
    this.initFormGroup(this._defaultFormGroup);
  }

  protected applyChildValues() {
    if (!this.__initialized) {
      return;
    }
    if (this._child) {
      this.formGroup.setValue({
        id: this._child.id ?? '',
        name: this._child.attributes?.name ?? '',
        birthdate: this._child.attributes?.birthdate ?? '',
        gender: this._child.attributes?.gender ?? '',
      });
    } else {
      this.formGroup.reset();
    }
  }

  ngOnInit(): void {
    this.__initialized = true;
    this.applyChildValues();
  }

  initFormGroup(group: FormGroup) {
    this.addControl('name', group, this.formBuilder.control(this._child?.attributes?.name ?? '', [Validators.required]));
    this.addControl('birthdate', group, this.formBuilder.control(this._child?.attributes?.birthdate ?? '', [Validators.required]));
    this.addControl('gender', group, this.formBuilder.control(this._child?.attributes?.gender ?? '', [Validators.required]));
    this.addControl('id', group, this.formBuilder.control(this._child?.id ?? ''));
  }

  addControl(name: string, group: FormGroup, control: FormControl) {
    if (!group) {
      return;
    }
    if (group?.get(name)) {
      return;
    }
    group.addControl(name, control);
  }

  removeChild() {
    this.formGroup.reset();
    this.childRemoved.emit();
  }

  get gender() {
    return this._child?.attributes?.gender;
  }

}
