/**
 * Created by ModelParser
 */
import { User, UserInterface } from '../User';
import { Channel, ChannelInterface } from '../Channel';
import { BaseStoredModel } from '../BaseStoredModel';

export interface MatchV2 {
  id: number;
  created: string;
  updated: string;
  state: 'pending'|'accepted'|'declined';
  sender_user_id: number;
  target_user_id: number;
}

export interface MatchDefinitionInterface {
    a_id?: number;
    a?: UserInterface;
    b_id?: number;
    b?: UserInterface;
    channel_id?: number;
    channel?: ChannelInterface;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class MatchDefinition extends BaseStoredModel implements MatchDefinitionInterface {
    a_id?: number;
    a?: User;
    b_id?: number;
    b?: User;
    channel_id?: number;
    channel?: Channel;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.a_id;
            delete this.a;
            delete this.b_id;
            delete this.b;
            delete this.channel_id;
            delete this.channel;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.a_id != null) {
            this.a_id = data.a_id;
        }
        if (data.a != null) {
            this.a = new User(data.a);
        }
        if (data.b_id != null) {
            this.b_id = data.b_id;
        }
        if (data.b != null) {
            this.b = new User(data.b);
        }
        if (data.channel_id != null) {
            this.channel_id = data.channel_id;
        }
        if (data.channel != null) {
            this.channel = new Channel(data.channel);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
