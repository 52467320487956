import {Component, EventEmitter, Input, Output} from '@angular/core';
import dayjs from 'dayjs';
import {Options} from "@angular-slider/ngx-slider";

export interface RangeSliderCurrentValue {
  min: number;
  max: number;
  minYear: number;
  maxYear: number;
}

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent {

  private debounceTimeout;
  @Input() currentValue: RangeSliderCurrentValue;

  @Input() attribute: string;
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() cache = true;
  @Output() filterChange: EventEmitter<{filter: string, inverted: string}> = new EventEmitter<{filter: string, inverted: string}>()

  constructor(
  ) {
  }
  getCurrentFilter(inverted = false) {
    const currentValue = this.currentValue ?? this.defaultCurrentValue();

    const inputMax = this.max;
    const inputMin = this.min;
    const currentValueMaxYear = new Date().getFullYear() - new Date(currentValue.max).getFullYear();
    const currentValueMinYear = new Date().getFullYear() - new Date(currentValue.min).getFullYear();
    const includeMax = currentValueMaxYear < inputMax;
    const includeMin = currentValueMinYear > inputMin;

    const parts = [];
    if (!includeMin && !includeMax) {
      return '';
    }

    if (inverted) {
      if (includeMin) {
        parts.push(`${this.attribute} > ${currentValue.min}`);
      }
      if (includeMax) {
        parts.push(`${this.attribute} < ${currentValue.max}`);
      }
      return parts.join(' OR ');
    }

    if (includeMin) {
      parts.push(`${this.attribute} <= ${currentValue.min}`);
    }
    if (includeMax) {
      parts.push(`${this.attribute} >= ${currentValue.max}`);
    }

    return parts.join(' AND ');
  }

  ngOnInit() {
  }

  defaultCurrentValue(): RangeSliderCurrentValue {
    const item = localStorage.getItem(`range-slider-${this.attribute}`);
    if (item) {
      return JSON.parse(item);
    }
    return {
      min: this.yearToUnix(this.min),
      minYear: this.min,
      max: this.yearToUnix(this.max),
      maxYear: this.max
    }
  }

  private yearToUnix(year: number): number {
    return dayjs().subtract(year, 'year').unix() * 1000;
  }

  get currentSliderValue() {
    const currentValue = this.currentValue ?? this.defaultCurrentValue();
    return currentValue.minYear ?? this.min;
  }

  get currentSliderHighValue() {
    const currentValue = this.currentValue ?? this.defaultCurrentValue();
    return currentValue.maxYear ?? this.max;
  }

  valueChange(type: 'min'|'max', event) {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    if (!this.currentValue) {
      this.currentValue = this.defaultCurrentValue();
    }
    this.currentValue[type] = this.yearToUnix(event);
    this.currentValue[`${type}Year`] = event;
    this.saveToCache();
    this.debounceTimeout = setTimeout(() => {
      this.filterChange.emit({
        filter: this.getCurrentFilter(),
        inverted: this.getCurrentFilter(true)
      })
      // REFINE
    }, 50)
  }

  protected saveToCache() {
    localStorage.setItem(`range-slider-${this.attribute}`, JSON.stringify(this.currentValue))
  }

  get sliderOptions(): Options {
    return {
      floor: this.min,
      ceil: this.max,
      step: this.step,
      hideLimitLabels: true,
      translate: (value2) => {
        if(value2 === this.max){
          return value2 + '+ ' + 'år';
        }
        //todo spørg om måneder vs mdr
        return value2 + ' år';
      },
    }
  }
}
