<footer class="flex flex-col py-[4%] bg-[#d2f4ea]">
  <div class="flex flex-row mx-auto justify-center w-full max-w-[80%] md:w-max-max py-[2%]">
    <div class="w-full grid gap-8 grid-cols-1 lg:grid-cols-4 ml:grid-cols-2">
      <div>
        <p class="text-[15px] text-[#2f8c7d] font-semibold mb-4 lg:mb-8">Om os</p>
        <ul>
          <li [ngClass]="classListOmOs">FAMOBA ApS</li>
          <li [ngClass]="classListOmOs">CVR: 40323945</li>
          <li class="flex flex-row items-center pt-5">
            <div class="flex items-center pr-4">
              <mat-icon class="text-[#388d7d] text-[18px] !h-[18px] !w-[18px]">mail_outline</mat-icon>
            </div>
            <a class="text-[15px] text-[#388d7d] font-light" href="mailto:info@famoba.dk">
              info@famoba.dk</a>
          </li>
         
        </ul>
      </div>
      <div>
        <p class="text-[15px] text-[#2f8c7d] font-semibold mb-4 lg:mb-8">Følg os</p>
        <div class="flex flex-row items-center">
            <a href="https://www.facebook.com/famobafamily" target="_blank" class="pr-2">
              <img src="/assets/icons/facebook.png" alt="icon facebook" />
            </a>
            <a href="https://www.instagram.com/famobadk/" target="_blank">
              <img src="/assets/icons/instagram.png" alt="icon instagram" />
            </a>
        </div>
      </div>
      <div>
        <p class="text-[15px] text-[#2f8c7d] font-semibold mb-4 lg:mb-8">Links</p>
        <ul class="pl-4">
          <li [ngClass]="['list-disc', classListOmOs]"><a href="https://famoba.dk/om-os">Om Famoba</a></li>
          <li [ngClass]="['list-disc', classListOmOs]"><a href="https://famoba.dk/kontakt/">Kontakt</a></li>
          <li [ngClass]="['list-disc', classListOmOs]"><a href="https://famoba.dk/cookies-privatlivspolitik/">Cookie og privatlivsbetingelser</a></li>
          <li [ngClass]="['list-disc', classListOmOs]"><a href="https://famoba.dk/handelsbetingelser/">Betingelser</a></li>
          <li [ngClass]="['list-disc', classListOmOs]"><a href="https://famoba.dk/faellesskabsregler/">Fællesskabsregler</a></li>
        </ul>
      </div>
      <div class="flex justify-center ml:justify-end">
        <a href="/">
          <img src="/assets/images/Famoba_Logo_new.png" class="w-[166px] h-[166px] lg:w-[217px] lg:h-[217px]" alt="logo"/>
        </a>
      </div>
    </div>
  </div>
</footer>
