/**
 * Created by ModelParser
 */
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface PreferenceDefinitionInterface {
    type?: string;
    user_id?: number;
    user?: UserInterface;
    low_date?: string;
    high_date?: string;
    low_value?: number;
    high_value?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class PreferenceDefinition extends BaseStoredModel implements PreferenceDefinitionInterface {
    type?: string;
    user_id?: number;
    user?: User;
    low_date?: string;
    high_date?: string;
    low_value?: number;
    high_value?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.type;
            delete this.user_id;
            delete this.user;
            delete this.low_date;
            delete this.high_date;
            delete this.low_value;
            delete this.high_value;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.low_date != null) {
            this.low_date = data.low_date;
        }
        if (data.high_date != null) {
            this.high_date = data.high_date;
        }
        if (data.low_value != null) {
            this.low_value = data.low_value;
        }
        if (data.high_value != null) {
            this.high_value = data.high_value;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
