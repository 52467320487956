/**
 * Created by ModelParser
 * Date: 09-06-2020.
 * Time: 08:36.
 */
import { FileDefinition, FileDefinitionInterface } from './definitions/FileDefinition';
import { FileTypesIcons, FileTypesNames } from '../constants';
import { environment } from '../../../environments/environment';

export interface FileInterface extends FileDefinitionInterface {

}

export class File extends FileDefinition implements FileInterface {

    constructor(json?: any) {
        super(json);
    }

    get icon(): string {
        return this.type ? FileTypesIcons[this.type] : 'fa-file';
    }

    get typeName(): string {
        return this.type ? FileTypesNames[this.type] : 'Ukendt';
    }

    get url(): string {
        return `${environment.apiUrl}/files/${this.path}/display`;
    }


}
