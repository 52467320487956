/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { InterestsTagDefinition, InterestsTagDefinitionInterface } from './definitions/InterestsTagDefinition';

export interface InterestsTagInterface extends InterestsTagDefinitionInterface {

}

export class InterestsTag extends InterestsTagDefinition implements InterestsTagInterface {

    constructor(json?: any) {
        super(json);
    }

}
