/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { CategoriesGroupDefinition, CategoriesGroupDefinitionInterface } from './definitions/CategoriesGroupDefinition';

export interface CategoriesGroupInterface extends CategoriesGroupDefinitionInterface {

}

export class CategoriesGroup extends CategoriesGroupDefinition implements CategoriesGroupInterface {

    constructor(json?: any) {
        super(json);
    }

}
