/**
 * Created by ModelParser
 * Date: 17-07-2019.
 * Time: 08:19.
 */
import { CategoryDefinition, CategoryDefinitionInterface } from './definitions/CategoryDefinition';

export interface CategoryInterface extends CategoryDefinitionInterface {

}

export class Category extends CategoryDefinition implements CategoryInterface {

    constructor(json?: any) {
        super(json);
    }
    public static createDefault() {
        const item = new Category();
        item.id = 0;
        return item;
    }

}
