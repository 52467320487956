import { ApiService } from './../../../services/api.service';
import { Activity } from './../../../pages/my-page/components/my-page-notifications/my-page-notifications.component';
import { UserInterface } from './../../models/User';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import {Observable, Subscription, combineLatest, timer} from "rxjs";
import {delay, filter, finalize, map, mergeMap, repeatWhen, shareReplay, switchMap, take, tap} from "rxjs/operators";
import { ROUTE } from '../shell.component';
import { User } from '../../models';
import { NotificationService } from './../../../services/notification.service';
import {UsersService} from "../../../services/users.service";
import { TwilioService } from './../../../services/twilio.service';
@Component({
  selector: "app-navigation-bar",
  templateUrl: "./navigation-bar.component.html",
  styleUrls: ["./navigation-bar.component.scss"]
})
export class NavigationBarComponent implements OnInit {
  // https://github.com/Robinyo/serendipity/tree/master/src/app/core/components/navigation-bar
  url$: Observable<any>;
  url: string;
  mobileMenuHidden: boolean = true;
  classMatTabLink: string = "!text-[13px] !text-[#000] !font-['Metropolis'] !font-normal !mx-0 !px-3 tracking-widest hover:!text-orange";
  feedUpdater$: Observable<any>;
  subscriptionSubscribedConversations: Subscription;
  notificationData$: Observable<{
    countFeed: number, 
    isLoadedFeed: boolean, 
    countConversations: number, 
    isLoadedConversations: boolean
  }>
  //feed
  readonly localStorageUsersKey = 'users';
  users: {[userStrapiId: number]: UserInterface} = {};
  firstRetrieve = true;

  @Output() toggleSidenav = new EventEmitter<void>();
  @Input() routes: ROUTE[] = [];
  @Input() mobile: boolean = false;
  @Input() user: User;

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected usersService: UsersService,
    protected notificationService: NotificationService,
    protected twilioService: TwilioService,
    protected apiService: ApiService
  ) {
    //url
    this.url$ = this.router.events.pipe(
      filter(d => d instanceof NavigationStart),
      map((d) => {
        return (d as NavigationStart).url;
      }),
      tap((d) => {
        this.mobileMenuHidden = true;
        this.url = d;
      })
    )
    this.url = (this.activatedRoute.snapshot as any)._routerState.url;
    //feed
    const usersData = localStorage.getItem(this.localStorageUsersKey);
    if (usersData) {
      this.users = JSON.parse(usersData);
    }
  }
 
  ngOnInit() {
    //prepare data
    const me = this.usersService.me();
    const feed = this.notificationService.getUserFeedId(me);
     //follow data
    this.feedUpdater$ = timer(0, 10000).pipe(
      switchMap(() => {
        return this.notificationService.getFeed(feed);
      }),
      tap(activities => {
        this.resolveActivityResolvers(activities);
        if(activities?.length > 0){
          this.notificationService.updateFeed(activities)
        }
      })
    )
    this.subscriptionSubscribedConversations = this.twilioService.asyncGetSubscribedConversations().subscribe(conversations => {
       if(conversations?.items?.length > 0){
        this.twilioService.updateCountConversations(conversations?.items?.filter(conversation => this.twilioService.hasUnreadMessages(conversation)).length);
      }
    });
    //get data
    this.notificationData$ = combineLatest([
      this.notificationService.getCountFeed(),
      this.notificationService.getIsLoadedFeed(),
      this.twilioService.getCountConversations(),
      this.twilioService.getIsLoadedConversations(),
    ]).pipe(
      map(([
        countFeed, isLoadedFeed, countConversations, isLoadedConversations
      ]) => ({ 
        countFeed, isLoadedFeed, countConversations, isLoadedConversations 
      }))
    )
  }

  ngOnDestroy() {
    this.subscriptionSubscribedConversations.unsubscribe();
  }

  getMobileMenuClass(active: boolean): string {
    let classList = 'text-center my-3.5 text-sm border-orange';
    if (active) {
      classList += ' border-b-4 font-bold';
    }
    return classList;
  }

  //feed
  async loadUsers(strapiUserIds: number[]) {
    const existingIds = Object.keys(this.firstRetrieve ? {} : this.users) as any as number[];
    const newIds = strapiUserIds.filter(d => existingIds.indexOf(d) === -1).filter(this.onlyUnique());
    if (!newIds?.length) {
      return;
    }
    const res = await this.apiService.api.users().getUsersByStrapiUserIds().addQueryParameter({strapiUserIds: newIds.join(',')}).executePromise();
    const users = res[0].users as {[id: string]: UserInterface};
    const userIds = Object.keys(users);
    for (const userId of userIds) {
      if (users[userId]?.id > 0) {
        this.users[userId] = users[userId];
      }
    }
    localStorage.setItem(this.localStorageUsersKey, JSON.stringify(this.users));
  }

  protected onlyUnique() {
    return (value, index, self) => {
      return self.indexOf(value) === index;
    }
  }

  resolveActivityResolvers(activities: Activity[]) {
    const resolvers = this.getDataResolversFromActivities(activities) as any;
    this.loadUsers(resolvers?.users);
    this.firstRetrieve = false;
  }

  getDataResolversFromActivities(activities: Activity[]) {
    const resolvers = {
      'users': []
    };
    if (!activities) {
      return resolvers;
    }

    for (const activity of activities) {
      if (['friend_request','friend_request_accept','friend_request_decline', 'new_post_in_group', 'new_comment_in_group_post', 'new_subscription_invite', 'denied_subscription_invite', 'accept_subscription_invite', 'delete_subscription_invite', 'group_request_accepted'].indexOf(activity.type) !== -1) {
        resolvers['users'].push(activity.data?.userId);
      }
    }

    return resolvers;
  }

}
