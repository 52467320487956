/**
 * Created by ModelParser
 */
import { Interest } from '../Interest';
import { Event, EventInterface } from '../Event';
import { InterestsTag, InterestsTagInterface } from '../InterestsTag';
import { EventsTag, EventsTagInterface } from '../EventsTag';
import { Group } from '../Group';
import { GroupsTag, GroupsTagInterface } from '../GroupsTag';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';
import { InterestAttributes } from './InterestDefinition';
import { GroupAttributes } from './GroupDefinition';

export interface TagDefinitionInterface {
    name?: string;
    interests?: StrapiModel<InterestAttributes>[];
    events?: EventInterface[];
    interests_tags?: InterestsTagInterface[];
    events_tags?: EventsTagInterface[];
    groups?: StrapiModel<GroupAttributes>[];
    groups_tags?: GroupsTagInterface[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class TagDefinition extends BaseStoredModel implements TagDefinitionInterface {
    name?: string;
    interests?: Interest[];
    events?: Event[];
    interests_tags?: InterestsTag[];
    events_tags?: EventsTag[];
    groups?: Group[];
    groups_tags?: GroupsTag[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.name;
            delete this.interests;
            delete this.events;
            delete this.interests_tags;
            delete this.events_tags;
            delete this.groups;
            delete this.groups_tags;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.interests != null) {
            this.interests = data.interests.map((i: any) => new Interest(i));
        }
        if (data.events != null) {
            this.events = data.events.map((i: any) => new Event(i));
        }
        if (data.interests_tags != null) {
            this.interests_tags = data.interests_tags.map((i: any) => new InterestsTag(i));
        }
        if (data.events_tags != null) {
            this.events_tags = data.events_tags.map((i: any) => new EventsTag(i));
        }
        if (data.groups != null) {
            this.groups = data.groups;
        }
        if (data.groups_tags != null) {
            this.groups_tags = data.groups_tags.map((i: any) => new GroupsTag(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
