import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {StrapiModel} from "../../../core/models/StrapiModel";
import {GroupAttributes} from "../../../core/models/definitions/GroupDefinition";
import {StrapiService} from "../../../services/strapi.service";
import { Interest } from '../../../core/models';

@Component({
  selector: 'app-big-group-card',
  templateUrl: './big-group-card.component.html',
  styleUrls: ['./big-group-card.component.scss']
})
export class BigGroupCardComponent implements OnInit {
  memberCount;
  groupName;
  city;
  @Input() group: StrapiModel<GroupAttributes>;
  @Input() tagsMatched: Interest[];
  @Input() tagsNoMatched: Interest[];
  isShowMatched:boolean = false;

  constructor(protected strapiService: StrapiService, private elRef: ElementRef) {
  }

  ngOnInit(): void {
    this.memberCount = this.getMemberCount();
    this.groupName = this.group?.attributes?.name;
    this.city = this.group?.attributes?.addressInfo?.['city'];
    const imgUrl = this.getImageUrl();
    if (imgUrl) {
      this.elRef.nativeElement.style.backgroundImage = `url(${imgUrl})`
    }
  }

  getImageUrl(): string {
    return this.strapiService.getStrapiImageSrc(this.group?.attributes?.banner_image, this.group?.attributes?.banner_image?.attributes?.formats?.large ? 'large' : 'medium');
  }

  getMemberCount() {
    let count = this.group?.attributes?.membersCount;
    if (count > 0 && count !== undefined) {
      return count
    } else {
      return 'Ingen';
    }
  }

  toggleShowMatched(){
    if(this.isShowMatched){
      this.isShowMatched = false;
    }else{
      this.isShowMatched = true;
    }
  }
}
