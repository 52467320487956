<ng-container *ngIf="interests$|async"></ng-container>
<app-extended-infinite-hits [generateFilters]="generateFilters" #extendedInfiniteHits></app-extended-infinite-hits>

<div class="my-8 md:mb-28 flex flex-wrap">
  <div class="p-2.5" *ngIf="showDebug">
    <label>
      Sorting:
      <select (change)="sortingChange($event)">
        <option value="location" [selected]="getSortingIndex() === 'members_location'">Afstand</option>
        <option value="tags" [selected]="getSortingIndex() === 'members'">Interesser</option>
      </select>
    </label>
    <label class="mt-2.5">Afstands præcision (m): <input type="number" min="0" placeholder="precision in meter"
        (change)="distanceChange($event, 'search-precision')" [value]="getFixedDistance('search-precision')"></label>
    <p class="italic">Læs mere her: <a
        href="https://www.algolia.com/doc/guides/managing-results/refine-results/geolocation/in-depth/geo-ranking-precision/"
        target="_blank">_link_</a></p>
  </div>
  <ng-container
    *ngFor="let hitGroup of extendedInfiniteHits.combinedHits; let i = index; trackBy: trackFilterGroup()">
    <div class="p-2.5 w-full" *ngIf="showDebug">
      <h3>Search #{{i}}: {{extendedInfiniteHits?.filters?.[i]?.title}}</h3>
      <h5>Filter: {{extendedInfiniteHits?.filters?.[i]?.searchParams.filters|json}}</h5>
      <h5 class="italic" *ngIf="hitGroup.length === 0">No results</h5>
    </div>
    <div class="p-2.5 w-full" [class.mt-12]="i !== 0"
      *ngIf="extendedInfiniteHits?.filters?.[i]?.title && hitGroup.length > 0">
      <h3>{{extendedInfiniteHits?.filters?.[i]?.title}}</h3>
    </div>
    <ng-container *ngFor="let hit of hitGroup; trackBy: trackBy()">
      <div class="p-2.5 w-full md:w-6/12" *ngIf="!isHidden(hit)" [class.z-20]="idTopPosition === hit?.objectID"
        (mouseenter)="changePositionIndex($event, hit)" (mouseleave)="changePositionIndex($event, hit)"
        (touchstart)="changePositionIndex($event, hit)" (touchend)="changePositionIndex($event, hit)">
        <app-user-card *ngIf="hitToMember(hit) as res" [user]="res.user" [tagsMatched]="res.tagsMatched"
          [tagsNoMatched]="res.tagsNoMatched" (click)="openProfile(hit)"></app-user-card>
      </div>
    </ng-container>
  </ng-container>
  <div class="w-full flex justify-center mt-8 mb-14" *ngIf="extendedInfiniteHits.canShowMore()">
    <button (click)="extendedInfiniteHits.showMore()">Indlæs flere</button>
  </div>
</div>
