<div class="container mt-4">
    <div *ngIf="isCameraExist; else noCameraExist">
        <div class="text-center">
            <ng-container *ngIf="!isTookImage; else changeImage">
                <webcam [height]="500" [width]="1000" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                    [switchCamera]="nextWebcamObservable"></webcam>
                <br />
                <p class="text-center underline text-sm pt-4 text-[#313131] underline-offset-[6px] cursor-pointer"
                    (click)="takeSnapshot()">
                    Tag billede</p>
            </ng-container>
            <ng-template #changeImage>
                <p class="text-center underline text-sm pt-4 text-[#313131] underline-offset-[6px] cursor-pointer"
                    (click)="reTakeSnapshot()">
                    Tag billeder igen</p>
            </ng-template>
            <p class="text-center underline text-sm pt-[15px] text-[#313131] underline-offset-[6px] cursor-pointer"
                (click)="changeToUploadImage()">
                Upload billede</p>
        </div>

        <div *ngIf="errors.length > 0">
            <h4>Error Messages:</h4>
            <ul *ngFor="let error of errors">
                <li>{{ error | json }}</li>
            </ul>
        </div>
    </div>

    <ng-template #noCameraExist>
        <p>Camera device not available</p>
        <p class="text-center underline text-sm pt-[15px] text-[#313131] underline-offset-[6px] cursor-pointer"
            (click)="changeToUploadImage()">
            Upload billede</p>
    </ng-template>
</div>