/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { GroupsTagDefinition, GroupsTagDefinitionInterface } from './definitions/GroupsTagDefinition';

export interface GroupsTagInterface extends GroupsTagDefinitionInterface {

}

export class GroupsTag extends GroupsTagDefinition implements GroupsTagInterface {

    constructor(json?: any) {
        super(json);
    }

}
