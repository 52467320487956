/**
 * Created by ModelParser
 */
import { Interest } from '../Interest';
import { BaseStoredModel } from '../BaseStoredModel';
import { AddressDefinitionInterface } from './AddressDefinition';
import { StrapiModel } from '../StrapiModel';
import { StrapiImage } from './UserDefinition';

export interface GroupModerator {
  role: 'owner' | 'admin';
  id: number;
  memberId: number;
}

export interface GroupAttributes {
  name: string;
  description: string;
  max_members: number;
  banner_image: StrapiImage;
  tags: Interest[];
  address: AddressDefinitionInterface;
  membersCount: number;
  addressInfo: object;
  genders: string[];
}

export class GroupDefinition extends BaseStoredModel implements StrapiModel<GroupAttributes> {
  attributes: GroupAttributes;
  id: number;

  protected dataFields(): any[] {
    return ['id', 'attributes'];
  }

  populate(data?: any, patch: boolean = false) {
    super.populate(data, patch);
  }
}
