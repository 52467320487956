/**
 * Created by ModelParser
 */
import { Permission, PermissionInterface } from '../Permission';
import { Subscription, SubscriptionInterface } from '../Subscription';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface PlanDefinitionInterface {
    name?: string;
    price?: number;
    permissions?: PermissionInterface[];
    subscriptions?: SubscriptionInterface[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class PlanDefinition extends BaseStoredModel implements PlanDefinitionInterface {
    name?: string;
    price?: number;
    permissions?: Permission[];
    subscriptions?: Subscription[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.name;
            delete this.price;
            delete this.permissions;
            delete this.subscriptions;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.price != null) {
            this.price = data.price;
        }
        if (data.permissions != null) {
            this.permissions = data.permissions.map((i: any) => new Permission(i));
        }
        if (data.subscriptions != null) {
            this.subscriptions = data.subscriptions.map((i: any) => new Subscription(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
