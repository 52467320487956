import { ApiCollectionBase } from '../ApiCollectionBase';
import { BaseResourceApi } from '../BaseResourceApi';

export class ApiCart extends ApiCollectionBase {

  public checkoutPost(): BaseResourceApi<any> {
    return new BaseResourceApi<any>(this.config, {
      uri: '/cart/checkout',
      method: 'post'
    });
  }

  public paidPost(): BaseResourceApi<any> {
    return new BaseResourceApi<any>(this.config, {
      uri: `/cart/paidPost`,
      method: 'post'
    });
  }

  public paidGet(): BaseResourceApi<any> {
    return new BaseResourceApi(this.config, {
      uri: `/cart/paidGet`
    });
  }

}
