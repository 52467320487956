<mat-icon mat-dialog-close="close" class="dialog-close-icon">close</mat-icon>
<mat-dialog-content class="w-full mat-typography !m-0 !flex flex-col justify-center !overflow-hidden !py-9 !px-44">
    <div class="flex flex-col items-center justify-center text-center">
        <h3 class="text-[#313131] font-semibold">Afsendte anmodninger</h3>
        <div class="w-full" *ngIf="users$|async as users; else loading;">
            <div *ngIf="users?.length > 0; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <div class="pt-7">
                    <div *ngFor="let user of users">
                        <app-user-matches-card class="mb-4" [user]="user" [component]="component" (filterUsers)="filterUsers()"></app-user-matches-card>
                    </div>
                </div>
            </ng-template>
            <ng-template #elseBlock>
                <div class="pt-24">
                    <p class="flex flex-col items-center justify-center">
                        <img src="/assets/logo-icon.svg" class="w-40 opacity-20">
                        Når du sender nogen en venneanmodning, vises det her.
                    </p>
                </div>
            </ng-template>
        </div>
        <ng-template #loading>
            <div class="w-40 h-40 m-auto">
                <app-spinner></app-spinner>
            </div>
        </ng-template>
    </div>
</mat-dialog-content>