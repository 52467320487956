<div class="flex items-center w-full" [ngClass]="selectedIndex === 1 && 'pb-3.5 md:pb-0'">
  <h2 class="flex-grow">Udforsk</h2>
  <a class="button outline ml-4 w-[158px] h-[44px] !leading-[44px] !p-0" (click)="createGroup()" *ngIf="selectedIndex === 1">Opret gruppe</a>
</div>
<p *ngIf="selectedIndex === 0" class="max-w-lg" [ngClass]="activeSubscription ? 'pb-[50px] md:pb-[25px]' : 'pb-[30px] md:pb-[35px]'">Her kan du udvide dit netværk. Brug søgeprofilen til venstre og få
  straks de bedste matches baseret på afstand, dine interesser samt dig og dit barns alder</p>
<p *ngIf="selectedIndex === 1" class="max-w-lg" [ngClass]="activeSubscription ? 'pb-[50px] md:pb-[25px]' : 'pb-[30px] md:pb-[35px]'">Opdag mulighederne i Famobas mange forældregrupper. Udfyld din
  søgeprofil, så finder vi de grupper, der passer bedst til dig.</p>

<app-subscribe-action class="w-full flex md:flex-row mb-9 md:mb-12" *ngIf="!activeSubscription"></app-subscribe-action>

<mat-tab-group
  animationDuration="0" #tabGroup (focusChange)="changeIndex($event.index)" [selectedIndex]="selectedIndex"
>
  <mat-tab label="Ligesindede">
    <ng-template matTabContent>
      <app-explore-like-minded></app-explore-like-minded>
    </ng-template>
  </mat-tab>
  <mat-tab label="Grupper">
    <ng-template matTabContent>
      <app-explore-groups></app-explore-groups>
    </ng-template>
  </mat-tab>
</mat-tab-group>
