/**
 * Created by ModelParser
 * Date: 16-07-2019.
 * Time: 10:20.
 */
import { AttachmentDefinition, AttachmentDefinitionInterface } from './definitions/AttachmentDefinition';

export interface AttachmentInterface extends AttachmentDefinitionInterface {

}

export class Attachment extends AttachmentDefinition implements AttachmentInterface {

    constructor(json?: any) {
        super(json);
    }

}
