/**
 * Created by ModelParser
 * Date: 15-07-2019.
 * Time: 07:57.
 */
import { EventDefinition, EventDefinitionInterface } from './definitions/EventDefinition';
import { environment } from '../../../environments/environment';

export interface EventInterface extends EventDefinitionInterface {

}

export class Event extends EventDefinition implements EventInterface {

    constructor(json?: any) {
        super(json);
    }

    get image(): string {
        if (this.files && this.files.length > 0) {
            return `${environment.apiUrl}/files/${this.files[0].id}/display`;
        }
        if (this.attachments && this.attachments.length > 0) {
            return `${environment.apiUrl}/storage?file=${this.attachments[0].path}`;
        }
        return null;
    }

    public static createDefault() {
        const item = new Event();
        item.id = 0;
        return item;
    }

    get spotsAvailable(): number {
        if (this.spots && this.attendee_count) {
            return this.spots - this.attendee_count;
        }
        if (this.spots) {
            return this.spots;
        }
        return -1;
    }

    get available(): boolean {
        const spots = this.spotsAvailable;
        if (spots && spots > 0) {
            return true;
        }
        else if (spots === -1){
            return true;
        }
        return false;
    }

    get addressItems(): string[] {
        if (this.location) {
            return this.location.split(',');
        }
    }

    get locationString(): string {
        const googleURI = '//www.google.com/maps?t=&z=17&ie=UTF8&iwloc=&output=embed&q=';
        if (this.location) {
            return googleURI + this.location
                .split(' ').join('+')
                .split(',').join('');
        }
        return '';
    }


}
