<ng-container *ngIf="interests$|async"></ng-container>

<form #form="ngForm" *ngIf="user" (keydown.enter)="$event.preventDefault()">
  <div class="md:max-w-[373px] max-w-[300px] mx-auto">
    <div class="text-center">
      <h2 class="text-2xl font-semibold">{{getStepTitle(stepper?.selectedIndex)}}</h2>
      <p class="mt-2">{{getDescription(stepper?.selectedIndex)}}
      </p>
    </div>

    <div class="px-3 mb-12">
      <mat-horizontal-stepper class="-mx-6" linear labelPosition="bottom" #stepper [@.disabled]="true">
        <mat-step [completed]="isStepComplete(0)">
          <app-address class="mt-8" [address]="user.address" (addressChange)="changeAddress($event)">
          </app-address>
        </mat-step>
        <mat-step [completed]="isStepComplete(1)">
          <div class="mt-8">
            <p class="text-left font-semibold mb-2">Rolle</p>
            <app-chip-select [size]="'large'" [value]="user.gender" (change)="updateGender($event)"
              [options]="{mom: 'Mor', dad: 'Far', parent: 'Forældre'}"></app-chip-select>
            <mat-form-field class="w-full mt-8" color="accent">
              <mat-label>Fødselsdag</mat-label>
              <input 
                matInput type="date" 
                (change)="updateParentBirthday($event)" 
                formControlName="birthdate" 
                [value]="user.birthdate"
                max="9999-12-31"
                required
              >
            </mat-form-field>
          </div>
        </mat-step>
        <mat-step [completed]="isStepComplete(2)">
          <div class="md:-mx-4">
            <p class="font-semibold mt-8 mb-3">Tilføj barn/børn/terminsdato</p>
            <app-edit-children-form [children]="user.children || []" (childrenChange)="updateChildren($event)">
            </app-edit-children-form>
          </div>
        </mat-step>
        <mat-step [completed]="isStepComplete(3)">
          <div class="w-10/12 mx-auto" *ngIf="interests">
            <p class="italic my-3 block text-center">Søg efter dine interesser og emner her..</p>
            <form class="mt-3">
              <div class="relative">
                <app-icon class="h-[50%] absolute pin-r pin-t mt-[0.5rem] ml-2 mr-4" color="black" icon="search"></app-icon>
                <input class="border rounded w-full h-[33px] pl-8" placeholder="" (keyup)="onSearch($event.target, tagSearch)">
              </div>
            </form>
            <small class="italic my-3 block text-gray text-center">Vælg mindst 1 interesse.
              Du kan altid ændre dem senere</small>
          </div>
          <app-algolia-tag-search
            #tagSearch
            class="green"
            [initialSearchValue]="userInterestLabels"
            (change)="changeValueNew(interests, $event)"
            [indexName]="'members'"
            [attribute]="'tags.label'"
          >
          </app-algolia-tag-search>
        </mat-step>
        <mat-step [completed]="isStepComplete(4)">
          <div class="flex flex-column justify-center mt-8">
            <div class="inline-block">
              <ng-container *ngIf="!isTakeImage; else contentTakeImage">
                <div class="flex flex-col justify-center">
                  <app-user-profile-icon [user]="selectedFile ? previewUser(user, selectedFile) : user" class="inline-block w-40 h-40">
                  </app-user-profile-icon>
                  <p 
                    class="text-center underline text-sm pt-[15px] text-[#313131] underline-offset-[6px] cursor-pointer"
                    (click)="openDialogUploadImage(user)"
                  >
                    Upload billede</p>
                </div>
                <p class="text-center underline text-sm pt-4 text-[#313131] underline-offset-[6px] cursor-pointer" (click)="enableTakeImage()">
                  Tag billede</p>
              </ng-container>
              <ng-template #contentTakeImage>
                <ng-container *ngIf="isTookImage">
                  <div class="webcam-image" *ngIf="webcamImage">
                    <img [src]="webcamImage.imageAsDataUrl" />
                  </div>
                </ng-container>
                <app-camera (getPicture)="handleImage($event)" (getFile)="handleImageTook($event)"></app-camera>
                <router-outlet></router-outlet>
              </ng-template>

            </div>
          </div>
          <div class="pt-11">
            <div class="mb-2.5 flex flex-row justify-between">
              <p class="font-semibold">Om dig</p>
              <small class="text-xs">Maks. 350 tegn</small>
            </div>
            <mat-form-field class="w-full">
              <textarea matInput maxlength="350" rows="5" (change)="updateDescription($event)" formControlName="description"
                placeholder="Beskriv lidt om hvad du er på udkig efter..."></textarea>
            </mat-form-field>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
      <ng-container *ngIf="!isLoadingSubmit; else loadingButton">
        <button type="button" class="w-full" [disabled]="!isStepComplete(stepper?.selectedIndex)"
          (click)="onSubmit(form, stepper?.selectedIndex); stepper.next()">Næste</button>
      </ng-container>
      <ng-template #loadingButton>
        <button [disabled]="true" class="w-full">Loading...</button>
      </ng-template>
    </div>
  </div>
</form>
