import { DiffAgeService } from './../../../services/diff-age.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { base64ToFile } from 'ngx-image-cropper';
import { WebcamImage } from 'ngx-webcam';
import {take, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import { Interest, User } from '../../../core/models';
import { UsersService } from '../../../services/users.service';
import { ApiService } from '../../../services/api.service';
import { UploadService } from '../../../services/upload.service';
import { InterestsService } from '../../../services/interests.service';
import { ChipSelectOptions, ChipSelectValue } from '../../../components/organisms/chip-select/chip-select.component';
import { ChildrenChangeEvent } from '../../../components/organisms/edit-children-form/edit-children-form.component';
import {AlgoliaTagSearchComponent} from "../../../components/organisms/algolia-tag-search/algolia-tag-search.component";
import { StrapiModel } from '../../../core/models/StrapiModel';
import { ChildDefinitionAttributes } from '../../../core/models/definitions/ChildDefinition';
import { AddressDefinitionInterface } from '../../../core/models/definitions/AddressDefinition';
import { UploadImageDialogComponent } from './../../../shared/dialogs/upload-image-dialog/upload-image-dialog.component';

@Component({
  selector: 'app-signup-city-form',
  templateUrl: './signup-city-form.component.html',
  styleUrls: ['./signup-city-form.component.scss'],
})
export class SignupCityFormComponent implements OnInit {
  interests$: Observable<Interest[]>;
  search: string;
  selectedFile;
  isLoadingSubmit: boolean = false;

  clonedUser: User;

  get user(): User {
    return this._user;
  }

  @Input()
  set user(value: User) {
    this._user = value;
    if (!this.clonedUser) {
      this.clonedUser = {...value} as any;
    }
  }

  private _user: User;

  defaultInterests: Interest[];
  interests: Interest[];

  updatedChildren: StrapiModel<ChildDefinitionAttributes>[];

  @ViewChild('stepper')
  set stepper(value: MatStepper) {
    if (!value) {
      return;
    }
    value._getIndicatorType = () => 'number'
    for (let i = 0; i < value.steps.length; i++) {
      if (this.isStepComplete(i)) {
        continue;
      }
      value.selectedIndex = i;
      break;
    }
  }

  constructor(
    protected apiService: ApiService,
    protected interestService: InterestsService,
    protected router: Router,
    protected userService: UsersService,
    protected uploadService: UploadService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private diffAgeService: DiffAgeService,
  ) {
  }

  get isTakeImage(): boolean {
    return this.uploadService.isTakeImage;
  }

  get isTookImage(): boolean {
    return this.uploadService.isTookImage;
  }

  get isAddressComplete() {
    return this.userService.isUserAddressComplete(this.clonedUser)
  }

  get isProfileImageComplete() {
    return this.selectedFile !== undefined
  }

  get isProfileImageTakeComplete() {
    return this.webcamImage !== undefined
  }

  ngOnInit(): void {
    this.interests$ = this.interestService.getInterests().pipe(
      tap(event => {
        this.interests = event;
        this.defaultInterests = event
      })
    );
  }

  interestOptions(interests: Interest[]): ChipSelectOptions {
    return interests?.reduce((p, c) => {
      p[c.id] = c.attributes.label
      return p;
    }, {}) ?? {};
  }

  get isChildrenValid() {
    const children = this.updatedChildren;
    if (!children) {
      return false;
    }
    const validChildren = children.filter(d => d.attributes?.name.length > 0 && d.attributes?.birthdate && d.attributes?.gender && d.attributes?.gender);
    return validChildren.length === children.length && children.length > 0;

  }

  updateGender(value: any) {
    this.clonedUser.gender = value;
  }

  updateParentBirthday(value: any) {
    this.clonedUser.birthdate = value?.target?.value;
  }

  updateDescription(value: any) {
    this.clonedUser.description = value?.target?.value;
  }

  private updateComplete(user: User) {
    this.user.id = user.id;
    this.user.children?.forEach((child) => {
      if (!child.attributes) {
        return;
      }
      child.attributes.user_id = this._user.id
    });
    this.userService.reloadUser();
  }

  getStepTitle(step: number) {
    const title = [
      'Hvor bor du?',
      'Lidt mere om dig',
      'Lidt om de yngste',
      'Dine interesser',
      'Så er du næsten i mål!'
    ];
    return title[step];
  }

  getDescription(step: number) {
    const description = [
      'Indtast din adresse, så du kan møde ligesindede i dit nærområde. Din præcise adresse vil ikke blive vist offentligt.',
      'Angiv din rolle som forælder og din alder, så vi kan matche dig bedst muligt med ligesindede.',
      'For at give dig de bedste matches med ligesindede, skal du udfylde lidt om dine børn eller en forventet terminsdato.',
      'Udvælg de interesser og emner, der passer bedst til dig.',
      'Fuldend din profil ved at lægge et vellignende billede op af dig selv samt en kort beskrivelse af hvad du søger, så andre ligesindede bedre kan finde dig.'
    ];
    return description[step];
  }

  isStepComplete(step: number): boolean {
    const validators = [
      () => this.isAddressComplete,
      () => this.clonedUser.gender?.length > 0 && this.checkBirthdate(),
      () => this.isChildrenValid,
      () => this.clonedUser.tags?.length >= 1,
      () => this.isProfileImageComplete || this.isProfileImageTakeComplete
    ];

    const fn = validators[step];
    if (!fn) {
      return true;
    }

    return fn();
  }

  checkBirthdate(): boolean {
    const birthdate = this.clonedUser?.birthdate;
    if(birthdate === undefined || birthdate?.length === 0) return false;
    const age = this.diffAgeService.handleDiffAge(birthdate);
    if(age < this.diffAgeService.minAge || age > this.diffAgeService.maxAge) return false;
    return true;
  }

  changeAddress(address: AddressDefinitionInterface) {
    this.clonedUser.address = address;
  }

  async onSubmit(form: NgForm, step: number) {
    this.isLoadingSubmit = true;
    let imageUpload;
    console.log('Updating!');
    if (!this.isStepComplete(step)) {
      console.log(`step ${step} is incomplete`);
      this.isLoadingSubmit = false;
      return;
    }
    if (form.invalid) {
      console.log('form is invalid')
      this.isLoadingSubmit = false;
      return;
    }

    if (!this.clonedUser.id) {
      console.log('no user id found')
      this.isLoadingSubmit = false;
      return;
    }
    if (this.selectedFile || this.webcamImage) {
      if(this.isTakeImage){
        imageUpload = await this.uploadFile(this.webcamFileImage);
      }else{
        imageUpload = await this.uploadFile(base64ToFile(this.selectedFile));
      }
      this.clonedUser.profile_image = imageUpload;
    }

    const data = {...this.clonedUser};

    if (this.updatedChildren) {
      data.children = this.updatedChildren as any;
    }

    const user = await this.userService.saveUser(data as User);
    this.isLoadingSubmit = false;
    this.updateComplete(user)
    this.router.navigateByUrl('/');
  }

  updateChildren(event: ChildrenChangeEvent) {
    if (!event.valid) {
      this.updatedChildren = undefined;
      return;
    }
    this.updatedChildren = event.children;
  }

  changeValue(interests: Interest[], values: ChipSelectValue[] | ChipSelectValue) {
    if (!Array.isArray(values)) {
      return;
    }
    this.clonedUser.tags = interests?.filter(d => values.findIndex(b => d.id == b) !== -1) ?? [];
  }

  changeValueNew(interests: Interest[], labels: ChipSelectValue[]) {
    if (!Array.isArray(labels)) {
      return;
    }
    this.clonedUser.tags = interests?.filter(d => labels.findIndex(b => d?.attributes?.label == b) !== -1) ?? [];
  }

  get userInterestIds() {
    return this.clonedUser?.tags?.map(m => m.id);
  }

  get userInterestLabels() {
    return this.clonedUser?.tags?.map(m => m?.attributes?.label);
  }

   previewUser(user: User, previewImage: string): User {
    const newUser = {...user};
    newUser.profile_image = {
      id: undefined,
      attributes: {
        url: previewImage
      }
    }
    return newUser as any;
  }

  async uploadFile(file: Blob): Promise<number> {
     if((file.size / 1048576 )> 8) {
        this.snackBar.open('Billedet er for stort. Max 8MB.');
        return;
    }
    const formData: FormData = new FormData();
    formData.append('files', file);
    const result = await this.apiService.api.strapi().upload('profile_image').executePromise(formData as any);
    return result[0][0]?.id;
  }
  // take image
  enableTakeImage(){
    this.uploadService.setIsTakeImage(true);
  }

  webcamImage: WebcamImage | undefined;
  webcamFileImage: File | undefined;

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  handleImageTook(file: File) {
    this.webcamFileImage = file;
  }
  filterByValue(array, string) {
    return array.filter(o =>
       Object.keys(o).some(k => typeof o[k] === 'object' && String(o[k]?.['label']).toLowerCase().includes(string.toLowerCase())));
  }

  onSearch(search: EventTarget, algoliaTagSearch: AlgoliaTagSearchComponent) {
    algoliaTagSearch.searchForItems((search as any).value)
  }

  openDialogUploadImage(me: User) {
    return this.dialog.open(UploadImageDialogComponent, {
      data: {
        me: me,
        previewUser: this.previewUser,
        maintainAspectRatio: 'true',
        aspectRatio: '1',
        width: "400"
      },
      width: '962px',
    }).afterClosed().pipe(take(1)).subscribe(data => {
      if(data?.croppedImage !== undefined){
        this.selectedFile = data?.croppedImage
      }
    });
  }
}
