import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fromEvent, Observable} from "rxjs";
import {filter, map, tap} from "rxjs/operators";
import {ApiService} from "../../../services/api.service";
import {User} from "../../../core/models";
import {TwilioService} from "../../../services/twilio.service";
import {UsersService} from "../../../services/users.service";
import {SingleChatDialogComponent} from "../single-chat-dialog/single-chat-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-new-chat-dialog',
  templateUrl: './new-chat-dialog.component.html',
  styleUrls: ['./new-chat-dialog.component.scss']
})
export class NewChatDialogComponent implements OnInit, AfterViewChecked {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>
  searchEvent$: Observable<any>;
  users: User[];
  isFetching = false;
  timeout;

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private twilioService: TwilioService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
  }
  ngAfterViewChecked(): void {
    const input = this.searchInput?.nativeElement;
    this.searchEvent$ = fromEvent(input, 'keyup').pipe(
      tap(async () => {
        this.fetchData(input.value.trim())
      })
    )
  }

  fetchData(query: string) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(async () => {
      this.isFetching = true;
      this.users = await this.apiService.api.users()
        .searchFriends()
        .addQueryParameter({query})
        .executePromise();
      this.isFetching = false;
    }, 250)
  }

  openChat(user: User) {
    this.dialog.closeAll();
    this.dialog.open(SingleChatDialogComponent, {
      data: this.twilioService.sendMessageTo(parseInt(this.usersService.me().strapi_user_id), parseInt(user.strapi_user_id)).pipe(
        filter(d => !!d),
        map(d => {
          return {sid: d.sid}
        })
      )
    })
  }

}
