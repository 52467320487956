import {ApiCollectionBase} from "../ApiCollectionBase";
import {BaseGetResourceApi} from "../BaseGetResourceApi";
import {Notification, User, UserStar} from "../../../models";
import {BaseResourceApi} from "../BaseResourceApi";
import {StrapiModel} from "../../../models/StrapiModel";
import {GroupAttributes} from "../../../models/definitions/GroupDefinition";

export class UsersApi extends ApiCollectionBase {

  public get(): BaseGetResourceApi<User> {
    return new BaseGetResourceApi<User>(this.config, {
      resourceClass: User,
      uri: '/users'
    });
  }

  public zendeskEndpoint(): BaseGetResourceApi<{ endpoint: string }[]> {
    return new BaseGetResourceApi<{endpoint: string}[]>(this.config, {
      uri: '/zendesk_redirect'
    })
  }

  public getById(id: number): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users/${id}`
    });
  }

  public post(): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users`,
      method: 'post'
    });
  }

  public putById(id: number): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users/${id}`,
      method: 'put'
    });
  }

  public put(): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users`,
      method: 'put'
    });
  }

  public patchById(id: number): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users/${id}`,
      method: 'patch'
    });
  }

  public patch(): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users`,
      method: 'patch'
    });
  }

  public patchActiveChild(): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users/children/setActive`,
      method: 'patch'
    });
  }

  public deleteById(id: number): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/users/${id}`,
      method: 'delete'
    });
  }

  public meGet(): BaseResourceApi<User> {
    return new BaseResourceApi<User>(this.config, {
      resourceClass: User,
      uri: `/me`
    });
  }

  public getStripeBillingPortalSession(): BaseResourceApi<[{ sessionURL: string }], {returnURL: string}> {
    return new BaseResourceApi(this.config, {
      uri: `/users/stripe_billing_portal_session`
    });
  }

  public getStreamSessionToken(): BaseResourceApi<[{ sessionToken: string }]> {
    return new BaseResourceApi(this.config, {
      uri: `/users/stream_session_token`
    });
  }

  public getUsersByStrapiUserIds(): BaseResourceApi<[{ users: {[strapiUserId: string]: User} }], {strapiUserIds: string}> {
    return new BaseResourceApi(this.config, {
      uri: `/users/users_by_strapi_ids`
    });
  }

  public getStripeCheckoutSession(): BaseResourceApi<[{ sessionURL: string }], {returnURL: string, priceId: string, productId: string}> {
    return new BaseResourceApi(this.config, {
      uri: `/users/stripe_checkout_session`
    });
  }

  public starPutPutById(id: number): BaseResourceApi<UserStar> {
    return new BaseResourceApi<UserStar>(this.config, {
      uri: `/users/${id}/star`,
      resourceClass: UserStar,
      method: 'put',
      summary: 'Favorite this person. If starred both ways, it will trigger a match.'
    });
  }

  public starDeleteDeleteById(id: number): BaseResourceApi<UserStar> {
    return new BaseResourceApi<UserStar>(this.config, {
      uri: `/users/${id}/star`,
      resourceClass: UserStar,
      method: 'delete',
      summary: 'Un-Favorite this person'
    });
  }

  public noStarPutPutById(id: number): BaseResourceApi<UserStar> {
    return new BaseResourceApi<UserStar>(this.config, {
      uri: `/users/${id}/nostar`,
      resourceClass: UserStar,
      method: 'put',
      summary: 'No-Favorite this person'
    });
  }

  public getNotifications(): BaseGetResourceApi<Notification> {
    return new BaseGetResourceApi<Notification>(this.config, {
      uri: '/users/getNotifications',
      resourceClass: Notification
    });
  }

  public getTwilioToken(): BaseGetResourceApi<[{token: string}]> {
    return new BaseGetResourceApi<[{token: string}]>(this.config, {
      uri: '/users/twilio_token'
    });
  }

  public searchFriends(): BaseGetResourceApi<User[]> {
    return new BaseGetResourceApi<User[]>(this.config, {
      uri: '/users/search_friends'
    });
  }

  public searchFriendsAccepted(): BaseGetResourceApi<User[]> {
    return new BaseGetResourceApi<User[]>(this.config, {
      uri: '/users/search_friends_accepted'
    });
  }

  public searchFriendsPending(): BaseGetResourceApi<User[]> {
    return new BaseGetResourceApi<User[]>(this.config, {
      uri: '/users/search_friends_pending'
    });
  }

  public currentGroups(): BaseGetResourceApi<[StrapiModel<GroupAttributes>[]]> {
    return new BaseGetResourceApi<[StrapiModel<GroupAttributes>[]]>(this.config, {
      uri: '/users/me/groups'
    });
  }

  public deleteMatch(userId: number): BaseResourceApi<any> {
    return new BaseResourceApi<any>(this.config, {
      uri: `/users/delete-match/${userId}`,
      method: 'delete'
    });
  }
}
