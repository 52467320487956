import {Injectable} from '@angular/core';
import {BehaviorSubject, from, Observable, of, ReplaySubject, switchMap} from "rxjs";
import {map, take} from "rxjs/operators";
import {Client, Conversation, Message, Paginator} from "@twilio/conversations";
import {StoredCacheObservable} from "../core/stored-cache-observable";
import {environment} from "../../environments/environment";
import {ApiService} from './api.service';
import {UsersService} from "./users.service";
@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  private _client: Client;
  private clientSubject: ReplaySubject<Client>;
  private conversationsReloader: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private countConversations: BehaviorSubject<number> = new BehaviorSubject(0);
  private isLoadedConversations = new BehaviorSubject<boolean>(false);
  private isOpenModal = new BehaviorSubject<boolean>(false);
  private activeConveration = new BehaviorSubject<Conversation>(undefined);
  constructor(
    private apiService: ApiService,
    private usersService: UsersService
  ) {
  }

  reloadSubscribedConversations() {
    this.conversationsReloader.next(undefined);
  }

  asyncGetSubscribedConversations(): Observable<Paginator<Conversation>> {
    return this.conversationsReloader.pipe(
      switchMap(() => from(this.getSubscribedConversations())),
    ) as any
  }


  async getSubscribedConversations() {
    const client = await this.getClient();
    return client.getSubscribedConversations();
  }

  async getCurrentUserTwilioToken(): Promise<string> {
    const result = await this.apiService.api.users().getTwilioToken().executePromise();
    return result[0].token;
  }

  sendMessageTo(selfStrapiUserId: number, strapiUserId: number): StoredCacheObservable<{ uniqueName: string, sid: string }> {
    return new StoredCacheObservable({
      localStorageKey: `conversation-${selfStrapiUserId}-${strapiUserId}`,
      observable: this.apiService.api.messages().sendMessageTo().execute({strapiUserId: strapiUserId}).pipe(
        map(d => d[0]),
      )
    }) as any;
  }

  getUserByConversationUniqueName(conv: Conversation) {
    const prefix = '1-to-1-';
    if (conv.uniqueName?.indexOf(prefix) !== 0) {
      return of(undefined);
    }
    const selfId = this.usersService.me().strapi_user_id;
    const [userId1, userId2] = conv.uniqueName.substring(prefix.length).split('-');
    const userId = userId1 == selfId ? userId2 : userId1;

    return this.usersService.getUserById(parseInt(userId));
  }

  async getClient(): Promise<Client> {
    if (!this.clientSubject) {
      this.clientSubject = new ReplaySubject<Client>();
      const token = await this.getCurrentUserTwilioToken();
      this._client = new Client(token, {
        region: environment.twilioRegion,
      });
      this._client.on('stateChanged', (state) => {
        if (state === 'initialized') {
          this.clientSubject.next(this._client);
          this._client.on('messageAdded', (message) => {
            this.handleDataAfterAddedMessage(message)
          });
        } else {
          throw new Error('Could not initialize Twilio')
        }
      });
    }
    return this.clientSubject.asObservable().pipe(
      take(1)
    ).toPromise();
  }
  
  //get
  getCountConversations(): Observable<number> {
    return this.countConversations.asObservable();
  }

  getIsLoadedConversations(): Observable<boolean> {
    return this.isLoadedConversations.asObservable();
  }

  getIsOpenModal(): Observable<boolean> {
    return this.isOpenModal.asObservable();
  }

  //handle
  hasUnreadMessages(conversation: Conversation) {
    return (conversation.lastMessage?.index ?? 0) != (conversation.lastReadMessageIndex ?? 0)
  }

  handleDataAfterAddedMessage(message: Message){
    const strapiId = this.usersService.me()?.strapi_user_id;
    const authorId = message?.author?.replace('uid:', '');
    if(strapiId !== '' && authorId !== '' && strapiId !== authorId){
      this.conversationsReloader.pipe(
        switchMap(() => from(this._client.getSubscribedConversations())),
        take(1)
      ).subscribe(res => {
        if(res?.items?.length > 0){
          let activeConveration;
          if(this.isOpenModal.getValue()){
            activeConveration = this.activeConveration.getValue();
          }
          this.updateCountConversations(res?.items?.filter(conversation => activeConveration?.sid !== conversation.sid && this.hasUnreadMessages(conversation)).length);
        }
      })
    }
  }

  updateCountConversations(count: number){
    this.countConversations.next(count);
    if(!this.isLoadedConversations.getValue()){
      this.isLoadedConversations.next(true);
    }
  } 
  
  reduceCountConversations(){
    const count = this.countConversations.getValue();
    if(count > 0){
      this.countConversations.next(count - 1);
    }
  }

  updateIsOpenModal(action: boolean, conversation: Conversation){
    this.isOpenModal.next(action);
    this.activeConveration.next(conversation)
    if(this.hasUnreadMessages(conversation)){
      this.reduceCountConversations()
    }
  } 
}
