import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DialogService } from '../../services/dialog.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private dialogService: DialogService) {

    }
    private dataHandler(event: any) {
        if (event instanceof HttpResponse) {
            if (event.body && event.body.status === 'ERROR') {
                console.error('Firebase error : ', event.body);
            }
        }

    }

    private errorHandler(error: any) {
        if (error instanceof HttpErrorResponse) {

            switch (error.status) {
                case 404:
                    break;
                case 401:
                    // console.warn('User logged out!');
                    // this.oAuthService.logOut();
                    // this.router.navigate(['/login']);

                    break;

                case 500:
                    console.error('Server fejl: ', event);
                    // this.messageService.addErrors(event.toString());
                    break;
                case 503:
                    console.error('Server ikke tilgængelig: ', event, error);
                    // this.messageService.addErrors(event.toString());
                    break;
                default:
                    console.error('unknown status : ', event);
                    this.dialogService.alert(error);
                    break;
            }
        }

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({});

        return next.handle(request).pipe(
            tap(
                data => this.dataHandler(event),
                error => this.errorHandler(error)
            )
        );
    }
}
