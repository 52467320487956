<h4 class="uppercase font-semibold mb-2.5" *ngIf="strapiAttributes.title">{{strapiAttributes.title}}</h4>
<h4 class="font-semibold mb-0" *ngIf="plan">{{(plan.amount/100)|currency:plan?.currency}}/{{getRecurringLabel(plan?.interval)}}</h4>

<div *ngIf="label">
  <app-chip class="mt-[25px] inline-block">{{label}}</app-chip>
</div>
<div class="overflow-y-auto mt-[19px]">
  <div class="text-sm pr-2 product-description" *ngIf="description" [innerHTML]="description"></div>
</div>
<div class="flex w-full justify-center flex-grow mt-10" [ngClass]="activePlanId ? 'items-center' : 'items-end'">
  <ng-container *ngIf="activePlanId; else renderButtonTemplate" class="py-3">
    <ng-container *ngIf="subscription?.['cancel_at_period_end']; else renderCancelTemplate">
      <div class="flex flex-col justify-center gap-2">
        <p class="text-xs">
          Din plan, der er sat på pause, <br/>annulleres i slutningen af din nuværende faktureringsperiode
          {{subscription?.['cancel_at']*1000|dateFormatPipe}}
        </p>
        <button class="btn-green outline" (click)="handleRedirectBilling('resume')" [disabled]="isInvited">Genregistrer</button>
      </div>
    </ng-container>
    <ng-template #renderCancelTemplate>
      <button class="btn-green outline" (click)="handleRedirectBilling('cancel')" [disabled]="isInvited">Afmeld abonnement</button>
    </ng-template>
  </ng-container>
  <ng-template #renderButtonTemplate>
    <button class="outline" (click)="btnClick()" [disabled]="isInvited">Vælg abonnement</button>
  </ng-template>
</div>
