/**
 * Created by ModelParser
 * Date: 22-07-2020.
 * Time: 06:55.
 */
import { VideoMeetingDefinition, VideoMeetingDefinitionInterface } from './definitions/VideoMeetingDefinition';
import Helpers from '../helpers';

export interface VideoMeetingInterface extends VideoMeetingDefinitionInterface {

}

export class VideoMeeting extends VideoMeetingDefinition implements VideoMeetingInterface {

    constructor(json?: any) {
        super(json);
    }
    public static createDefault() {
        const item = new VideoMeeting();
        item.id = 0;
        item.date = Helpers.serverDate(new Date());
        return item;
    }

}
