<h1 class="text-2xl mb-4 text-bold">Velkommen til!</h1>
<p style="max-width: 650px">
  Du har fri adgang til både netværk, grupper og tema grupper med vores dygtige
  fagpersoner. <br /><br />

  De bedste hilsener<br /><br />

  Cecilly og Adam<br />
  Famoba.dk
</p>
<div class="w-full flex justify-center mt-8">
  <button (click)="close()">GÅ TIL FAMOBA</button>
</div>
