/**
 * Created by ModelParser
 * Date: 11-06-2020.
 * Time: 12:26.
 */
import {
  NotificationChannelDefinition, NotificationChannelDefinitionInterface,
} from './definitions/NotificationChannelDefinition';

export interface NotificationChannelInterface extends NotificationChannelDefinitionInterface {

}

export class NotificationChannel extends NotificationChannelDefinition implements NotificationChannelInterface {

    constructor(json?: any) {
        super(json);
    }

}
