import { Component, EventEmitter, HostBinding, Input, OnInit, Output, TrackByFunction } from '@angular/core';
import { ChipStyle } from '../../atoms/chip/chip.component';

export type ChipSelectValue = string|number|boolean;
export type ChipSelectOptions = {[key: string]: ChipSelectValue}

@Component({
  selector: 'app-chip-select',
  templateUrl: './chip-select.component.html',
  styleUrls: ['./chip-select.component.scss']
})
export class ChipSelectComponent implements OnInit {

  @HostBinding() class;

  @Input() options: ChipSelectOptions = {};
  @Input() value: ChipSelectValue[]|ChipSelectValue;
  @Input() wrap = false;
  @Input() multi = false;
  @Input() style: ChipStyle = 'cyan';
  @Input() selectedStyle: ChipStyle
  @Input() size: 'large'|'medium'|'small' = 'small'
  @Output() change: EventEmitter<ChipSelectValue[] | ChipSelectValue> = new EventEmitter<ChipSelectValue[] | ChipSelectValue>();


  get trackBy(): TrackByFunction<any> {
    return (index, item) => {
      return item.key;
    }
  }

  get keyValueOptions() {
    const keys = Object.keys(this.options ?? {});
    return keys.map(key => ({key, value: this.options[key]}));
  }

  constructor() { }

  ngOnInit(): void {
    this.class = 'flex -m-1' + (this.wrap ? ' flex-wrap' : '')
  }

  get isArray() {
    return Array.isArray(this.value)
  }

  unsetKey(key: string) {
    if (this.isArray) {
      (this.value as any[]).splice(this.indexOf(key), 1);
    } else {
      this.value = undefined;
    }
  }

  indexOf(key: string) {
    if (!this.isArray) {
      return -1;
    }
    return (this.value as any[]).findIndex(d => d == key);
  }

  addKey(key: string) {
    if (this.multi) {
      if (!this.isArray) {
        this.value = [];
      }
      (this.value as any[]).push(key);
    } else {
      this.value = this.isArray ? [key] : key;
    }
  }

  changeValue(key: string) {
    if (this.isSelected(key)) {
      this.unsetKey(key);
    } else {
      this.addKey(key);
    }
    this.change.emit(this.value);
  }

  isSelected(key: string) {
    if (Array.isArray(this.value)) {
      return this.indexOf(key) !== -1;
    } else {
      return this.value === key;
    }
  }

}
