import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../../core/models';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatchV2} from "../../../core/models/definitions/MatchDefinition";
import {Observable} from "rxjs";
import {UsersService} from '../../../services/users.service';
import {ApiService} from '../../../services/api.service';
import {filter, map, switchMap, tap} from "rxjs/operators";
import {StoredCacheObservable} from "../../../core/stored-cache-observable";

export interface ProfileDialogDataButton {
  title: string
  disabled?: boolean;
  className?: string;
  click?: (() => any | Promise<any>) | undefined;
}

export interface ProfileDialogData {
  user: Observable<User>,
  title: string,
  buttons: Observable<ProfileDialogDataButton[]>
}

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent implements OnInit {

  loading = true;
  buttonClicked = false;
  match: MatchV2;
  isShowDropdown: boolean = false;
  me: User;
  loadingUpdate: boolean = false;
  isShowButton: boolean = false;

  buttons$: Observable<{ user: User, buttons: ProfileDialogDataButton[] }>;

  constructor(
    private usersService: UsersService,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: ProfileDialogData,
    public dialogRef: MatDialogRef<ProfileDialogComponent>,
  ) {
    this.me = this.usersService.me();
  }

  protected getButtonsObservable(button?: ProfileDialogDataButton): Observable<{ user: User, buttons: ProfileDialogDataButton[] }> {
    return this.data.buttons.pipe(
      switchMap(buttons => {
        return this.data.user.pipe(
          map(user => ({user, buttons}))
        )
      }),
      filter(res => {
        if(button !== undefined && res.buttons !== undefined){
            const findInArrayNotUpdate = res.buttons.find(element => element.title === button.title);
            if(findInArrayNotUpdate){
              return false;
            }
        }
        return res.buttons !== undefined && res.user !== undefined;
      }),
      tap(d => {
        this.loading = false;
      })
    ) as any
  }

  ngOnInit(): void {
    this.buttons$ = this.getButtonsObservable();
    this.data.buttons.subscribe(b => {
      if (b && b.length === 0) {
        this.data.user.subscribe(u => {
          if (u && this.me) {
            if (u.strapi_user_id !== this.me.strapi_user_id) {
              this.isShowDropdown = true
            }
          }
        })
      }
    })
  }

  setIsShowButton() {
    this.isShowButton = true;
  }

  async buttonClick(button: ProfileDialogDataButton) {
    if (!button.click) {
      return;
    }
    this.buttonClicked = true;
    // comment code button.disabled, i want disable all button after submit
    // button.disabled = this.buttonClicked;
    Promise.resolve(button.click()).finally(() => {
      this.buttons$ = this.getButtonsObservable(button);
      return this.buttonClicked = false;
    })
    // button.disabled = this.buttonClicked;
  }

  async deleteMatch(userReceive: User) {
    this.loadingUpdate = true;
    await this.apiService.api.users()
      .deleteMatch(userReceive?.id)
      .execute().subscribe(() => {
        StoredCacheObservable.clearCacheByKey(`checkIfRequestIsAlreadySend-${userReceive.strapi_user_id}`)
        this.loadingUpdate = false;
        this.dialogRef.close();
        this.usersService.reloadUserMatches();
      });
  }
}
