import {Injectable} from '@angular/core';
import {StrapiImage, StrapiUserGender} from "../core/models/definitions/UserDefinition";
import {User} from "../core/models";
import {ChildDefinitionAttributes} from "../core/models/definitions/ChildDefinition";
import {StrapiModel} from "../core/models/StrapiModel";
import algoliasearch from "algoliasearch";
import {environment} from "../../environments/environment";

export interface AlgoliaHit {
  objectID: string;
  _geoloc?: {lng: number, lat: number}
}
export interface AlgoliaHitMember extends AlgoliaHit {
  createdAt: number;
  first_name: string;
  last_name: string;
  birthdate: number;
  childrenBirthdates: number[];
  gender: StrapiUserGender;
  tagIds: number[];
  city: string;
  profile_image: {[format: string]: string}
}

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {

  protected client;

  userToFilter(user: User, index: 'groups'|'members') {
    const address = user.address
    const tagIds = user.tags.map(d => d.id)

    const objectID = user.strapi_user_id?.toString();
    const tagIdsFilter = tagIds.map(d => `tagIds:${d}`);
    const aroundLatLng = `${address.lat}, ${address.long}`;
    let facetFilters = [];
    if (index === 'members') {
      facetFilters = [`gender:${user.gender}`, `objectID:-${objectID}`];
    }
    if (index === 'groups') {
      facetFilters = [`genders:${user.gender}`];
    }
    return {optionalFilters: tagIdsFilter, facetFilters: facetFilters, aroundLatLng, }
  }

  getImageFormHit<T>(hit: T, hitKey: keyof T) {
    const profileImageKeys = Object.keys(hit?.[hitKey] ?? {});
    if (profileImageKeys.length === 0) {
      return undefined;
    }

    const imageFormatNames = Object.keys(hit[hitKey] ?? {})

    const imageFormats = imageFormatNames.reduce((previousValue, key) => {
      previousValue[key] = { url: hit[hitKey][key]};
      return previousValue;
    }, {});

    return {
      id: -1,
      attributes: {
        url: hit[hitKey][imageFormatNames[0]] ?? undefined, formats: imageFormats
      }
    } as StrapiImage;
  }

  hitToUser(hit: AlgoliaHitMember) {

    const children = hit.childrenBirthdates?.map(bithdate => {
      return {
        id: undefined,
        attributes: {
          birthdate: new Date(bithdate).toISOString(),
          gender: 'male',
          name: 'xxx'
        }
      } as StrapiModel<ChildDefinitionAttributes>
    })


    return new User({
      id: parseInt(hit.objectID),
      first_name: hit.first_name,
      last_name: hit.last_name,
      children: children,
      birthdate: hit.birthdate ? new Date(hit.birthdate).toISOString() : undefined,
      gender: hit.gender,
      address: {
        lat: hit._geoloc.lat,
        long: hit._geoloc.lng,
        city: hit.city,
        dawaId: 'xxx',
        zip: 0,
        street: 'xxx'
      },
      tags: hit.tagIds.map(d => ({id: d, attributes: {}})) as any,
      profile_image: this.getImageFormHit(hit, 'profile_image')
    });
  }

  get searchClient() {
    if (!this.client) {
      this.client = algoliasearch(
        environment.algolia.appId,
        environment.algolia.apiKey
      );
    }
    return this.client;
  }

}
