/**
 * Created by ModelParser
 * Date: 26-11-2019.
 * Time: 14:15.
 */
import { MessageDefinition, MessageDefinitionInterface } from './definitions/MessageDefinition';

export interface MessageInterface extends MessageDefinitionInterface {

}

export class Message extends MessageDefinition implements MessageInterface {

    constructor(json?: any) {
        super(json);
    }

}
