import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { Subject } from "rxjs";
import {UsersService} from "./users.service";
import {User} from "../core/models";
import {environment} from "../../environments/environment";

declare var Tawk_API: any;

@Injectable({
  providedIn: 'root'
})
export class TawkService {

  private loaded: boolean;
  private loadSubject: Subject<boolean> = new Subject<boolean>();
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private userService: UsersService,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  public initTawk(user: User) {
    if (!this.renderer) {
      this.renderer = this.rendererFactory.createRenderer(null, null);
    }
    this.load(user);
  }

  protected getTawkScript(src: string) {
    return `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='${src}';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();
      `
  }

  private load(user: User) {
    if (this.loaded || !user) {
      return;
    }
    const tawkURL = user?.active_subscription?.strapi_subscription?.tawkURL || environment.tawkURL
    if (!tawkURL) {
      return;
    }
    this.loaded = true;
    const s = this.renderer.createElement('script');
    s.type = 'text/javascript';
    s.text = this.getTawkScript(tawkURL);
    this.renderer.appendChild(this._document.body, s);
    Tawk_API.onLoad = this.loadedEvent.bind(this);
  }

  private loadedEvent(){
    this.loaded = true;
    this.loadSubject.next(this.loaded);
  }
}
