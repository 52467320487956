import { BaseModel } from './BaseModel';

export class BaseStoredModel extends BaseModel {

    id?: number;
    type?: string;

    public exists(): boolean {
        return this.id && this.id != 0;
    }

}
