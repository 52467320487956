/**
 * Created by ModelParser
 * Date: 13-09-2019.
 * Time: 11:05.
 */
import { InterestDefinition } from './definitions/InterestDefinition';

export class Interest extends InterestDefinition {

    constructor(json?: any) {
        super(json);
    }

    static createDefault(): Interest {
        const interest: Interest = new Interest();
        interest.id = 0;
        return interest;
    }

}
