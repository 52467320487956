/**
 * Created by ModelParser
 * Date: 09-06-2020.
 * Time: 08:36.
 */
import { ChannelMemberDefinition, ChannelMemberDefinitionInterface } from './definitions/ChannelMemberDefinition';

export interface ChannelMemberInterface extends ChannelMemberDefinitionInterface {

}

export class ChannelMember extends ChannelMemberDefinition implements ChannelMemberInterface {

    constructor(json?: any) {
        super(json);
    }

}
