import { Component, OnInit } from '@angular/core';
import {StoredCacheObservable} from "../../../core/stored-cache-observable";
import {FamobaProduct} from "../../../core/models/definitions/SubscriptionDefinition";
import {UsersService} from "../../../services/users.service";
import {AuthenticationService} from "../../../services/authentication.service";
import {ApiService} from "../../../services/api.service";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {User} from "../../../core/models";

@Component({
  selector: 'app-subscription-container',
  templateUrl: './subscription-container.component.html',
  styleUrls: ['./subscription-container.component.scss']
})
export class SubscriptionContainerComponent implements OnInit {

  subscriptions$: StoredCacheObservable<FamobaProduct[]>;
  user$: Observable<User>;

  constructor(
    protected usersService: UsersService,
    protected authenticationService: AuthenticationService,
    protected apiService: ApiService
  ) {
    this.subscriptions$ = new StoredCacheObservable({
      localStorageKey: 'subscription-plans',
      observable: this.apiService
        .api
        .subscriptions()
        .get()
        .execute()
        .pipe(
          map(d => d[0]),
          map(d => d?.sort(this.sortPlans()))
        )
    }) as any;
    this.user$ = this.usersService.meAsync();
  }

  ngOnInit(): void {
  }

  sortPlans() {
    return (a: FamobaProduct, b: FamobaProduct) => {
      return b.strapi.attributes.order - a.strapi.attributes.order;
    }
  }

}
