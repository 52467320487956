/**
 * Created by ModelParser
 */

import { Attendee, AttendeeInterface } from '../Attendee';
import { BaseStoredModel } from '../BaseStoredModel';
import { ChannelMember, ChannelMemberInterface } from '../ChannelMember';
import { Child } from '../Child';
import { File, FileInterface } from '../File';
import { Group } from '../Group';
import { GroupMemberInterface, GroupMemberOld } from '../GroupMemberOld';
import { GroupStar, GroupStarInterface } from '../GroupStar';
import { Interest } from '../Interest';
import { Match, MatchInterface } from '../Match';
import { Message, MessageInterface } from '../Message';
import {
  MessageRecipient,
  MessageRecipientInterface,
} from '../MessageRecipient';
import {
  NotificationSubscription,
  NotificationSubscriptionInterface,
} from '../NotificationSubscription';
import { PhoneNumber, PhoneNumberInterface } from '../PhoneNumber';
import { Preference, PreferenceInterface } from '../Preference';
import { Searchprofile, SearchprofileInterface } from '../Searchprofile';
import { StrapiModel } from '../StrapiModel';
import { SubscriptionInterface } from '../Subscription';
import { User, UserInterface } from '../User';
import { UserStar, UserStarInterface } from '../UserStar';
import { AddressDefinitionInterface } from './AddressDefinition';
import { ChildDefinitionAttributes } from './ChildDefinition';
import { InterestAttributes } from './InterestDefinition';
import { StripePlan } from './SubscriptionDefinition';

export type ActiveSubscriptionSource = 'self' | 'invited';
export type ActiveSubscriptionFeature = 'friends' | 'groups';

export interface ActiveSubscription {
  source: ActiveSubscriptionSource;
  strapi_subscription: { [key: string]: any };
  stripe_subscription: { [key: string]: any };
}

export interface StripeListResult<T> {
  data: T[];
  hasMore: boolean;
  total_count: number;
  url: string;
}

export interface StripeSubscription {
  id: string;
  plan: StripePlan;
}

export interface StripeCustomer {
  id: string;
  subscriptions?: StripeListResult<StripeSubscription>;
}

export type StrapiImage = StrapiModel<StrapiImageAttributes>;

export interface StrapiImageAttributes {
  url?: string;
  formats?: { [key: string]: { url: string } };
}

export type StrapiUser = StrapiModel<StrapiUserAttributes>;

export type StrapiUserGender = 'mom' | 'dad' | 'parent';

export interface StrapiUserAttributes {
  first_name?: string;
  last_name?: string;
  birthdate?: string;
  description?: string;
  email?: string;
  gender?: StrapiUserGender;
  whitelisted?: boolean;
  profile_image: StrapiImage;
  newsletter?: boolean;
}

export interface UserDefinitionInterface extends StrapiUserAttributes {
  stripe_customer?: StripeCustomer;
  user_type_id?: number;
  phone_number_id?: number;
  phone_number?: PhoneNumberInterface;
  address_id?: number;
  address?: AddressDefinitionInterface;
  renew_password?: boolean;
  scope?: string;
  image_id?: number;
  image?: FileInterface;
  searchprofile?: SearchprofileInterface;
  instagram?: string;
  current_subscription_id?: number;
  attendees?: AttendeeInterface[];
  interests?: StrapiModel<InterestAttributes>[];
  group_members?: GroupMemberInterface[];
  subscriptions?: SubscriptionInterface[];
  preferences?: PreferenceInterface[];
  match_a?: MatchInterface[];
  match_b?: MatchInterface[];
  message_senders?: MessageInterface[];
  channel_members?: ChannelMemberInterface[];
  message_recipients?: MessageRecipientInterface[];
  notification_subscriptions?: NotificationSubscriptionInterface[];
  user_stars?: UserStarInterface[];
  group_stars?: GroupStarInterface[];
  children?: StrapiModel<ChildDefinitionAttributes>[];
  active_child?: StrapiModel<ChildDefinitionAttributes>;
  id?: number;
  created?: string;
  updated?: string;
  created_by_id?: number;
  created_by?: UserInterface;
  updated_by_id?: number;
  updated_by?: UserInterface;
  tags?: Interest[];
  strapi_user_id?: string;
  groups?: Group[];
  active_subscription?: ActiveSubscription;
  verified?: boolean;
}

export class UserDefinition
  extends BaseStoredModel
  implements UserDefinitionInterface
{
  user_type_id?: number;
  phone_number_id?: number;
  phone_number?: PhoneNumberInterface;
  address_id?: number;
  address?: AddressDefinitionInterface;
  first_name?: string;
  last_name?: string;
  newsletter?: boolean;
  gender?: 'mom' | 'dad' | 'parent';
  birthdate?: string;
  description?: string;
  renew_password?: boolean;
  scope?: string;
  image_id?: number;
  image?: FileInterface;
  searchprofile?: Searchprofile;
  instagram?: string;
  current_subscription_id?: number;
  current_subscription?: StripeSubscription;
  attendees?: Attendee[];
  interests?: Interest[];
  group_members?: GroupMemberOld[];
  preferences?: Preference[];
  match_a?: Match[];
  match_b?: Match[];
  message_senders?: Message[];
  channel_members?: ChannelMember[];
  message_recipients?: MessageRecipient[];
  notification_subscriptions?: NotificationSubscription[];
  user_stars?: UserStar[];
  group_stars?: GroupStar[];
  children?: Child[];
  active_child?: Child;
  id?: number;
  created?: string;
  updated?: string;
  created_by_id?: number;
  created_by?: User;
  updated_by_id?: number;
  updated_by?: User;
  tags?: Interest[];
  profile_image: StrapiImage;
  email?: string;
  whitelisted?: boolean;
  strapi_user_id?: string;
  groups?: Group[];
  stripe_customer?: StripeCustomer;
  active_subscription?: ActiveSubscription;
  verified?: boolean;

  constructor(data?: any) {
    super();
    this.populate(data);
  }

  protected dataFields(): (keyof this)[] {
    return [
      'user_type_id',
      'phone_number_id',
      'phone_number',
      'address_id',
      'address',
      'first_name',
      'last_name',
      'gender',
      'description',
      'renew_password',
      'birthdate',
      'scope',
      'image_id',
      'image',
      'searchprofile',
      'instagram',
      'current_subscription_id',
      'current_subscription',
      'attendees',
      'interests',
      'group_members',
      'preferences',
      'match_a',
      'match_b',
      'message_senders',
      'channel_members',
      'message_recipients',
      'notification_subscriptions',
      'user_stars',
      'group_stars',
      'children',
      'active_child',
      'tags',
      'id',
      'created',
      'created_by_id',
      'created_by',
      'updated_by_id',
      'updated_by',
      'profile_image',
      'email',
      'whitelisted',
      'strapi_user_id',
      'groups',
      'stripe_customer',
      'active_subscription',
      'verified',
      'newsletter',
    ];
  }

  public populate(data?: any, patch: boolean = false) {
    super.populate(data, patch);
    if (!data) return;
    if (data.phone_number != null) {
      this.phone_number = new PhoneNumber(data.phone_number);
    }
    if (data.image != null) {
      this.image = new File(data.image);
    }
    if (data.searchprofile != null) {
      this.searchprofile = new Searchprofile(data.searchprofile);
    }
    if (data.attendees != null) {
      this.attendees = data.attendees.map((i: any) => new Attendee(i));
    }
    if (data.interests != null) {
      this.interests = data.interests.map((i: any) => new Interest(i));
    }
    if (data.group_members != null) {
      this.group_members = data.group_members.map(
        (i: any) => new GroupMemberOld(i)
      );
    }
    if (data.preferences != null) {
      this.preferences = data.preferences.map((i: any) => new Preference(i));
    }
    if (data.match_a != null) {
      this.match_a = data.match_a.map((i: any) => new Match(i));
    }
    if (data.match_b != null) {
      this.match_b = data.match_b.map((i: any) => new Match(i));
    }
    if (data.message_senders != null) {
      this.message_senders = data.message_senders.map(
        (i: any) => new Message(i)
      );
    }
    if (data.channel_members != null) {
      this.channel_members = data.channel_members.map(
        (i: any) => new ChannelMember(i)
      );
    }
    if (data.message_recipients != null) {
      this.message_recipients = data.message_recipients.map(
        (i: any) => new MessageRecipient(i)
      );
    }
    if (data.notification_subscriptions != null) {
      this.notification_subscriptions = data.notification_subscriptions.map(
        (i: any) => new NotificationSubscription(i)
      );
    }
    if (data.user_stars != null) {
      this.user_stars = data.user_stars.map((i: any) => new UserStar(i));
    }
    if (data.group_stars != null) {
      this.group_stars = data.group_stars.map((i: any) => new GroupStar(i));
    }

    let activeChild: Child;
    if (data.children != null && Array.isArray(data.children)) {
      this.children = data.children?.map((i: any) => new Child(i));
      activeChild = this.children.find(
        (child) => child.attributes.active_child === true
      );
    }

    if (this.children) {
      if (activeChild !== null) {
        this.active_child = activeChild;
      }
    }

    if (data.created_by != null) {
      this.created_by = new User(data.created_by);
    }
    if (data.updated_by != null) {
      this.updated_by = new User(data.updated_by);
    }
  }
}
