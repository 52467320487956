import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { StrapiService } from '../../../services/strapi.service';
import { UserInterface } from "../../../core/models/User";

@Component({
  selector: 'app-user-profile-icon',
  templateUrl: './user-profile-icon.component.html',
  styleUrls: ['./user-profile-icon.component.scss']
})
export class UserProfileIconComponent implements OnInit {

  @HostBinding('class') class;

  @Input() user: UserInterface;
  
  constructor(
    private strapiService: StrapiService
  ) { }

  ngOnInit(): void {
  }

  get imgURL() {
    return this.strapiService.getStrapiImageSrc(this.user?.profile_image, 'small');
  }

  get icon() {
    if (!this.user?.gender || this.user?.gender == 'mom') {
      return 'profile-female';
    } else {
      return 'profile-male';
    }
  }

}
