import { Component, Input } from '@angular/core';
import { Interest } from '../../../core/models';
import { GroupAttributes } from '../../../core/models/definitions/GroupDefinition';
import { StrapiModel } from '../../../core/models/StrapiModel';

@Component({
  selector: 'app-image-link',
  templateUrl: './image-link.component.html',
  styleUrls: ['./image-link.component.scss']
})
export class ImageLinkComponent {
  @Input() componentName: string;
  @Input() group: StrapiModel<GroupAttributes>;
  @Input() tagsMatched: Interest[];
  @Input() tagsNoMatched: Interest[];
  
  @Input() backgroundImageURL: string;
  @Input() disabledText: string;
  @Input() label: string;
  @Input() href: string;
  isShowMatched:boolean = false
  
  toggleShowMatched(){
    if(this.isShowMatched){
      this.isShowMatched = false;
    }else{
      this.isShowMatched = true;
    }
  }

}
