import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { skipWhile, tap } from 'rxjs/operators';
import { User } from '../../core/models';
import { UsersService } from '../../services/users.service';
import Constants from 'src/app/core/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup-page',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
})
export class SignupPageComponent implements OnInit {

  user: User;
  me$: Observable<User>

  constructor(
    private userService: UsersService,
    private router: Router
  ) {
    this.me$ = this.userService.meAsync().pipe(
      skipWhile(d => !d),
      tap(user => {
        if (this.userService.isUserSignedUp(user)) {
          this.router.navigateByUrl('/explore');
        }
      })
    );
  }

  isUserComplete(user) {
    return this.userService.isUserSignedUp(user)
  }

  getPage(user: User): 'verify' | 'city' | 'name' {
    if (this.hasUserMinimumData(user) && !this.emailVerified) {
      return 'verify';
    }
    const userHasName = this.userHasName(user);
    return userHasName ? 'city' : 'name';
  }

  hasUserMinimumData(user: User) {
    return this.userService.hasUserMinimumData(user);
  }

  get emailVerified() {
    return this.userService.isEmailVerified;
  }

  userHasName(user: User) {
    return user?.last_name?.length > 0 && user?.first_name?.length > 0 && user?.email?.length > 0;
  }


  ngOnInit() {
  }

  get Constants() {
    return Constants;
  }

  get environment() {
    return environment;
  }
}
