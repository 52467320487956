<ng-container *ngIf="valueChange$|async"></ng-container>
<ng-container *ngIf="isAccessComponent">
  <button type="button" class="outline mb-4 md:mb-8" *ngIf="addChildPosition === 'top'" (click)="addChild()">Tilføj
    børn</button>
</ng-container>
<app-edit-child-form
  *ngFor="let group of groups; let i = index; trackBy: trackBy"
  [formGroup]="group.group"
  [child]="group.child"
  [showIcon]="showIcon"
  (childRemoved)="removeChild(i)"
  [isAccessComponent]="isAccessComponent"
  class="mb-2"
></app-edit-child-form>
<div class="flex justify-center" *ngIf="addChildPosition === 'bottom'">
  <div class="inline-block cursor-pointer" (click)="addChild()">
    <app-profile-icon icon="child" class="w-16 h-16"></app-profile-icon>
    <p class="underline text-center">Tilføj barn</p>
  </div>
</div>
