import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {tap} from "rxjs/operators";

const DEFAULT_TTL = 604800;

const getCacheItem = (config: StoredCacheObservableConfig<any>) => {
  let storageData = localStorage.getItem(config.localStorageKey) as any;
  if (!storageData || storageData === 'undefined') {
    return undefined;
  }
  storageData = JSON.parse(storageData);
  const expireTimespan = storageData?.expire;
  const now = new Date().getTime();
  if (!expireTimespan || expireTimespan < now) {
    localStorage.removeItem(config.localStorageKey);
    return undefined;
  }

  let existingData = undefined;
  if (storageData?.data) {
    if (config.readFromStorage) {
      existingData = config.readFromStorage(storageData.data);
    } else if (storageData === 'undefined') {
      existingData = undefined;
    } else {
      existingData = JSON.parse(storageData.data);
    }
  }

  return existingData;
}


export interface StoredCacheObservableConfig<T> {
  observable: Observable<any>
  localStorageKey: string;
  readFromStorage?: (data: string) => T;
  writeToStorage?: (data: T) => string;
  ttl?: number;
}

export class StoredCacheObservable<T> extends BehaviorSubject<T> {

  protected subscription: Subscription;
  protected config: StoredCacheObservableConfig<T>

  constructor(
    config: StoredCacheObservableConfig<T>
  ) {
    let existingData = getCacheItem(config) ?? undefined;
    super(existingData);
    this.config = config;
  }

  public next(data: T) {
    super.next(data);
    this.setItem(data);
  }

  protected getItem() {
  }

  protected setItem(data: any) {
    const parsedData = this.config.writeToStorage ? this.config.writeToStorage(data) : JSON.stringify(data);
    const ttl = this.config.ttl ?? DEFAULT_TTL;
    localStorage.setItem(this.config.localStorageKey, JSON.stringify({
      data: parsedData,
      expire: new Date().getTime() + (ttl * 1000)
    }));
  }

  subscribe(params) {
    if (!this.subscription) {
      this.subscription = this.config.observable.pipe(
        tap(data => {
          this.next(data)
        })
      ).subscribe();
    }
    return super.subscribe(params);
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    super.unsubscribe();
  }

  static clearCacheByKey(key: string) {
    localStorage.removeItem(key);
  }

}
