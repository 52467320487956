/**
 * Created by ModelParser
 * Date: 09-06-2020.
 * Time: 08:36.
 */
import { GroupStarDefinition, GroupStarDefinitionInterface } from './definitions/GroupStarDefinition';

export interface GroupStarInterface extends GroupStarDefinitionInterface {

}

export class GroupStar extends GroupStarDefinition implements GroupStarInterface {

    constructor(json?: any) {
        super(json);
    }

}
