import {Component, forwardRef, Inject, Input, OnInit, TrackByFunction} from '@angular/core';
import {AlgoliaHitMember, AlgoliaService} from "../../../../services/algolia.service";
import {UsersService} from "../../../../services/users.service";
import {ExploreComponent} from "../../explore.component";
import {Filter} from "../filter/filter.component";
import {GeneratedFilters, GenerateFilterFn} from '../extended-infinite-hits/extended-infinite-hits.component';
import {Interest, User} from '../../../../core/models';
import {InterestsService} from '../../../../services/interests.service';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

export interface HitUserMember {
  user: User
  tagsMatched: Interest[];
  tagsNoMatched: Interest[];
}

@Component({
  selector: 'app-explore-like-minded',
  templateUrl: './explore-like-minded.component.html',
  styleUrls: ['./explore-like-minded.component.scss'],
})
export class ExploreLikeMindedComponent implements OnInit {
  interests$: Observable<Interest[]>;
  interests: Interest[];
  idTopPosition: string = "";

  _filters;

  showDebug = localStorage.getItem('show-debug') === 'true';

  private hitCache: {[objectID: string]: HitUserMember} = {};


  @Input() user: User;
  constructor(
    protected algoliaService: AlgoliaService,
    protected usersService: UsersService,
    private interestService: InterestsService,
    @Inject(forwardRef(() => ExploreComponent))
    public exploreComponent: ExploreComponent
  ) {
    this.user = this.usersService.me();
    this.interests$ = this.interestService.getInterests().pipe(
      tap(d => this.interests = d)
    )
  }

  isHidden(hit: any) {
    return hit.hideInSearch !== false;
  }

  ngOnInit(): void {
  }

  trackFilterGroup(): TrackByFunction<any> {
    return (index, item) => {
      return JSON.stringify(item);
    }
  }

  getSortingIndex(): 'members_location'|'members' {
    if (!this.showDebug) {
      return 'members_location';
    }
    const item = localStorage.getItem('like-minded-sorting');
    if (!item) {
      return 'members_location'
    }
    return item === 'location' ? 'members_location' : 'members';
  }

  sortingChange(event) {
    const value = event?.target?.value;
    if (!value) {
      return;
    }
    localStorage.setItem('like-minded-sorting', value);
    location.reload();
  }

  protected joinFilters(parts: string[], condition: 'AND'|'OR'): string {
    return parts.filter(d => d.length > 0).map(d => `(${d})`).join(` ${condition} `);
  }

  get generateFilters(): GenerateFilterFn {
    if (this._filters) {
      return this._filters;
    }
    this._filters = (filter: Filter) => {
      if (!filter.birthdate || !filter.childrenBirthdates || !filter.user) {
        return [];
      }
      return [
        {
          config: {indexName: this.getSortingIndex(), searchClient: this.algoliaService.searchClient},
          searchParams: {
            ...this.algoliaService.userToFilter(filter.user, 'members'),
            filters: this.joinFilters([filter.birthdate.getCurrentFilter(), filter.childrenBirthdates.getCurrentFilter()], 'AND'),
            aroundPrecision: this.getFixedDistance('search-precision'),
            aroundRadius: this.getFixedDistance('search-distance'),
            getRankingInfo: true
          },
          title: 'Bedste match: afstand, interesser, forældres alder, barns alder'
        },
        {
          config: {indexName: this.getSortingIndex(), searchClient: this.algoliaService.searchClient},
          searchParams: {
            ...this.algoliaService.userToFilter(filter.user, 'members'),
            filters: this.joinFilters([filter.birthdate.getCurrentFilter(true), filter.childrenBirthdates.getCurrentFilter()], 'AND'),
            aroundPrecision: this.getFixedDistance('search-precision'),
            aroundRadius: this.getFixedDistance('search-distance'),
            getRankingInfo: true
          },
          title: 'Bedste match: afstand, interesser, barns alder'
        },
        {
          config: {indexName: this.getSortingIndex(), searchClient: this.algoliaService.searchClient},
          searchParams: {
            ...this.algoliaService.userToFilter(filter.user, 'members'),
            filters: this.joinFilters([filter.birthdate.getCurrentFilter(), filter.childrenBirthdates.getCurrentFilter(true)], 'AND'),
            aroundPrecision: this.getFixedDistance('search-precision'),
            aroundRadius: this.getFixedDistance('search-distance'),
            getRankingInfo: true
          },
          title: 'Bedste match: afstand, interesser, forældres alder'
        },
        {
          config: {indexName: this.getSortingIndex(), searchClient: this.algoliaService.searchClient},
          searchParams: {
            ...this.algoliaService.userToFilter(filter.user, 'members'),
            filters: this.joinFilters([filter.birthdate.getCurrentFilter(true), filter.childrenBirthdates.getCurrentFilter(true)], 'AND'),
            aroundPrecision: this.getFixedDistance('search-precision'),
            aroundRadius: this.getFixedDistance('search-distance'),
            getRankingInfo: true
          },
          title: 'Bedste match: afstand, interesser'
        }
      ] as GeneratedFilters[]
    }
    return this._filters;
  }

  tagIdsToTags(tags: Interest[], ids): Interest[] {
    ids = ids.map(d => parseInt(d));
    return tags.filter(d => ids.indexOf(d.id) >= 0)
  }

  hitToMember(hit: any): HitUserMember {
    const selfUser = this.user;
    const key = JSON.stringify({selfTags: selfUser.tags.map(d => d.id), hitObjectId: hit.objectID})
    if (this.hitCache[key]) {
      return this.hitCache[key]
    }
    const tagsMatched =  this.tagIdsToTags(selfUser.tags, hit.tagIds);
    let tagsNoMatched =  this.tagIdsToTags(this.interests, hit.tagIds);
    if(tagsMatched.length > 0){
      tagsMatched.forEach(element => {
        tagsNoMatched = tagsNoMatched.filter(function(item) {
            return item.id != element.id;
        })
      });
    }
    this.hitCache[key] = {
      user: this.algoliaService.hitToUser(hit),
      tagsMatched,
      tagsNoMatched
    };
    return this.hitCache[key];
  }

  openProfile(hit: AlgoliaHitMember) {
    this.usersService.openDefaultProfileDialog({userId: parseInt(hit.objectID)})
  }

  trackBy(): TrackByFunction<any> {
    return (index, item) => {
      return item.objectID;
    }
  }
  getFixedDistance(key: 'search-precision'|'search-distance') {
    let distance = parseInt(localStorage.getItem(key));

    if (key === 'search-precision' && !this.showDebug) {
      distance = undefined;
    }

    if (distance >= 100000 && key === 'search-distance') {
      return undefined;
    }
    if (distance) {
      if (distance >= 100000) {
      return undefined;
    }
    return distance ;
    }
    if (key === 'search-distance') {
      return 60000;
    }
    if (key === 'search-precision') {
      return 2000;
    }
    return undefined;
  }

  distanceChange(event, key) {
    const distance = parseInt(event.target.value);
    const value = distance > 0 ? distance : undefined;
    localStorage.setItem(key, value as any);
    location.reload();
  }

  changePositionIndex(event, data){
    this.idTopPosition = event.type === 'mouseenter' || event.type === 'touchstart' ? data?.objectID : "";
  }
}
