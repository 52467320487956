/**
 * Created by ModelParser
 */
import { User, UserInterface } from '../User';
import { Plan, PlanInterface } from '../Plan';
import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';

export interface StrapiPaymentLink {
  id: number;
  priceId: string;
  paymentLink: string;
}

export interface StrapiSubscriptionAttributes {
  stripeId: string;
  description: string;
  focused?: boolean
  focusedLabel?: string;
  order: number;
  title: string;
  paymentLinks: StrapiPaymentLink[];
}

export type StrapiSubscription = StrapiModel<StrapiSubscriptionAttributes>;

export type StripeRecurringInterval = 'month';

export interface StripeRecurring {
  interval: StripeRecurringInterval;
  trial_period_days: 14;
}

export interface StripePrice {
  id: string;
  active: boolean;
  billing_scheme;
  currency: string;
  product: string;
  recurring?: StripeRecurring;
  type: 'recurring';
  unit_amount: number;
  unit_amount_decimal: string;
}

export interface StripePlan {
  id: string;
  active: boolean;
  amount: number;
  amount_decimal: string;
  currency: string;
  interval: StripeRecurringInterval;
  interval_count: number;
  product: string;
}

export interface FamobaProduct {
  strapi: StrapiSubscription;
  plan: StripePlan;
}

export interface SubscriptionDefinitionInterface {
    user_id?: number;
    user?: UserInterface;
    plan_id?: number;
    plan?: PlanInterface;
    user_current_subscription?: UserInterface;
    active?: boolean;
    start?: string;
    end?: string;
    payment_link?: string;
    last_payment?: string;
    amount?: number;
    quick_pay_subscription_id?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class SubscriptionDefinition extends BaseStoredModel implements SubscriptionDefinitionInterface {
    user_id?: number;
    user?: User;
    plan_id?: number;
    plan?: Plan;
    user_current_subscription?: User;
    active?: boolean;
    start?: string;
    end?: string;
    payment_link?: string;
    amount?: number;
    quick_pay_subscription_id?: string;
    last_payment?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.user_id;
            delete this.user;
            delete this.plan_id;
            delete this.plan;
            delete this.user_current_subscription;
            delete this.active;
            delete this.start;
            delete this.end;
            delete this.payment_link;
            delete this.amount;
            delete this.quick_pay_subscription_id;
            delete this.last_payment;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.plan_id != null) {
            this.plan_id = data.plan_id;
        }
        if (data.plan != null) {
            this.plan = new Plan(data.plan);
        }
        if (data.user_current_subscription != null) {
            this.user_current_subscription = new User(data.user_current_subscription);
        }
        if (data.active != null) {
            this.active = data.active;
        }
        if (data.start != null) {
            this.start = data.start;
        }
        if (data.end != null) {
            this.end = data.end;
        }
        if (data.payment_link != null) {
            this.payment_link = data.payment_link;
        }
        if (data.amount != null) {
            this.amount = data.amount;
        }
        if (data.quick_pay_subscription_id != null) {
            this.quick_pay_subscription_id = data.quick_pay_subscription_id;
        }
        if (data.last_payment != null) {
            this.last_payment = data.last_payment;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
