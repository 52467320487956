<form
  class="sign-up-form mb-12"
  [formGroup]="formGroup"
  (keydown.enter)="$event.preventDefault()"
  (ngSubmit)="saveValues()"
>
  <h2 class="w-full text-center">Velkommen</h2>
  <p class="text-black-50 text-center d-block w-full pb-2">
    Vi skal bruge lidt oplysninger om dig
  </p>

  <mat-form-field appearance="outline" class="w-full">
    <input
      matInput
      placeholder="E-mail"
      type="email"
      name="username"
      formControlName="email"
      autocomplete="false"
      [readonly]="user?.email?.length > 0"
      required
    />
  </mat-form-field>

  <div class="flex flex-col">
    <mat-form-field appearance="outline" class="flex-grow mr-1">
      <input
        matInput
        placeholder="Fornavn"
        formControlName="first_name"
        autocomplete="false"
        name="first_name"
        required
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="flex-grow">
      <input
        matInput
        placeholder="Efternavn"
        formControlName="last_name"
        autocomplete="false"
        name="last_name"
        required
      />
    </mat-form-field>
  </div>

  <mat-checkbox
    #checkboxNews
    formControlName="newsletter"
    name="newsletter"
    class="text-base italic mat-checkbox-multiline"
    color="primary"
  >
    Jeg accepterer at modtage nyheder og notifikationer
  </mat-checkbox>

  <mat-checkbox
    #checkboxPolicy
    class="text-base italic mat-checkbox-multiline"
    color="primary"
  >
    Jeg accepterer
    <a
      style="text-decoration: underline"
      target="_blank"
      href="https://famoba.dk/handelsbetingelser"
      >brugervilkår og privatlivspolitik,</a
    >
    herunder brug af cookies.
  </mat-checkbox>
  <button
    [disabled]="!checkboxPolicy?.checked || !formGroup.valid"
    type="submit"
    class="w-full mt-4"
  >
    Opret profil
  </button>
</form>
