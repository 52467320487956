import { Component } from '@angular/core';
import { UsersService } from '../../services/users.service';
import {User} from "../../core/models";
import {Observable} from "rxjs";
import {ApiService} from "../../services/api.service";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-my-page',
  templateUrl: './my-page.component.html',
  styleUrls: ['./my-page.component.scss']
})
export class MyPageComponent {

  matches$: Observable<User[]>
  matches: User[] = [];

  constructor(
    private userService: UsersService,
    protected apiService: ApiService
  ) {
    this.matches$ = this.userService.getUserMatches().pipe(
      tap((d: any) => this.matches = d)
    );
  }

  get me() {
    return this.userService.me();
  }

  get activeSubscription() {
    return this.userService.hasUserFeature(this.me, 'friends');
  }
  openDialogFriendRequests() {
    return this.userService.openDialogFriendRequests();
  }


}
