import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divider',
  template: '',
  styleUrls: [],
  host: {
    class: 'h-0.5 w-full bg-lgray'
  }
})
export class DividerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
