/**
 * Created by ModelParser
 */
import { Group } from '../Group';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';
import { GroupAttributes } from './GroupDefinition';

export interface GroupMemberDefinitionInterface {
    group_id?: number;
    group?: StrapiModel<GroupAttributes>;
    user_id?: number;
    user?: UserInterface;
    status?: string;
    type?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class GroupMemberDefinition extends BaseStoredModel implements GroupMemberDefinitionInterface {
    group_id?: number;
    group?: Group;
    user_id?: number;
    user?: User;
    status?: string;
    type?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.group_id;
            delete this.group;
            delete this.user_id;
            delete this.user;
            delete this.status;
            delete this.type;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.group_id != null) {
            this.group_id = data.group_id;
        }
        if (data.group != null) {
            this.group = data.group;
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.status != null) {
            this.status = data.status;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
