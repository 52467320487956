<div class="w-full flex flex-col">
  <div class="flex cursor-pointer w-full text-white border-solid border-white border-b-1 pb-1" (click)="active = !active">
    <span class="flex-grow text-xs font-bold">{{label}}</span>
    <span [class]="(active ? 'rotate-0' : 'rotate-45')">
      <app-icon class="w-3 h-3 fill-white" [icon]="'x'"></app-icon>
    </span>
  </div>
  <div class="flex flex-wrap py-2 -m-0.5" [class.hidden]="!active">
    <ng-content></ng-content>
  </div>
</div>
