<div class="flex justify-stretch flex-col !overflow-hidden relative h-full">
  <mat-icon mat-dialog-close="close" class="dialog-close-icon">close</mat-icon>
  <div class="hidden md:block absolute left-[-350px] bottom-[-160px] 2xl:bottom-[60px]">
    <img src="/assets/orange-logo-transparent.svg">
  </div>
  <div class=" mt-5 mx-auto">
    <h3 class="text-center">Få fuld adgang!</h3>
    <p class="text-center">Du har lige nu en gratis profil. Opgrader for at få fuld <br> adgang til ligesindede, grupper og meget mere.</p>
  </div>
  <div class="-mx-2 max-w-full flex flex-wrap grid-cols-2 overflow-y-auto z-10 pt-10" *ngIf="user$|async as user">
    <div class="px-2 my-4 w-full sm:w-6/12 lg:w-4/12 inline-block" *ngFor="let productConf of subscriptions$|async">
      <app-product-card class="bg-white" [product]="productConf" [adjustHeight]="true" [user]="user"></app-product-card>
    </div>
  </div>
</div>
