import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {User} from '../core/models';
import {ApiService} from './api.service';
import {AuthenticationService} from './authentication.service';
import {
  ActiveSubscriptionFeature,
  ActiveSubscriptionSource,
  StrapiUserGender
} from '../core/models/definitions/UserDefinition';
import {
  ProfileDialogComponent, ProfileDialogDataButton
} from "../shared/dialogs/profile-dialog/profile-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatchV2} from "../core/models/definitions/MatchDefinition";
import {MatDialogRef} from "@angular/material/dialog/dialog-ref";
import {StoredCacheObservable} from "../core/stored-cache-observable";
import {StrapiModel} from "../core/models/StrapiModel";
import {GroupAttributes} from "../core/models/definitions/GroupDefinition";
import {
  SubscriptionContainerComponent
} from '../components/organisms/subscription-container/subscription-container.component';
import {MatSnackBar} from "@angular/material/snack-bar";
import {NotificationService} from "./notification.service";
import {FriendRequestsDialogComponent} from "../shared/dialogs/friend-requests-dialog/friend-requests-dialog.component";
import {User as AuthUser} from "@auth0/auth0-spa-js/dist/typings/global";
import {Router} from "@angular/router";
import {FreeTrialPopupComponent} from "../shared/dialogs/free-trial-popup/free-trial-popup.component";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  reducerIsUpdateMatches: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  protected userSubject: BehaviorSubject<User>;
  protected cachedUserSubject: StoredCacheObservable<User>;

  protected failedRetrievingUser = false;

  protected hasShownFreeTrialPopup = false;


  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    protected snackbar: MatSnackBar,
    protected notificationService: NotificationService,
    protected router: Router
  ) {
  }

  public reloadUserMatches() {
    return this.reducerIsUpdateMatches.next(undefined);
  }

  public getUserMatches() {
    return this.reducerIsUpdateMatches.pipe(
      switchMap(() => {
        return new StoredCacheObservable({
          localStorageKey: 'user-matches-' + this.me().strapi_user_id,
          observable: this.apiService.api.users().searchFriendsAccepted().execute()
        })
      })
    )
  }


  protected getMeApi(auth): StoredCacheObservable<User> {
    return new StoredCacheObservable<User>({
      localStorageKey: 'me-' + auth.sub + '-' + auth.updated_at,
      observable: this.apiService.api.users().meGet().find().pipe(
        map(d => d[0]),
        catchError(d => {
          const httpMessage = d?.error?.message;
          if (httpMessage === 'Email already in use') {
            this.failedRetrievingUser = true;
            this.router.navigateByUrl('/email-already-used')
            return of({id: 0})
          }
          throw d;
        })
      )
    }) as any;
  }

  protected initUserBehavior() {
    if (this.userSubject) {
      return;
    }
    this.userSubject = new BehaviorSubject<User>(undefined);

    this.authenticationService.userAsync().pipe(
      switchMap(auth => {
        this.cachedUserSubject = this.getMeApi(auth)
        return this.cachedUserSubject;
      }),
      tap(user => {
        this.userSubject.next(user as any);
        this.updateVerifiedState(user, this.authenticationService.lastAuthUser);
      }),
    ).subscribe();
  }

  protected async updateVerifiedState(user: User, auth: AuthUser) {
    if (!user || !auth) {
      return;
    }
    if (!this.isUserSignedUp(user)) {
      return;
    }

    if (user.verified || !auth.email_verified) {
      return;
    }
    user.verified = true;
    const updatedUser = await this.saveUser(user);
    if (updatedUser?.stripe_customer?.subscriptions?.total_count > 0 && !this.hasShownFreeTrialPopup) {
      this.hasShownFreeTrialPopup = true;
      this.dialog.open(FreeTrialPopupComponent, {
        data: {
          user: updatedUser
        }
      });
    }
  }

  public async reloadUser() {
    const user = await this.apiService.api.users().meGet().find().pipe(
      map(d => d[0]),
      take(1)
    ).toPromise();
    this.cachedUserSubject.next(user);
  }

  /**
   * @deprecated
   */
  public get users(): User[] {
    return [];
  }

  /**
   * @deprecated use me()
   */
  public get user(): User {
    return this.me();
  }

  public me(): User {
    this.initUserBehavior();
    return this.userSubject.getValue();

  }

  public meAsync(): Observable<User> {
    this.initUserBehavior();
    return this.userSubject.asObservable();
  }

  isUserAddressComplete(user: User): boolean {
    return !!(user?.address?.city && user?.address?.zip);
  }

  isUserProfileImageComplete(user: User): boolean {
    return !!(user?.profile_image);
  }

  hasUserMinimumData(user: User): boolean {
    const hasEmail = user?.email?.length > 0;
    if (!hasEmail) {
      return false;
    }
    const hasName = user?.first_name?.length > 0 && user?.last_name?.length > 0;
    const hasChildren = user?.children?.filter(d => d.attributes?.birthdate && d.attributes?.name)?.length > 0;
    const hasAddress = this.isUserAddressComplete(user);
    const hasProfileImage = this.isUserProfileImageComplete(user);
    const hasGender = user?.gender?.length > 0;
    const hasInterests = user?.tags?.filter(d => d.attributes.label)?.length > 0;
    const hasBirthday = !!user.birthdate
    return hasName && hasChildren && hasAddress && hasGender && hasInterests && hasBirthday && hasProfileImage;
  }

  isUserSignedUp(user: User): boolean {
    return this.isEmailVerified && this.hasUserMinimumData(user);
  }

  get isEmailVerified() {
    return this.authenticationService.lastAuthUser.email_verified !== false;
  }

  async saveUser(user: User) {
    this.cachedUserSubject.next(user);
    await this.apiService.api.users().patchById(user.id).executePromise(user);
    await this.reloadUser();
    return this.me();
  }

  getGenderLabel(gender: StrapiUserGender): string {
    if (!gender) {
      return 'forældre';
    }
    switch (gender) {
      case 'mom':
        return 'mor';
      case 'dad':
        return 'far';
      case 'parent':
        return 'forældre'
    }
  }

  getUserActiveSubscriptionSource(user: User): ActiveSubscriptionSource {
    return user?.active_subscription?.source;
  }

  hasUserFeature(user: User, feature: ActiveSubscriptionFeature): boolean {
    const features = user?.active_subscription?.strapi_subscription?.features ?? [];
    return features.indexOf(feature) !== -1;
  }

  getButtonGroup(match: MatchV2 | undefined, self: User): 'pending' | 'waiting-response' | 'none' | undefined {
    if (!match?.id || match.state === 'declined') {
      return 'none';
    } else if (match.state === 'pending' && match.target_user_id === self.id) {
      return 'pending';
    } else if (match.state === 'pending' && match.sender_user_id === self.id) {
      return 'waiting-response'
    } else if (match.state === 'accepted') {
      return undefined;
    } else {
      return 'none';
    }
  }

  protected checkIfRequestIsAlreadySend(strapiUserId: number) {
    return new StoredCacheObservable({
      localStorageKey: `checkIfRequestIsAlreadySend-${strapiUserId}`,
      observable: this.apiService.api.matches().checkIfRequestIsAlreadySend().addQueryParameter({matchId: strapiUserId}).execute().pipe(
        map(d => d[0])
      )
    })
  }

  protected getPendingButtons(strapiUserId, obs) {
    return [
      {
        title: 'Afslå',
        className: 'outline btn-black',
        click: () => this.declineFriendRequest(strapiUserId).then(d => obs.next(d))
      },
      {
        title: 'Accepter',
        className: 'btn-green',
        click: () => this.acceptFriendRequest(strapiUserId).then(d => obs.next(d))
      }
    ]
  }

  protected getDefaultButtons(group, strapiUserId, obs) {
    return [
      {
        title: group === 'none' ? 'Send venneanmodning' : 'Afventer',
        disabled: group === 'waiting-response',
        click: this.hasUserFeature(this.me(), 'friends') ? group === 'waiting-response' ? undefined : () => this.sendFriendRequest(strapiUserId).then(d => {
          obs.next(d)
        }) : () => this.openSubscriptionContainer(),
      }
    ]
  }

  getDefaultProfileDialogButtons(strapiUserId: number): Observable<ProfileDialogDataButton[]> {
    const obs = new ReplaySubject();
    this.checkIfRequestIsAlreadySend(strapiUserId).pipe(
      take(2)
    ).subscribe(d => {
      obs.next(d)
    })
    return obs.asObservable().pipe(
      map((match: any) => {
        if (strapiUserId === parseInt(this.me()?.strapi_user_id)) {
          return [];
        }
        const group = this.getButtonGroup(match, this.me());
        if (group === 'pending') {
          return this.getPendingButtons(strapiUserId, obs);
        } else if (group === 'none' || group === 'waiting-response') {
          return this.getDefaultButtons(group, strapiUserId, obs);
        } else {
          return [];
        }
      })
    );
  }


  getUserById(userId: number): Observable<User> {
    return new StoredCacheObservable({
      localStorageKey: `user-by-id-${userId}`,
      observable: this.apiService.api.users().getById(userId).execute().pipe(
        map(d => d[0])
      )
    }).asObservable() as any
  }

  async openDefaultProfileDialog({
                                   user,
                                   userId,
                                   buttons,
                                   title
                                 }: { user?: User, userId?: number, title?: string, buttons?: Observable<ProfileDialogDataButton[]> }): Promise<MatDialogRef<ProfileDialogComponent>> {

    let userObs: Observable<User>;
    if (user?.id > 0) {
      userObs = of(user);
    } else {
      userObs = this.getUserById(userId);
    }
    let buttonsObs = buttons;
    if (!buttonsObs) {
      buttonsObs = userObs.pipe(
        switchMap(user => {
          return this.getDefaultProfileDialogButtons(parseInt(user?.strapi_user_id));
        })
      )
    }

    return this.dialog.open(ProfileDialogComponent, {
      data: {
        user: userObs,
        title: title ?? 'Profil',
        buttons: buttonsObs
      },
    });
  }

  openSubscriptionContainer() {
    return this.dialog.open(SubscriptionContainerComponent, {
      height: '80vh',
      width: '85vw',
    });
  }

  sendFriendRequest(strapiUserId: number): Promise<MatchV2> {
    this.snackbar.open('Venneamodning sendt');
    return this.apiService.api.matches().sendMatchRequest().addQueryParameter({matchId: strapiUserId}).executePromise() as any;
  }

  declineFriendRequest(strapiUserId: number): Promise<MatchV2> {
    return this.apiService.api.matches().rejectMatchRequest().addQueryParameter({matchId: strapiUserId}).executePromise().then(() => {
      this.notificationService.reloadUserFeed({strapi_user_id: strapiUserId} as any);
    }) as any;
  }

  acceptFriendRequest(strapiUserId: number): Promise<MatchV2> {
    return this.apiService.api.matches().acceptMatchRequest().addQueryParameter({matchId: strapiUserId}).executePromise().then(() => {
      this.notificationService.reloadUserFeed({strapi_user_id: strapiUserId} as any);
    }) as any;
  }


  public currentGroups(): Observable<StrapiModel<GroupAttributes>[]> {
    return new StoredCacheObservable({
      localStorageKey: `current-groups-${this.me().strapi_user_id}`,
      observable: this.apiService.api.users().currentGroups().execute().pipe(
        map(d => d[0])
      )
    }).asObservable() as any;
  }

  public openDialogFriendRequests() {
    return this.dialog.open(FriendRequestsDialogComponent, {
      data: {},
      width: '962px',
    });
  }

}
