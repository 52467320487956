import {Component, forwardRef, Inject, Input, OnInit, TrackByFunction} from '@angular/core';
import {StrapiModel} from "../../../../core/models/StrapiModel";
import {GroupAttributes} from "../../../../core/models/definitions/GroupDefinition";
import {ExploreComponent} from "../../explore.component";
import {Filter} from "../filter/filter.component";
import {AlgoliaService} from "../../../../services/algolia.service";
import { InterestsService } from '../../../../services/interests.service';
import { Interest, User } from '../../../../core/models';
import { UsersService } from '../../../../services/users.service';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-explore-groups',
  templateUrl: './explore-groups.component.html',
  styleUrls: ['./explore-groups.component.scss']
})


export class ExploreGroupsComponent implements OnInit {

  interests: Interest[];
  interests$: Observable<Interest[]>;
  tagsMatched: Interest[];
  tagsNoMatched: Interest[];



  @Input() user: User;
  constructor(
    @Inject(forwardRef(() => ExploreComponent))
    public exploreComponent: ExploreComponent,
    protected algoliaService: AlgoliaService,
    private interestService: InterestsService,
    private usersService: UsersService,
  ) {
    this.user = this.usersService.me();
    this.interests$ = this.interestService.getInterests().pipe(
      tap(d => this.interests = d)
    )
  }

  ngOnInit(): void {
  }

  isHidden(hit: any) {
    return hit.hideInSearch !== false;
  }


  get generateFilters() {
    return (filter: Filter) => {
      if (!filter.birthdate || !filter.childrenBirthdates || !filter.user) {
        return [];
      }
      return [
        {
          config: {indexName: 'groups', searchClient: this.algoliaService.searchClient},
          searchParams: this.algoliaService.userToFilter(filter.user, 'groups')
        },
      ]
    }
  }


  reduceArr<T>(arr: T[][]): T[] {
    return arr?.reduce((p, c) => {
      p.push(...c);
      return p;
    }, []) ?? [];
  }


  trackBy(): TrackByFunction<any> {
    return (index, item) => {
      return item.objectID;
    }
  }

  tagIdsToTags(tags: Interest[], ids): Interest[] {
    ids = ids.map(d => parseInt(d));
    return tags.filter(d => ids.indexOf(d.id) >= 0)
  }

  hitToGroup(hit): StrapiModel<GroupAttributes> {
    const obj = {
      id: hit.objectID,
      attributes: {
        name: hit?.name,
        membersCount: hit?.membersCount,
        addressInfo: hit?.addressInfo,
      }
    } as Partial<StrapiModel<GroupAttributes>>
    this.tagsMatched =  this.tagIdsToTags(this.user.tags, hit.tagIds);
    this.tagsNoMatched =  this.tagIdsToTags(this.interests, hit.tagIds);
    if(this.tagsMatched.length > 0){
      this.tagsMatched.forEach(element => {
        this.tagsNoMatched =  this.tagsNoMatched.filter(function(item) {
            return item.id != element.id;
        })
      });
    }
    if (hit.banner_image) {
      const formats = Object.keys(hit.banner_image);
      obj.attributes.banner_image = {attributes: {formats: {}}} as any;
      for (const format of formats) {
        obj.attributes.banner_image.attributes.formats[format] = {url: hit.banner_image[format]};
      }
    }
    return obj as any;
  }
}
