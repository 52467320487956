/**
 * Created by ModelParser
 */
import { Group } from '../Group';
import { Match, MatchInterface } from '../Match';
import { Message, MessageInterface } from '../Message';
import { ChannelMember, ChannelMemberInterface } from '../ChannelMember';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel'
import { StrapiModel } from '../StrapiModel';
import { GroupAttributes } from './GroupDefinition';

export interface ChannelDefinitionInterface {
    name?: string;
    group_id?: number;
    group?: StrapiModel<GroupAttributes>;
    match?: MatchInterface;
    first_message_id?: number;
    first_message?: MessageInterface;
    last_message_id?: number;
    last_message?: MessageInterface;
    message_count?: number;
    messages?: MessageInterface[];
    channel_members?: ChannelMemberInterface[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class ChannelDefinition extends BaseStoredModel implements ChannelDefinitionInterface {
    name?: string;
    group_id?: number;
    group?: Group;
    match?: Match;
    first_message_id?: number;
    first_message?: Message;
    last_message_id?: number;
    last_message?: Message;
    message_count?: number;
    messages?: Message[];
    channel_members?: ChannelMember[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.name;
            delete this.group_id;
            delete this.group;
            delete this.match;
            delete this.first_message_id;
            delete this.first_message;
            delete this.last_message_id;
            delete this.last_message;
            delete this.message_count;
            delete this.messages;
            delete this.channel_members;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.group_id != null) {
            this.group_id = data.group_id;
        }
        if (data.group != null) {
            this.group = data.group;
        }
        if (data.match != null) {
            this.match = new Match(data.match);
        }
        if (data.first_message_id != null) {
            this.first_message_id = data.first_message_id;
        }
        if (data.first_message != null) {
            this.first_message = new Message(data.first_message);
        }
        if (data.last_message_id != null) {
            this.last_message_id = data.last_message_id;
        }
        if (data.last_message != null) {
            this.last_message = new Message(data.last_message);
        }
        if (data.message_count != null) {
            this.message_count = data.message_count;
        }
        if (data.messages != null) {
            this.messages = data.messages.map((i: any) => new Message(i));
        }
        if (data.channel_members != null) {
            this.channel_members = data.channel_members.map((i: any) => new ChannelMember(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
