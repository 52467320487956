import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Message} from "@twilio/conversations";
import {User} from "../../../core/models";

export type Alignment = 'left' | 'right'

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageCardComponent implements OnInit {

  constructor() { }

  @Input() message: Message;
  @Input() alignment: Alignment
  @Input() sender: User

  ngOnInit(): void {
  }

}
