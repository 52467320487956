import { DateFormatPipe } from './date-format.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StrapiModelSelectPipe } from './strapi-model-select.pipe';
import {AgePipe} from "./age.pipe";
import { ShortDatePipe } from './short-date.pipe';

@NgModule({
  declarations: [
    StrapiModelSelectPipe,
    AgePipe,
    ShortDatePipe,
    DateFormatPipe
  ], exports: [
    StrapiModelSelectPipe, 
    AgePipe, 
    ShortDatePipe,
    DateFormatPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
