/**
 * Created by ModelParser
 */
import { Notification, NotificationInterface } from '../Notification';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface NotificationDataDefinitionInterface {
    notification_id?: number;
    notification?: NotificationInterface;
    data?: string;
    type?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class NotificationDataDefinition extends BaseStoredModel implements NotificationDataDefinitionInterface {
    notification_id?: number;
    notification?: Notification;
    data?: string;
    type?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.notification_id;
            delete this.notification;
            delete this.data;
            delete this.type;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.notification_id != null) {
            this.notification_id = data.notification_id;
        }
        if (data.notification != null) {
            this.notification = new Notification(data.notification);
        }
        if (data.data != null) {
            this.data = data.data;
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
