
<ng-container *ngIf="searchEvent$|async"></ng-container>
<div class="flex items-end mb-5">
  <h3 class="text-lg font-semibold">Beskeder</h3>
  <ng-container *ngIf="notificationData$|async as notification">
    <app-badge-notification [count]="notification.countConversations"></app-badge-notification>
  </ng-container>
</div>
<div class="w-full shadow-border rounded flex-grow h-[466px]">
  <ng-container *ngIf="!isFetching && notificationData$|async as notification; else loader">
    <div class="flex w-full h-full flex-col md:p-6 p-4"
      *ngIf="hasSearchConversations|| notification.subscribedConversations?.items?.length > 0; else noNotifications">
      <div class="flex w-full cursor-pointer items-center mb-4">
        <div class="border-solid flex flex-grow items-center border-b-2 pb-2.5 mr-10 border-[#B7B7B7]">
          <app-icon class="h-5" icon="search" color="dgray" (click)="handleInputSearch()"></app-icon>
          <input type="text" class="pl-2 w-full" autocomplete="off" (keydown.enter)="handleInputSearch()" matInput #searchInput />
        </div>
        <app-icon icon="newChat" (click)="newChat()" class="h-5 mb-3" color="dgray"></app-icon>
      </div>

      <div class="flex-grow overflow-auto pretty-scrollbar">
        <ng-container
          *ngFor="let conv of sortedConversations(notification.subscribedConversations?.items); trackBy: trackBy()">
          <div (click)="openConversation(conv)"
            class="flex items-center my-4 cursor-pointer pr-0 sm:pr-8 overflow-hidden"
            *ngIf="getUser(conv)|async as user">
            <div>
              <app-user-profile-icon class="w-9 h-9 inline-block mr-6" [user]="user"></app-user-profile-icon>
            </div>
            <div class="flex-grow min-w-0 mr-6">
              <p class="truncate" [class.font-bold]="hasUnreadMessages(conv)">{{user.first_name}} {{user.last_name}}</p>
              <p class="truncate" [class.font-bold]="hasUnreadMessages(conv)">{{conv.lastMessage ?
                ((getLastMessage(conv)|async)?.body ?? '&nbsp;') : ('Sig hej til ' + user.first_name)}}</p>
            </div>
            <p *ngIf="conv.lastMessage"
              class="hidden sm:inline-block capitalize text-xs text-dgray whitespace-nowrap italic">
              {{conv.lastMessage.dateCreated|dateFormatPipe}}</p>
            <div class="h-2 w-2 hidden sm:inline-block rounded-full ml-4" [class.bg-green]="hasUnreadMessages(conv)">
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #noNotifications>
  <div class="flex flex-col w-full h-full">
    <div class="flex-grow bg-cover rounded-t bg-center" style="background-image: url('/assets/Famoba_2sh21.jpg')"></div>
    <div class="w-72 mx-auto text-center px-2 md:px-0 pt-7">
      <p class="m-0 text-sm">
        Du har endnu ikke nogen ligesindede. <br />Kig på ‘Udforsk’ og se hvem vi tror du har en masse tilfælles med.
      </p>
    </div>
    <div class="w-full flex justify-center pt-[25px] pb-8">
      <a class="mx-auto button" [routerLink]="'/explore'">Find ligesindede</a>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>