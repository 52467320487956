/**
 * Prefixes all requests with `environment.serverUrl`.
 */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  private progressRef: NgProgressRef;
  private inProgressCount = 0;
  private progressStarted = false;
  private progressStartedDate: Date;
  private progressEndedTimeout: number;

  constructor(ngProgress: NgProgress, private ngZone: NgZone) {
    this.progressRef = ngProgress.ref('root');
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf('.svg') !== -1 ||
      request.url.indexOf('dawa.aws.dk') !== -1
    ) {
      return next.handle(request);
    } else {
      let serverUrl = environment.apiUrl;
      let host = window.location.origin.split('://')[1];
      const localHost =
        host === 'localhost:5500' ||
        host === 'localhost:4200' ||
        host === '192.168.1.107:5500' ||
        host === '192.168.1.108:5500' ||
        host === '192.168.0.112:4200';

      if (localHost) {
        host = serverUrl; // .slice(0, -1);
      } else {
        serverUrl = host; // Replace config with local url
      }

      if (environment.production) {
        serverUrl = environment.apiUrl;
      }

      request = request.clone({ url: serverUrl + request.url }); // Lokalt direkte forbindelse til API

      if (request.headers.has('ignoreProgressBar')) {
        request.headers.delete('ignoreProgressBar');
        return next.handle(request);
      }

      this.inProgressCount++;
      if (!this.progressStarted) {
        this.progressStarted = true;
        this.ngZone.run((_) => {
          this.progressStartedDate = new Date();
          if (this.progressEndedTimeout) {
            clearTimeout(this.progressEndedTimeout);
          }
          this.progressRef.start();
        });
      }

      return next.handle(request).pipe(
        finalize(() => {
          this.inProgressCount--;
          if (this.inProgressCount === 0) {
            this.progressStarted = false;
            this.ngZone.run((_) => {
              const ended = new Date();
              if (ended.getTime() - this.progressStartedDate.getTime() > 100) {
                // console.warn('STOP');
                this.progressRef.complete();
              } else {
                this.progressRef.complete();
                this.progressEndedTimeout = null;
                /*
                this.progressEndedTimeout = setTimeout((_: any) => {
                    // console.warn('STOP');

                }, 100);
                */
              }
            });
          }
        })
      );
    }
  }
}
