/**
 * Created by ModelParser
 * Date: 25-11-2019.
 * Time: 11:08.
 */
import { MatchDefinition, MatchDefinitionInterface } from './definitions/MatchDefinition';
import { User } from './User';

export interface MatchInterface extends MatchDefinitionInterface {

}

export class Match extends MatchDefinition implements MatchInterface {

    constructor(json?: any) {
        super(json);
        if (this.a) {
            this.a = new User(this.a);
        }
        if (this.b) {
            this.b = new User(this.b);
        }
    }

    public matchedUser(userId: number) {
        if (this.a && this.b) {
            if (this.a_id === userId) {
                return this.b;
            } else {
                return this.a;
            }
        }
        return null;
    }

}
