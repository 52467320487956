/**
 * Created by ModelParser
 * Date: 23-06-2020.
 * Time: 08:08.
 */
import { PermissionDefinition, PermissionDefinitionInterface } from './definitions/PermissionDefinition';

export interface PermissionInterface extends PermissionDefinitionInterface {

}

export class Permission extends PermissionDefinition implements PermissionInterface {

    constructor(json?: any) {
        super(json);
    }

}
