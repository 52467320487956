<div class="absolute left-[-212px]">
  <img alt="watermark" src="/assets/images/watermark_logo_part.svg">
</div>
<div class="container md:py-[100px] py-16 z-10" *ngIf="groups$|async as groups">
  <h3 class="text-center font-semibold text-lg mb-4">Grupper</h3>
  <p class="m-auto text-center max-w-[352px]" *ngIf="groups?.length === 0">Du er endnu ikke medlem af nogen grupper, men måske vores forslag er noget for dig?</p>
  <div class="w-full md:px-[0.2rem] flex mt-8 mb-14">
    <ng-template [ngIf]="groups?.length > 0">
      <div *ngIf="groups?.length === 1; then bigGroup else elseGroup"></div>
      <ng-template #bigGroup>
        <a class="w-full px-2 md:h-[320px] h-[286px] md:mb-0 mb-3" [routerLink]="'/groups/group/' + groups[0].id">
          <app-big-group-card [group]="groups[0]"></app-big-group-card>
        </a>
      </ng-template>
      <ng-template #elseGroup>
        <app-group-card-container>
          <ng-container *ngFor="let group of groups | keyvalue">
            <app-group-card 
              [routerLink]="'/groups/group/' + groups[group.key]?.id" 
              class="flex-shrink-0 max-w-[311px]" 
              [group]="groups[group.key]"
            ></app-group-card>
          </ng-container>
        </app-group-card-container>
      </ng-template>
    </ng-template>
    <ng-container *ngIf="groups?.length === 0">
      <ais-instantsearch class="w-full" [config]="config">
        <ais-configure [searchParameters]="filters"></ais-configure>
        <ais-hits class="w-full">
          <ng-template let-hits="hits">
            <app-group-card-container>
              <ng-container *ngFor="let hit of hits | keyvalue">
                <app-group-card 
                  *ngIf="!hits[+hit.key]?.hideInSearch" 
                  [routerLink]="'/groups/group/' + hits[+hit.key]?.objectID" 
                  class="flex-shrink-0 max-w-[311px]"
                  [group]="hitToGroup(hits[+hit.key])"
                ></app-group-card>
              </ng-container>
            </app-group-card-container>
          </ng-template>
        </ais-hits>
      </ais-instantsearch>
    </ng-container>
  </div>
  <div class="flex justify-center">
    <a class="btn-orange" routerLink="/explore/groups">Find flere grupper</a>
  </div>
</div>
