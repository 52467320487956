import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-page-support',
  templateUrl: './my-page-support.component.html',
  styleUrls: ['./my-page-support.component.scss']
})
export class MyPageSupportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
