import { Component, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Interest, User } from '../../core/models';
import algoliasearch from 'algoliasearch';
import { InstantSearchConfig, NgAisInstantSearch } from 'angular-instantsearch/instantsearch/instantsearch';
import { Observable } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { InterestsService } from '../../services/interests.service';
import {AlgoliaIndexName, FilterComponent, UserHit} from './components/filter/filter.component';
import { GoogleService } from '../../services/google.service';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {Location} from "@angular/common";
import {filter, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {AlgoliaService} from "../../services/algolia.service";


@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit {
  config: InstantSearchConfig;
  url$: Observable<any>;

  @ViewChild('filter') filterComponent: FilterComponent;

  tagIdsFilter: string[];
  selectedTags: string[];
  routes = [
    '/explore/like-minded',
    '/explore/groups'
  ]

  user: User;
  interests$: Observable<Interest[]>

  selectedIndex: number;

  constructor(
    protected dialog: MatDialog,
    protected usersService: UsersService,
    protected interestsService: InterestsService,
    protected googleService: GoogleService,
    protected activatedRoute: ActivatedRoute,
    protected location: Location,
    protected router: Router,
    protected algoliaService: AlgoliaService
  ) {
    this.initStartIndex();
    this.interests$ = this.interestsService.getInterests();
    this.user = this.usersService.me();
    this.initAlgoliaConfig();


    this.url$ = this.router.events.pipe(
      filter(d => d instanceof NavigationEnd),
      tap(d => {
        this.changeIndex(this.getIndexFromCurrentPath(), false);
      })
    )
  }

  initAlgoliaConfig() {
    this.config = {
      indexName: this.getAlgoliaIndex(this.selectedIndex),
      searchClient: this.algoliaService.searchClient,
    }
  }

  initStartIndex() {
    this.selectedIndex = this.getIndexFromCurrentPath()
  }

  getIndexFromCurrentPath() {
    const path = '/' + this.activatedRoute.snapshot.url.map(d => d.path).join('/')
    return this.routes.indexOf(path);
  }

  getAlgoliaIndex(indexNumber: number): AlgoliaIndexName {
    const indexes = ['members', 'groups'];
    return indexes[indexNumber] ?? 'members' as any;
  }

  ngOnInit() {
    this.updateTagIdsFilter();
  }

  tagsUpdates(values) {
    this.selectedTags = values;
    this.updateTagIdsFilter();
  }

  updateTagIdsFilter() {
    this.tagIdsFilter = this.selectedTags?.map(d => `tagIds:${d}`) ?? [];
  }

  interestGroupTooltipTracker: number = 46;

  setGroups(id: number) {
    this.setIdForGroups(id);
    this.setClassForGroups(id);
  }

  setIdForGroups(id: number) {
    return 'group-' + id;
  }

  grplocation = 100;

  setClassForGroups(id: number) {
    var temp = 'group-' + id;
    document.getElementById(temp).style.left = this.grplocation.toString();
    this.grplocation = this.grplocation + 25;
  }


  distance(hit: UserHit): string {
    return (this.googleService.computeDistanceBetween(hit._geoloc, {
      lng: this.user.address.long,
      lat: this.user.address.lat
    }) / 1000).toFixed(2)
  }

  changeIndex(indexNumber: number, updatePath = true) {
    this.selectedIndex = indexNumber;
    if (updatePath) {
      this.location.go(this.routes[this.selectedIndex]);
    }
  }

}
