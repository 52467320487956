import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss']
})
export class ProfileIconComponent implements OnInit {

  @Input() imgURL: string;
  @Input() icon: string;
  isLoadingImage: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  loadImage() {
    this.isLoadingImage = false;
  }
}
