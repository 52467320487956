import { Component, OnInit } from '@angular/core';
import {UsersService} from "../../../../services/users.service";
import {Observable} from "rxjs";
import {StrapiModel} from "../../../../core/models/StrapiModel";
import {GroupAttributes} from "../../../../core/models/definitions/GroupDefinition";
import {AlgoliaService} from "../../../../services/algolia.service";


@Component({
  selector: 'app-my-page-groups',
  templateUrl: './my-page-groups.component.html',
  styleUrls: ['./my-page-groups.component.scss']
})
export class MyPageGroupsComponent implements OnInit {

  filters;
  groups$: Observable<StrapiModel<GroupAttributes>[]>;

  constructor(
    private usersService: UsersService,
    protected algoliaService: AlgoliaService,
  ) { }

  ngOnInit(): void {
    this.groups$ = this.usersService.currentGroups();
    this.filters = {hitsPerPage: 3, ...this.algoliaService.userToFilter(this.usersService.me(), 'groups')};
  }

  get config() {
    return {
      indexName: 'groups',
      searchClient: this.algoliaService.searchClient
    };
  }


  hitToGroup(hit): StrapiModel<GroupAttributes> {
    const obj = {
      id: hit.objectID,
      attributes: {
        name: hit.name,
        membersCount: hit.membersCount,
      }
    } as Partial<StrapiModel<GroupAttributes>>

    if (hit.banner_image) {
      const formats = Object.keys(hit.banner_image);
      obj.attributes.banner_image = {attributes: {formats: {}}} as any;
      for (const format of formats) {
        obj.attributes.banner_image.attributes.formats[format] = {url: hit.banner_image[format]};
      }
    }

    return obj as any;
  }
}
