import { Observable, Subscription } from 'rxjs';
import {HttpEvent, HttpHeaders, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import { ApiFilter } from './ApiFilter';
import { ApiInclude } from './ApiInclude';
import { ApiOrdering } from './ApiOrdering';
import { ApiCollectionBase } from './ApiCollectionBase';
import { environment } from '../../../../environments/environment';

export type RequestMethod = 'get' | 'put' | 'patch' | 'post' | 'delete'

export abstract class BaseApi<T = any, QueryParams = { [key: string]: any }> extends ApiCollectionBase {

  protected topic: string;

  protected method: RequestMethod;
  protected limitValue: number;
  protected offsetValue: number;
  private apiFilter: ApiFilter;
  private apiInclude: ApiInclude;
  private apiOrdering: ApiOrdering;
  private apiQueryParameters: string[][];

  constructor(
    injections: any,
    private _additionalArguments?: {[key: string]: any}
  ) {
    super(injections);
  }

  get additionalArguments() {
    return this._additionalArguments || {};
  }

  abstract get uri(): string;

  protected filter(): ApiFilter {
    if (!this.apiFilter) {
      this.apiFilter = new ApiFilter();
    }
    return this.apiFilter;
  }

  protected getInclude(): ApiInclude {
    if (!this.apiInclude) {
      this.apiInclude = new ApiInclude();
    }
    return this.apiInclude;
  }

  protected ordering(): ApiOrdering {
    if (!this.apiOrdering) {
      this.apiOrdering = new ApiOrdering();
    }
    return this.apiOrdering;
  }

  public addQueryParameter(params: Partial<QueryParams>) {
    if (!this.apiQueryParameters) {
      this.apiQueryParameters = [];
    }
    const values = Object.keys(params).map(d => [d, params[d]])
    this.apiQueryParameters.push(...values);
    return this;
  }

  protected convertToResource(data: any): T {
    return data;
  }

  /**
   * @deprecated use this.get()
   * @protected
   */
  protected executeFind(): Observable<T> {
    return this.get();
  }

  public onPushCreated(callback: (value: T) => void) {
    if (this.topic) {
      throw new Error('Not implemented');
    }
    return this;
  }

  public onPushUpdated(callback: (value: T) => void) {
    if (this.topic) {
      throw new Error('Not implemented');
    }
    return this;
  }

  public onPushDeleted(callback: (value: T) => void) {
    if (this.topic) {
      throw new Error('Not implemented');
    }
    return this;
  }

  public include(name: string): this {
    this.getInclude().include(name);
    return this;
  }

  public find(): Observable<T> {
    return this.get();
  }

  /**
   * @deprecated use execute
   * @param data
   * @param next
   */
  public save(data: T): Observable<T> {
    return this.execute(data);
  }

  public executePromise(data?: T): Promise<T> {
    return this.execute(data).pipe(take(1)).toPromise();
  }

  public execute(data?: T): Observable<T> {
    switch (this.method) {
      case 'get':
        return this.get();
      case 'post':
        return this.post(data) as any;
      case 'put':
        return this.put(data);
      case 'patch':
        return this.patch(data);
      case 'delete':
        return this.delete();
    }
  }

  private getParams(): HttpParams {
    let params = new HttpParams();

    /*
     * Filtering
     */
    if (this.apiFilter) {
      params = params.append('filter', this.apiFilter.toString());
    }

    /*
     * Include
     */
    if (this.apiInclude) {
      params = params.append('include', this.apiInclude.toString());
    }

    /*
     * Ordering
     */
    if (this.apiOrdering) {
      params = params.append('ordering', this.apiOrdering.toString());
    }

    /*
     * Offset & Limit
     */
    if (this.limitValue != null && this.limitValue >= 0) {
      params = params.append('limit', this.limitValue.toString());
    }
    if (this.offsetValue != null) {
      params = params.append('offset', this.offsetValue.toString());
    }

    /*
     * Access Token
     */
    const token = this.config.authenticationService.getAuthorizationToken();
    if (token) {
      params = params.append('access_token', token);
    }

    /*
     * Query Parameters
     */
    if (this.apiQueryParameters) {
      this.apiQueryParameters.forEach(pair => {
        params = params.append(pair[0], pair[1].toString());
      });
    }

    return params;
  }

  protected request<S = any, K = any>(config: HttpRequest<S>): Observable<K[]> {
    return this.config.headers().pipe(
      switchMap((res) => {
        const params = this.getParams();
        const headers = (typeof (res as HttpHeaders)?.getAll === 'function' ? res : new HttpHeaders(res as any)) as HttpHeaders
        const requestConfig = config.clone({
          params,
          headers,
          reportProgress: false,
        })
        this.config.httpClient.request('m', '', )
        return this.config.httpClient.request<K[]>(requestConfig)
      }),
      filter(d => d?.type !== 0),
      map(d => (d as any)?.body),
      map((response: any) => {
        if (response?.resources) {
          return response.resources.map(resource => this.convertToResource(resource));
        } else if (response?.resource) {
          return [this.convertToResource(response.resource)];
        } else {
          return [];
        }
      })
    )
  }

  private get(): Observable<T> {
    return this.request(new HttpRequest('GET', `${environment.apiPath}${this.uri}`)) as any;
  }

  private post(data) {
    return this.request(new HttpRequest('POST', `${environment.apiPath}${this.uri}`, data));
  }

  private put(data): Observable<T> {
    return this.request(new HttpRequest('PUT', `${environment.apiPath}${this.uri}`, data)).pipe(
      map(d => d?.[0])
    )
  }

  private patch(data): Observable<T> {
    return this.request(new HttpRequest('PATCH', `${environment.apiPath}${this.uri}`, data)).pipe(
      map(d => d?.[0])
    )
  }

  protected delete(): Observable<any> {
    return this.request(new HttpRequest('DELETE', `${environment.apiPath}${this.uri}`)).pipe(
      map(d => d?.[0])
    )
  }
}
