/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { SubscriptionDefinition, SubscriptionDefinitionInterface } from './definitions/SubscriptionDefinition';

export interface SubscriptionInterface extends SubscriptionDefinitionInterface {

}

export class Subscription extends SubscriptionDefinition implements SubscriptionInterface {

    constructor(json?: any) {
        super(json);
    }

}
