import {ApiCollectionBase} from "../ApiCollectionBase";
import {BaseResourceApi} from "../BaseResourceApi";
import {ReportDataAttributes} from "./strapi-report";


export class ReportApi extends ApiCollectionBase {

  public newReport(): BaseResourceApi<ReportDataAttributes> {
    return new BaseResourceApi(this.config, {
      uri: `/report`, method: 'post'
    })
  }


}
