import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AlgoliaService} from "../../../services/algolia.service";
import {InstantSearchConfig} from "angular-instantsearch/instantsearch/instantsearch";
import {PlainSearchParameters} from "algoliasearch-helper";
import {NgAisRefinementList} from "angular-instantsearch/refinement-list/refinement-list";
import {ChipSelectValue} from "../chip-select/chip-select.component";

@Component({
  selector: 'app-algolia-tag-search',
  templateUrl: './algolia-tag-search.component.html',
  styleUrls: ['./algolia-tag-search.component.scss'],
})
export class AlgoliaTagSearchComponent implements OnInit {

  private changeCount = 0;

  @Input() attribute;
  @Input() indexName;

  @ViewChild('refinementList') refinementList: NgAisRefinementList
  @Input() initialSearchValue: string[] = []
  @Output() change: EventEmitter<ChipSelectValue[]> = new EventEmitter<ChipSelectValue[]>()

  constructor(
    protected algoliaService: AlgoliaService,
    protected changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
  }

  public searchForItems(value: string) {
    this.refinementList.state.searchForItems(value);
  }

  get config(): InstantSearchConfig {
    return {
      searchClient: this.algoliaService.searchClient,
      indexName: this.indexName,
      initialUiState: {
        [this.indexName]: {
          refinementList: {
            [this.attribute]: this.initialSearchValue
          }
        }
      }
    }
  }

  get searchParams(): PlainSearchParameters {
    return {
      hitsPerPage: 1
    }
  }

  triggerChange() {
    this.changeCount++;
    if (this.changeCount < 2) {
      return;
    }
    this.changeDetector.detectChanges();
    this.change.emit(this.refinementList.state.items.filter(d => d.isRefined).map(d => d.value))
  }

  setValues(values: string[]) {
    for (const val of values) {
      this.refinementList.state.refine(val)
    }
  }

}
