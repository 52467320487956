<ng-container *ngIf="icon === 'arrow-back'">
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    [class]="getClassList('fill')"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.82835 8.50006L9.9142 1.41421L8.49998 0L0 8.49998L7.9155e-05 8.50006L0 8.50014L8.49998 17.0001L9.9142 15.5859L2.82835 8.50006Z"
      [class]="getClassList('fill')"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'arrow'">
  <svg
    width="28"
    height="21"
    viewBox="0 0 28 21"
    [class]="getClassList('fill')"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="16.8965"
      width="14.8492"
      height="2.6833"
      transform="rotate(45 16.8965 0)"
      [class]="getClassList('fill')"

    />
    <rect
      x="27.3965"
      y="10.5"
      width="14.8492"
      height="2.39004"
      transform="rotate(135 27.3965 10.5)"
      [class]="getClassList('fill')"
    />
    <rect y="9" width="25" height="3" [class]="getClassList('fill')"/>
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'arrow-down'">
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    [class]="getClassList('fill')"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M-0.000122379 1.41428L7.07095 8.48535L7.07103 8.48527L7.07107 8.48532L14.1421 1.41425L12.7279 3.75906e-05L7.07099 5.65697L1.41409 7.05101e-05L-0.000122379 1.41428Z"
      [class]="getClassList('fill')"

    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'arrow-long'">
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    [class]="getClassList('fill')"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="16.9689"
      width="15.6002"
      height="2.10114"
      transform="rotate(45 16.9689 0)"
      [class]="getClassList('fill')"

    />
    <rect
      x="28"
      y="11.031"
      width="15.6002"
      height="2.10114"
      transform="rotate(135 28 11.031)"
      [class]="getClassList('fill')"
    />
    <rect y="10" width="25" height="2" [class]="getClassList('fill')"/>
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'child'">
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6101 15.255C22.1051 14.85 21.4651 14.61 20.7701 14.61C20.0751 14.61 19.4351 14.85 18.9301 15.255"
      stroke="#7380A3"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M11.07 15.255C10.565 14.85 9.92501 14.61 9.23001 14.61C8.53501 14.61 7.89501 14.85 7.39001 15.255"
      stroke="#7380A3"
      [class]="getClassList('stroke')"

      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M12.49 22.795C13.18 23.345 14.05 23.675 15 23.675C15.95 23.675 16.82 23.345 17.51 22.795"
      stroke="#7380A3"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
      stroke="#7380A3"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M10.6851 1.67999C14.2301 0.404989 18.1351 2.24499 19.4051 5.78999C20.1301 7.80999 19.0801 10.04 17.0601 10.765C15.0401 11.49 12.8101 10.44 12.0851 8.41999C11.5851 7.02999 11.9251 5.53999 12.8451 4.50999"
      stroke="#7380A3"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'calendar'">
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    [class]="getClassList('fill')"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="29"
      height="29"
      rx="7"
      stroke="#7380A3"
      stroke-width="2"
    />
    <line x1="1" y1="10" x2="30" y2="10" stroke="#7380A3" stroke-width="2"/>
    <rect x="5" y="14" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="5" y="18" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="13" y="14" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="13" y="18" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="21" y="14" width="5" height="2" rx="1" stroke="#7380A3"/>
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'cart'">
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    [class]="getClassList('fill')"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="29"
      height="29"
      rx="7"
      stroke="#7380A3"
      stroke-width="2"
    />
    <line x1="1" y1="10" x2="30" y2="10" stroke="#7380A3" stroke-width="2"/>
    <rect x="5" y="14" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="5" y="18" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="13" y="14" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="13" y="18" width="5" height="2" rx="1" stroke="#7380A3"/>
    <rect x="21" y="14" width="5" height="2" rx="1" stroke="#7380A3"/>
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'profile-male'">
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.39001 14.61C7.89501 15.015 8.53501 15.255 9.23001 15.255C9.92501 15.255 10.565 15.015 11.07 14.61"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M18.9301 14.61C19.4351 15.015 20.0751 15.255 20.7701 15.255C21.4651 15.255 22.1051 15.015 22.6101 14.61"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M12.49 22.795C13.18 23.345 14.05 23.675 15 23.675C15.95 23.675 16.82 23.345 17.51 22.795"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M28.975 14.335C26.605 13.305 22.98 11.12 22.98 7.38503V4.96503"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M2.375 8.95C7.92 10.885 17.035 12.23 23.745 4.065"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'profile-female'">
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.71179 11.9103C1.71179 11.9103 10.9003 10.4942 18.2865 4.02527"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M29.1164 12.4736C29.1164 12.4736 20.9095 12.4736 16.1624 5.71497"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M7.73022 14.6138C8.22908 15.0161 8.87275 15.2574 9.56471 15.2574C10.2567 15.2574 10.9003 15.0161 11.3992 14.6138"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M19.2842 14.6138C19.783 15.0161 20.4267 15.2574 21.1187 15.2574C21.8106 15.2574 22.4543 15.0161 22.9531 14.6138"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M15.3416 29C23.0735 29 29.3416 22.732 29.3416 15C29.3416 7.26801 23.0735 1 15.3416 1C7.60957 1 1.34155 7.26801 1.34155 15C1.34155 22.732 7.60957 29 15.3416 29Z"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M12.8313 22.8046C13.5233 23.3517 14.3922 23.6896 15.3416 23.6896C16.2911 23.6896 17.16 23.3517 17.852 22.8046"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M1.38991 16.0782C1.63129 19.2483 1.34164 22.8851 1.0198 25.492C0.810605 27.1495 2.29106 28.5334 3.94854 28.1954C5.63819 27.8575 9.14624 27.5518 11.4152 28.4368"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M29.3095 16.0782C29.0681 19.2483 29.3577 22.8851 29.6796 25.492C29.8888 27.1495 28.4083 28.5334 26.7508 28.1954C25.0612 27.8575 21.5531 27.5518 19.2842 28.4368"
      [class]="getClassList('stroke')"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'location'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="26"
    viewBox="0 0 20 26"
  >
    <defs>
    </defs>
    <g transform="translate(-901.9 332)">
      <g transform="translate(901.9 -332)">
        <path
          class="a"
          [class]="getClassList('fill')"
          d="M912-306a1.128,1.128,0,0,1-.784-.288l-.1-.1c-.588-.863-1.275-1.727-2.059-2.686l-.686-.863c-.98-1.247-2.059-2.686-3.039-4.125-2.353-3.454-3.431-6.044-3.431-8.155a9.7,9.7,0,0,1,2.941-6.908A10.135,10.135,0,0,1,911.9-332h0a9.936,9.936,0,0,1,10,9.786h0c0,2.111-1.078,4.7-3.431,8.155a48.971,48.971,0,0,1-3.039,4.03l-.686.863c-.784.959-1.471,1.919-2.059,2.686l-.1.1C912.586-306.1,912.292-306,912-306Zm0-24.081a7.991,7.991,0,0,0-8.039,7.867c0,1.727.98,4.03,3.039,7.1.882,1.343,1.961,2.686,2.941,3.934l.686.863A22.152,22.152,0,0,1,912-308.494c.392-.576.882-1.151,1.373-1.823l.686-.863c.882-1.151,1.961-2.59,2.941-3.934,2.059-3.07,3.039-5.373,3.039-7.1A7.867,7.867,0,0,0,912-330.081Z"
          transform="translate(-901.9 332)"
        />
      </g>
      <g transform="translate(909.297 -324.88)">
        <circle
          class="a"
          [class]="getClassList('fill')"
          cx="2.7"
          cy="2.7"
          r="2.7"
        />
      </g>
    </g>
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'x'">
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <defs></defs>
    <path
      class="a" [class]="getClassList('fill')"
      d="M22758.5,23676.906l-8.092,8.094-1.408-1.408,8.092-8.092-8.092-8.092,1.408-1.408,8.092,8.092,8.09-8.092,1.41,1.408-8.094,8.092,8.094,8.092-1.41,1.408Z"
      transform="translate(-22748.998 -23666)"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'logo-famoba'">
  <svg xmlns="http://www.w3.org/2000/svg" class="overflow-visible" width="22" height="24" viewBox="0 0 22 24">
    <path
      [class]="getClassList('fill') + ' a'"
      d="M22,10.8a16.24,16.24,0,0,0-.306-2.7.726.726,0,0,0-.917-.525.711.711,0,0,0-.535.9,7.778,7.778,0,0,1,.306,2.325,9.435,9.435,0,0,1-9.472,9.3,9.4,9.4,0,0,1-9.549-9.3A9.435,9.435,0,0,1,11,1.5a5.072,5.072,0,0,1,5.118,5.025A4.839,4.839,0,0,1,14.59,10.05,4.946,4.946,0,0,1,11,11.475h0A4.952,4.952,0,0,1,6.569,8.85.78.78,0,0,0,5.5,8.55.752.752,0,0,0,5.194,9.6,6.553,6.553,0,0,0,11,12.975h0A6.651,6.651,0,0,0,15.66,11.1a6.409,6.409,0,0,0,1.91-4.575A6.591,6.591,0,0,0,10.924,0,10.936,10.936,0,0,0,0,10.8a10.583,10.583,0,0,0,3.972,8.325,2.947,2.947,0,0,0-.611.75A2.961,2.961,0,0,0,4.736,23.7a2.548,2.548,0,0,0,1.3.3,3.9,3.9,0,0,0,.917-.15,2.842,2.842,0,0,0,1.91-2.4A9.835,9.835,0,0,0,11,21.675h0a9.65,9.65,0,0,0,2.063-.225,2.275,2.275,0,0,0,.229.975,2.711,2.711,0,0,0,1.6,1.425,3.936,3.936,0,0,0,.993.15,2.274,2.274,0,0,0,1.222-.3,2.7,2.7,0,0,0,1.451-1.575,2.763,2.763,0,0,0-.687-2.925A10.524,10.524,0,0,0,22,10.8ZM7.333,21.15a1.381,1.381,0,0,1-.917,1.275,1.161,1.161,0,0,1-.993-.075,1.653,1.653,0,0,1-.687-.75,1.285,1.285,0,0,1,.076-1.05.915.915,0,0,1,.535-.525A13.043,13.043,0,0,0,7.41,21C7.333,21.075,7.333,21.075,7.333,21.15Zm9.854.45a1.653,1.653,0,0,1-.687.75,1.707,1.707,0,0,1-1.069.075,1.662,1.662,0,0,1-.764-.675,2.156,2.156,0,0,1-.153-.675,8.668,8.668,0,0,0,2.063-.975A1.148,1.148,0,0,1,17.188,21.6Z"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'search'">
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <path
      [class]="getClassList('fill') + ' a'"
      d="M29.207,27.684l-6.953-7.016a9.909,9.909,0,1,0-1.5,1.523l6.907,6.971a1.07,1.07,0,0,0,1.51.039A1.077,1.077,0,0,0,29.207,27.684ZM14.467,22.275A7.823,7.823,0,1,1,20,19.984,7.777,7.777,0,0,1,14.467,22.275Z"
      transform="translate(-4.5 -4.493)"
    />
  </svg>
</ng-container>
<ng-container *ngIf="icon === 'newChat'">
  <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="21.5" viewBox="0 0 22.5 21.5">
    <g id="Group_234" data-name="Group 234" transform="translate(-665.25 -419.25)">
      <g id="Group_42" data-name="Group 42" transform="translate(-66 638)">
        <path id="Path_482" data-name="Path 482" d="M459.292.25h12.416a5.041,5.041,0,0,1,5.042,5.014v7.474a5.035,5.035,0,0,1-5.042,5.016h-4l-4.663,3.617a1.332,1.332,0,0,1-.933.379,1.352,1.352,0,0,1-1.353-1.349V17.754h-1.471a5.035,5.035,0,0,1-5.042-5.016V5.266A5.035,5.035,0,0,1,459.292.25Zm12.416,16a3.533,3.533,0,0,0,3.542-3.516V5.266a3.538,3.538,0,0,0-3.542-3.516H459.292a3.533,3.533,0,0,0-3.542,3.516v7.473a3.533,3.533,0,0,0,3.542,3.516h2.043a.928.928,0,0,1,.928.926v2.9l4.63-3.591a.922.922,0,0,1,.619-.237Z" transform="translate(277 -219)" fill="#f4a266"/>
      </g>
      <path id="Path_505_-_Outline" data-name="Path 505 - Outline" d="M275.972,62.75h1.056v2.222h2.222v1.056h-2.222V68.25h-1.056V66.028H273.75V64.972h2.222Z" transform="translate(400 363)" fill="#f4a266"/>
    </g>
  </svg>
</ng-container>
<ng-container *ngIf="isFaIcon">
  <span>Not implemented</span>
</ng-container>
