import { FileUploadType } from '../../../services/strapi.service';
import {
  Attendee,
  Channel,
  ChannelMember,
  Event,
  EventStar,
  File,
  Group,
  Interest,
  Match,
  Message,
  Notification,
  NotificationChannel,
  NotificationSubscription,
  NotificationTrigger,
  Permission,
  Plan,
  Preference,
  Searchprofile,
  Subscription,
  System,
  Tag,
  User
} from '../../models';
import { Child } from '../../models/Child';
import { MatchV2 } from "../../models/definitions/MatchDefinition";
import { ApiCollectionBase } from './ApiCollectionBase';
import { BaseGetResourceApi } from './BaseGetResourceApi';
import { BaseResourceApi } from './BaseResourceApi';
import { GroupApi } from "./groups/GroupApi";
import { MessageApi } from "./messages/MessageApi";
import { ApiCart } from './parts/ApiCart';
import { ApiCategories } from './parts/ApiCategories';
import { ApiSubscriptionInvite } from './parts/ApiSubscriptionInvite';
import { ApiSubscriptions } from './parts/ApiSubscriptions';
import { ReportApi } from './reports/ReportApi';
import { UsersApi } from "./users/UsersApi";

export interface CartCheckoutRequest {
  event_ids?: number[];
  redirect_uri?: string;
  user_id?: number;
  user?: User;
}

export interface CartCheckoutResponse {
  link?: string;
}

export interface EventSearchMatch {
  event?: Event;
  score?: number;
}

export interface FriendRequestCheckResponse {
  result?: boolean;
}


export interface GroupSearchMatch {
  group?: Group;
  score?: number;
}

export interface PaymentsLinkResponse {
  link?: string;
}

export interface SearchEventFilterRequest {
  type?: string;
  low_value?: string;
  high_value?: string;
  low_date?: string;
  high_date?: string;
  gps_latitude?: number;
  gps_longitude?: number;
}

export interface SearchEventRequest {
  filters?: SearchEventFilterRequest[];
  interests?: number[];
  tags?: number[];
  categories?: number[];
  period_start?: string;
  period_end?: string;
}

export interface SearchGroupFilterRequest {
  type?: string;
  low_value?: string;
  high_value?: string;
  low_date?: string;
  high_date?: string;
  gps_latitude?: number;
  gps_longitude?: number;
}

export interface SearchGroupRequest {
  filters?: SearchGroupFilterRequest[];
  interests?: number[];
  tags?: number[];
}

export interface SearchUserFilterRequest {
  type?: string;
  low_value?: string;
  high_value?: string;
  low_date?: string;
  high_date?: string;
  gps_latitude?: number;
  gps_longitude?: number;
}

export interface SearchUserRequest {
  filters?: SearchUserFilterRequest[];
  interests?: number[];
}

export interface SystemCalendarsPutRequest {
  id?: string;
}

export interface UserSearchMatch {
  user?: User;
  score?: number;
}

export class Api extends ApiCollectionBase {

  public cart(): ApiCart {
    return new ApiCart(this.config);
  }

  public categories(): ApiCategories {
    return new ApiCategories(this.config);
  }

  public channels(): Channels {
    return new Channels(this.config);
  }

  public children(): Children {
    return new Children(this.config);
  }

  public events(): Events {
    return new Events(this.config);
  }

  public files(): Files {
    return new Files(this.config);
  }

  public groups(): GroupApi {
    return new GroupApi(this.config);
  }

  public reports(): ReportApi {
    return new ReportApi(this.config);
  }

  public interests(): Interests {
    return new Interests(this.config);
  }

  public matches(): Matches {
    return new Matches(this.config);
  }

  public messages(): MessageApi {
    return new MessageApi(this.config);
  }

  public notificationChannels(): NotificationChannels {
    return new NotificationChannels(this.config);
  }

  public notificationSubscriptions(): NotificationSubscriptions {
    return new NotificationSubscriptions(this.config);
  }

  public notificationTriggers(): NotificationTriggers {
    return new NotificationTriggers(this.config);
  }

  public notifications(): Notifications {
    return new Notifications(this.config);
  }

  public permissions(): Permissions {
    return new Permissions(this.config);
  }

  public plans(): Plans {
    return new Plans(this.config);
  }

  public preferences(): Preferences {
    return new Preferences(this.config);
  }

  public search(): Search {
    return new Search(this.config);
  }

  public searchprofile(): Searchprofiles {
    return new Searchprofiles(this.config);
  }

  public matchingUsers(): MatchingUsers {
    return new MatchingUsers(this.config);
  }

  public matchingGroups(): MatchingGroups {
    return new MatchingGroups(this.config);
  }

  public matchingEvents(): MatchingEvents {
    return new MatchingEvents(this.config);
  }

  public subscriptions(): ApiSubscriptions {
    return new ApiSubscriptions(this.config);
  }

  public subscriptionInvite(): ApiSubscriptionInvite {
    return new ApiSubscriptionInvite(this.config);
  }

  public systems(): Systems {
    return new Systems(this.config);
  }

  public tags(): Tags {
    return new Tags(this.config);
  }

  public users(): UsersApi {
    return new UsersApi(this.config);
  }

  public strapi(): Strapi {
    return new Strapi(this.config);
  }

}



class Channels extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Channel> {
    return new BaseGetResourceApi<Channel>(this.config, {
      resourceClass: Channel,
      uri: '/channels'
    });
  }

  public deleteById(id: number): BaseResourceApi<Channel> {
    return new BaseResourceApi<Channel>(this.config, {
      resourceClass: Channel,
      uri: `/channels/${id}`,
      method: 'delete'
    });
  }

  public getById(id: number): BaseResourceApi<Channel> {
    return new BaseResourceApi<Channel>(this.config, {
      resourceClass: Channel,
      uri: `/channels/${id}`
    });
  }

  public leavePutById(id: number): BaseResourceApi<ChannelMember> {
    return new BaseResourceApi<ChannelMember>(this.config, {
      uri: `/channels/${id}/leave`,
      method: 'put',
      resourceClass: ChannelMember
    });
  }

  public messagesGetGetById(id: number): BaseResourceApi<Message> {
    return new BaseResourceApi<Message>(this.config, {
      uri: `/channels/${id}/messages`,
      resourceClass: Message
    });
  }

  public messagesPostPostById(id: number): BaseResourceApi<Message> {
    return new BaseResourceApi<Message>(this.config, {
      method: 'post',
      resourceClass: Message,
      uri: `/channels/${id}/messages`
    });
  }

}

class Children extends ApiCollectionBase {
  public get(): BaseGetResourceApi<Child> {
    return new BaseGetResourceApi<Child>(this.config, {
      uri: '/children',
      resourceClass: Child
    });
  }

  public getActiveChild(): BaseGetResourceApi<Child> {
    return new BaseGetResourceApi<Child>(this.config, {
      uri: '/children/active',
      resourceClass: Child
    });
  }

  public post(): BaseResourceApi<Child> {
    return new BaseResourceApi<Child>(this.config, {
      uri: '/children/new',
      method: 'post',
      resourceClass: Child
    });
  }

  public patch(id: number): BaseResourceApi<Child> {
    return new BaseResourceApi<Child>(this.config, {
      uri: `/children/edit/${id}`,
      method: 'patch',
      resourceClass: Child
    });
  }

  public delete(id: number): BaseResourceApi<Child> {
    return new BaseResourceApi<Child>(this.config, {
      uri: `/children/delete/${id}`,
      method: 'delete',
      resourceClass: Child
    });
  }

  public makeChildActive(id: number): BaseResourceApi<Child> {
    return new BaseResourceApi<Child>(this.config, {
      resourceClass: Child,
      uri: `/children/active/${id}`,
      method: 'patch'
    });
  }
}

class Events extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Event> {
    return new BaseGetResourceApi<Event>(this.config, {
      resourceClass: Event,
      uri: '/events'
    });
  }

  public getById(id: number): BaseResourceApi<Event> {
    return new BaseResourceApi<Event>(this.config, {
      uri: `/events/${id}`,
      resourceClass: Event
    });
  }

  public patchById(id: number): BaseResourceApi<Event> {
    return new BaseResourceApi<Event>(this.config, {
      uri: `/events/${id}`,
      resourceClass: Event,
      method: 'patch'
    });
  }

  public patch(): BaseResourceApi<Event> {
    return new BaseResourceApi<Event>(this.config, {
      method: 'patch',
      uri: `/events`,
      resourceClass: Event
    });
  }

  public starPutPutById(id: number): BaseResourceApi<EventStar> {
    return new BaseResourceApi<EventStar>(this.config, {
      uri: `/events/${id}/star`,
      resourceClass: EventStar,
      method: 'put'
    });
  }

  public starDeleteDeleteById(id: number): BaseResourceApi<EventStar> {
    return new BaseResourceApi<EventStar>(this.config, {
      uri: `/events/${id}/star`,
      resourceClass: EventStar,
      method: 'delete'
    });
  }

  public attendeesPatchById(id: number): BaseResourceApi<Attendee> {
    return new BaseResourceApi<Attendee>(this.config, {
      uri: `/events/${id}/attendees`,
      method: 'patch',
      resourceClass: Attendee
    });
  }

  public attendeesDeleteDeleteByEventIdByUserId(eventId: number, userId: number): BaseResourceApi<EventStar> {
    return new BaseResourceApi<EventStar>(this.config, {
      uri: `/events/${eventId}/attendees/${userId}`,
      resourceClass: EventStar,
      method: 'delete'
    });
  }

  public attendeesGetById(id: number): BaseResourceApi<Attendee> {
    return new BaseResourceApi<Attendee>(this.config, {
      uri: `/events/${id}/attendees`,
      resourceClass: Attendee
    });
  }

  public relatedGetGetById(id: number): BaseResourceApi<Event> {
    return new BaseResourceApi<Event>(this.config, {
      uri: `/events/${id}/related`,
      resourceClass: Event
    });
  }

  public createZoomLinkById(id: number): BaseResourceApi<Event> {
    return new BaseResourceApi<Event>(this.config, {
      uri: `/events/${id}/video_meeting`,
      method: 'post',
      resourceClass: Event
    });
  }

  public removeZoomLinkById(id: number): BaseResourceApi<Event> {
    return new BaseResourceApi<Event>(this.config, {
      method: 'patch',
      uri: `/events/${id}/remove_video_meeting`,
      resourceClass: Event
    });
  }

}

class Files extends ApiCollectionBase {

  public post(): BaseResourceApi<File> {
    return new BaseResourceApi<File>(this.config, {
      method: 'post',
      uri: `/files`,
      resourceClass: File
    });
  }

  public displayGetById(id: number): BaseResourceApi<File> {
    return new BaseResourceApi<File>(this.config, {
      uri: `/files/${id}/display`,
      resourceClass: File
    });
  }

}

class Interests extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Interest> {
    return new BaseGetResourceApi<Interest>(this.config, {
      uri: '/interests',
      resourceClass: Interest
    });
  }

  public getById(id: number): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: `/interests/${id}`,
      resourceClass: Interest,
    });
  }

  public post(): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: '/interests',
      resourceClass: Interest,
      method: 'post'
    });
  }

  public putById(id: number): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: `/interests/${id}`,
      resourceClass: Interest,
      method: 'put'
    });
  }

  public put(): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: '/interests',
      resourceClass: Interest,
      method: 'put'
    });
  }

  public patchById(id: number): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: `/interests/${id}`,
      resourceClass: Interest,
      method: 'patch'
    });
  }

  public patch(): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: '/interests',
      resourceClass: Interest,
      method: 'patch'
    });
  }

  public deleteById(id: number): BaseResourceApi<Interest> {
    return new BaseResourceApi<Interest>(this.config, {
      uri: `/interests/${id}`,
      resourceClass: Interest,
      method: 'delete'
    });
  }

}

class Matches extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Match> {
    return new BaseGetResourceApi<Match>(this.config, {
      uri: '/matches',
      resourceClass: Match
    });
  }

  public getById(id: number): BaseResourceApi<Match> {
    return new BaseResourceApi<Match>(this.config, {
      uri: `/matches/${id}`,
      resourceClass: Match
    });
  }

  public deleteById(id: number): BaseResourceApi<Match> {
    return new BaseResourceApi<Match>(this.config, {
      uri: `/matches/${id}`,
      resourceClass: Match,
      method: 'delete'
    });
  }

  public channelJoinPutById(id: number): BaseResourceApi<Match> {
    return new BaseResourceApi<Match>(this.config, {
      uri: `/matches/${id}/channel/join`,
      resourceClass: Match,
      method: 'put'
    });
  }

  public sendMatchRequest(): BaseResourceApi<[MatchV2]> {
    return new BaseResourceApi<[MatchV2]>(this.config, {
      uri: `/matches/matchRequest`,
      method: 'put'
    });
  }

  public acceptMatchRequest(): BaseResourceApi<[MatchV2]> {
    return new BaseResourceApi<[MatchV2]>(this.config, {
      uri: `/matches/acceptMatchRequest`,
      method: 'put'
    });
  }

  public rejectMatchRequest(): BaseResourceApi<[MatchV2]> {
    return new BaseResourceApi<[MatchV2]>(this.config, {
      uri: `/matches/rejectMatchRequest`,
      method: 'delete'
    });
  }
  public pullackMatchRequest(): BaseResourceApi<[MatchV2]> {
    return new BaseResourceApi<[MatchV2]>(this.config, {
      uri: `/matches/pullMatchRequest`,
      method: 'delete'
    });
  }

  public checkIfRequestIsAlreadySend(): BaseGetResourceApi<[MatchV2]> {
    return new BaseGetResourceApi<[MatchV2]>(this.config, {
      uri: '/matches/checkIfRequestIsAlreadySend'
    });
  }


}


class NotificationChannels extends ApiCollectionBase {

  public get(): BaseGetResourceApi<NotificationChannel> {
    return new BaseGetResourceApi<NotificationChannel>(this.config, {
      resourceClass: NotificationChannel,
      uri: `/notification_channels`
    });
  }

  public getById(id: number): BaseResourceApi<NotificationChannel> {
    return new BaseResourceApi<NotificationChannel>(this.config, {
      uri: `/notification_channels/${id}`,
      resourceClass: NotificationChannel
    });
  }

}

class NotificationSubscriptions extends ApiCollectionBase {

  public get(): BaseGetResourceApi<NotificationSubscription> {
    return new BaseGetResourceApi<NotificationSubscription>(this.config, {
      resourceClass: NotificationSubscription,
      uri: '/notification_subscriptions'
    });
  }

  public getById(id: number): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: `/notification_subscriptions/${id}`,
      resourceClass: NotificationSubscription
    });
  }

  public post(): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: '/notification_subscriptions',
      method: 'post',
      resourceClass: NotificationSubscription
    });
  }

  public putById(id: number): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: `/notification_subscriptions/${id}`,
      method: 'put',
      resourceClass: NotificationSubscription
    });
  }

  public put(): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: '/notification_subscriptions',
      method: 'put',
      resourceClass: NotificationSubscription
    });
  }

  public patchById(id: number): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: `/notification_subscriptions/${id}`,
      method: 'patch',
      resourceClass: NotificationSubscription
    });
  }

  public patch(): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: '/notification_subscriptions',
      method: 'patch',
      resourceClass: NotificationSubscription
    });
  }

  public deleteById(id: number): BaseResourceApi<NotificationSubscription> {
    return new BaseResourceApi<NotificationSubscription>(this.config, {
      uri: `/notification_subscriptions/${id}`,
      method: 'delete',
      resourceClass: NotificationSubscription
    });
  }

}

class NotificationTriggers extends ApiCollectionBase {

  public get(): BaseGetResourceApi<NotificationTrigger> {
    return new BaseGetResourceApi<NotificationTrigger>(this.config, {
      resourceClass: NotificationTrigger,
      uri: `/notification_triggers`
    });
  }

  public getById(id: number): BaseResourceApi<NotificationTrigger> {
    return new BaseResourceApi<NotificationTrigger>(this.config, {
      resourceClass: NotificationTrigger,
      uri: `/notification_triggers/${id}`
    });
  }

}

class Notifications extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Notification> {
    return new BaseGetResourceApi<Notification>(this.config, {
      resourceClass: Notification,
      uri: '/notifications'
    });
  }

  public getById(id: number): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      resourceClass: Notification,
      uri: `/notifications/${id}`
    });
  }

  public putById(id: number): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications/${id}`,
      resourceClass: Notification,
      method: 'put'
    });
  }

  public put(): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications`,
      resourceClass: Notification,
      method: 'put'
    });
  }

  public patchById(id: number): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications/${id}`,
      resourceClass: Notification,
      method: 'patch'
    });
  }

  public patch(): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications`,
      resourceClass: Notification,
      method: 'patch'
    });
  }

  public deleteById(id: number): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications/${id}`,
      resourceClass: Notification,
      method: 'delete'
    });
  }

  public handlePutById(id: number): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications/${id}/handle`,
      resourceClass: Notification,
      method: 'put'
    });
  }

  public markAsRead(id: number): BaseResourceApi<Notification> {
    return new BaseResourceApi<Notification>(this.config, {
      uri: `/notifications/${id}/markAsRead`,
      resourceClass: Notification,
      method: 'put'
    });
  }

  public getFeedById(feedId: string): BaseResourceApi<any> {
    return new BaseResourceApi(this.config, {
      uri: `/notifications/feed/${feedId}`
    });
  }

}

class Permissions extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Permission> {
    return new BaseGetResourceApi<Permission>(this.config, {
      uri: '/permissions',
      resourceClass: Permission
    });
  }

  public getById(id: number): BaseResourceApi<Permission> {
    return new BaseResourceApi<Permission>(this.config, {
      uri: `/permissions/${id}`,
      resourceClass: Permission
    });
  }

}

class Plans extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Plan> {
    return new BaseGetResourceApi<Plan>(this.config, {
      resourceClass: Plan,
      uri: '/plans',
    });
  }

  public getById(id: number): BaseResourceApi<Plan> {
    return new BaseResourceApi<Plan>(this.config, {
      uri: `/plans/${id}`,
      resourceClass: Plan
    });
  }

  public subscribePutById(id: number): BaseResourceApi<Subscription> {
    return new BaseResourceApi<Subscription>(this.config, {
      uri: `/plans/${id}/subscribe`,
      method: 'put',
      resourceClass: Subscription
    });
  }

}

class Preferences extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Preference> {
    return new BaseGetResourceApi<Preference>(this.config, {
      resourceClass: Preference,
      uri: `/preferences`
    });
  }

  public getById(id: number): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences/${id}`,
      resourceClass: Preference
    });
  }

  public post(): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences`,
      method: 'post',
      resourceClass: Preference
    });
  }

  public putById(id: number): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences/${id}`,
      method: 'put',
      resourceClass: Preference
    });
  }

  public put(): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences`,
      method: 'put',
      resourceClass: Preference
    });
  }

  public patchById(id: number): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences/${id}`,
      method: 'patch',
      resourceClass: Preference
    });
  }

  public patch(): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences`,
      method: 'patch',
      resourceClass: Preference
    });
  }

  public deleteById(id: number): BaseResourceApi<Preference> {
    return new BaseResourceApi<Preference>(this.config, {
      uri: `/preferences/${id}`,
      method: 'delete',
      resourceClass: Preference
    });
  }

}

class Search extends ApiCollectionBase {

  public usersPost(): BaseResourceApi<UserSearchMatch[]> {
    return new BaseResourceApi<UserSearchMatch[]>(this.config, {
      method: 'post',
      uri: `/search/users`,
    });
  }

  public groupsPost(): BaseResourceApi<GroupSearchMatch[]> {
    return new BaseResourceApi<GroupSearchMatch[]>(this.config, {
      method: 'post',
      uri: `/search/groups`,
    });
  }

  public eventsPost(): BaseResourceApi<EventSearchMatch[]> {
    return new BaseResourceApi<EventSearchMatch[]>(this.config, {
      method: 'post',
      uri: `/search/events`,
    });
  }

}

class Systems extends ApiCollectionBase {

  public get(): BaseGetResourceApi<System> {
    return new BaseGetResourceApi<System>(this.config, {
      uri: '/systems',
      resourceClass: System
    });
  }

  public getById(id: number): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems/${id}`,
      resourceClass: System
    });
  }

  public post(): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems`,
      resourceClass: System,
      method: 'post'
    });
  }

  public putById(id: number): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems/${id}`,
      resourceClass: System,
      method: 'put'
    });
  }

  public put(): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems`,
      resourceClass: System,
      method: 'put'
    });
  }

  public patchById(id: number): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems/${id}`,
      resourceClass: System,
      method: 'patch'
    });
  }

  public patch(): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems`,
      resourceClass: System,
      method: 'patch'
    });
  }

  public deleteById(id: number): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems/${id}`,
      resourceClass: System,
      method: 'delete'
    });
  }

  public getGetById(id: number): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: '/systems',
      resourceClass: System
    });
  }

  public calendarsGet(): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems/calendars`,
      resourceClass: System
    });
  }

  public calendarsPut(): BaseResourceApi<System> {
    return new BaseResourceApi<System>(this.config, {
      uri: `/systems/calendars`,
      summary: 'Body: {"id": "martin@4spaces.dk"}',
      resourceClass: System,
      method: 'put'
    });
  }

}

class Searchprofiles extends ApiCollectionBase {

  public patch(): BaseResourceApi<Searchprofile> {
    return new BaseResourceApi<Searchprofile>(this.config, {
      uri: '/discover/searchprofiles/update',
      resourceClass: Searchprofile,
      method: 'patch'
    });
  }

  public getById(id: number): BaseResourceApi<Searchprofile> {
    return new BaseResourceApi<Searchprofile>(this.config, {
      resourceClass: Searchprofile,
      uri: `/searchprofiles/${id}`
    });
  }
}

class Tags extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Tag> {
    return new BaseGetResourceApi<Tag>(this.config, {
      uri: '/tags',
      resourceClass: Tag
    });
  }

  public getById(id: number): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags/${id}`,
      resourceClass: Tag
    });
  }

  public post(): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags`,
      resourceClass: Tag,
      method: 'post'
    });
  }

  public putById(id: number): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags/${id}`,
      resourceClass: Tag,
      method: 'put'
    });
  }

  public put(): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags`,
      resourceClass: Tag,
      method: 'put'
    });
  }

  public patchById(id: number): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags/${id}`,
      resourceClass: Tag,
      method: 'patch'
    });
  }


  public patch(): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags`,
      resourceClass: Tag,
      method: 'patch'
    });
  }

  public deleteById(id: number): BaseResourceApi<Tag> {
    return new BaseResourceApi<Tag>(this.config, {
      uri: `/tags/${id}`,
      resourceClass: Tag,
      method: 'delete'
    });
  }

}

class MatchingUsers extends ApiCollectionBase {

  public getMatchesByInterests(): BaseGetResourceApi<User> {
    return new BaseGetResourceApi<User>(this.config, {
      uri: '/discover/likeminded/by_interests',
      resourceClass: User
    });
  }

  public getMatchesByDistance(): BaseGetResourceApi<User> {
    return new BaseGetResourceApi<User>(this.config, {
      uri: '/discover/likeminded/by_distance',
      resourceClass: User
    });
  }
}

class MatchingGroups extends ApiCollectionBase {

  public getGroupsByInterests(): BaseGetResourceApi<Group> {
    return new BaseGetResourceApi<Group>(this.config, {
      resourceClass: Group,
      uri: '/discover/groups/by_interests'
    });
  }

  public getGroupsByDistance(): BaseGetResourceApi<Group> {
    return new BaseGetResourceApi<Group>(this.config, {
      resourceClass: Group,
      uri: '/discover/groups/by_distance'
    });
  }

  public getGroupByDistanceNoFilter(): BaseGetResourceApi<Group> {
    return new BaseGetResourceApi<Group>(this.config, {
      resourceClass: Group,
      uri: '/discover/groups/by_distance/nofilter'
    });
  }
}

class MatchingEvents extends ApiCollectionBase {

  public getMatchingEvents(): BaseGetResourceApi<Event> {
    return new BaseGetResourceApi<Event>(this.config, {
      uri: `/discover/events`,
      resourceClass: Event
    });
  }
}

class Strapi extends ApiCollectionBase {

  public updateMe(): BaseResourceApi<any> {
    return new BaseResourceApi(this.config, {
      uri: '/strapi/me',
      method: 'put'
    })
  }

  public interests() {
    return new BaseResourceApi<Interest[]>(this.config, {
      uri: '/strapi/interests'
    })
  }

  public upload(type: FileUploadType) {
    return new BaseResourceApi<any>(this.config, {
      uri: `/strapi/upload/${type}`,
      method: 'post'
    })
  }

  public deleteById(id: string) {
    return new BaseResourceApi<any>(this.config, {
      uri: `/strapi/users/${id}`,
      method: 'delete'
    })
  }

}




