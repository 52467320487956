import { NgModule } from '@angular/core';
import { AuthModule as Auth0Module } from '@auth0/auth0-angular';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './services/authentication.service';
import {environment} from "../environments/environment";

// https://onthecode.co.uk/angular-logging-made-easy-with-ngx-logger/

// @ts-ignore
// @ts-ignore
@NgModule({
  declarations: [
  ],
  exports: [],
  imports: [
    Auth0Module.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
    }),
  ],
  providers: [
    ApiService,
    AuthenticationService
  ],
  bootstrap: [],
  entryComponents: [
  ],
})
export class AuthModule {
}
