/**
 * Created by ModelParser
 */
import { Category, CategoryInterface } from '../Category';
import { Event, EventInterface } from '../Event';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface CategoriesEventDefinitionInterface {
    category_id?: number;
    category?: CategoryInterface;
    event_id?: number;
    event?: EventInterface;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class CategoriesEventDefinition extends BaseStoredModel implements CategoriesEventDefinitionInterface {
    category_id?: number;
    category?: Category;
    event_id?: number;
    event?: Event;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.category_id;
            delete this.category;
            delete this.event_id;
            delete this.event;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.category_id != null) {
            this.category_id = data.category_id;
        }
        if (data.category != null) {
            this.category = new Category(data.category);
        }
        if (data.event_id != null) {
            this.event_id = data.event_id;
        }
        if (data.event != null) {
            this.event = new Event(data.event);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
