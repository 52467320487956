/**
 * Created by ModelParser
 */
import { Channel, ChannelInterface } from '../Channel';
import { User, UserInterface } from '../User';
import { MessageRecipient, MessageRecipientInterface } from '../MessageRecipient';
import { BaseStoredModel } from '../BaseStoredModel';

export interface MessageDefinitionInterface {
    channel_id?: number;
    channel?: ChannelInterface;
    sender_id?: number;
    sender?: UserInterface;
    date?: string;
    body?: string;
    message_recipients?: MessageRecipientInterface[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class MessageDefinition extends BaseStoredModel implements MessageDefinitionInterface {
    channel_id?: number;
    channel?: Channel;
    sender_id?: number;
    sender?: User;
    date?: string;
    body?: string;
    message_recipients?: MessageRecipient[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.channel_id;
            delete this.channel;
            delete this.sender_id;
            delete this.sender;
            delete this.date;
            delete this.body;
            delete this.message_recipients;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.channel_id != null) {
            this.channel_id = data.channel_id;
        }
        if (data.channel != null) {
            this.channel = new Channel(data.channel);
        }
        if (data.sender_id != null) {
            this.sender_id = data.sender_id;
        }
        if (data.sender != null) {
            this.sender = new User(data.sender);
        }
        if (data.date != null) {
            this.date = data.date;
        }
        if (data.body != null) {
            this.body = data.body;
        }
        if (data.message_recipients != null) {
            this.message_recipients = data.message_recipients.map((i: any) => new MessageRecipient(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
