import {Component, forwardRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Interest, User} from '../../../../core/models';
import {LatLng} from '../../../../services/google.service';
import {SidenavComponent} from "../../../../components/organisms/sidenav/sidenav.component";
import {BehaviorSubject, Observable} from "rxjs";
import {RangeSliderComponent} from "../../../../components/instantsearch/range-slider/range-slider.component";
import {UsersService} from "../../../../services/users.service";
import {
  AlgoliaTagSearchComponent
} from "../../../../components/organisms/algolia-tag-search/algolia-tag-search.component";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface UserHit {
  objectID: string;
  tagIds: number[];
  _geoloc: LatLng;
  first_name: string;
  last_name: string;
  gender: string;
}

export type AlgoliaIndexName = 'members' | 'groups';

export interface Filter {
  birthdate?: RangeSliderComponent,
  childrenBirthdates?: RangeSliderComponent
  user?: User;
}

@Component({
  selector: 'app-filter', templateUrl: './filter.component.html', styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  protected triggerTimeout;

  private filterSubject: BehaviorSubject<Filter>;

  triggerUpdate(obj?: Filter) {
    if (this.triggerTimeout) {
      clearTimeout(this.triggerTimeout);
    }
    this.triggerTimeout = setTimeout(() => {
      this.filterSubject.next({...this.currentFilter, ...(obj || {})});
    }, 150)
  }

  get filter(): Observable<Filter> {
    return this.filterSubject.asObservable();
  }
  get currentFilter(): Filter {
    return this.filterSubject.getValue()
  }

  @Input() user: User;
  @ViewChild('birthdateFilter', {static: true}) birthdateFilterComponent;
  @ViewChild('childrenFilter', {static: true}) childrenFilterComponent;


  @Input() defaultInterests: Interest[]
  get interests(): Interest[] {
    return this._interests;
  }

  @Input() set interests(value: Interest[]) {
    this._interests = value;
    this.updateTagsOptions();
  }

  tagsOptions;

  private _interests: Interest[]

  constructor(
    @Inject(forwardRef(() => SidenavComponent)) public sidenav: SidenavComponent,
    private usersService: UsersService,
    protected snackbar: MatSnackBar
    ) {
  }

  ngOnInit(): void {
    this.filterSubject = new BehaviorSubject<Filter>({
      user: this.user,
      birthdate: this.birthdateFilterComponent,
      childrenBirthdates: this.childrenFilterComponent
    })
  }

  tagsUpdated(tagNames, tagSearch) {
    const oldTags = this.user.tags.map(d => d?.attributes?.label)
    if (!tagNames || tagNames.length <= 0) {
      tagSearch.setValues(oldTags);
      this.snackbar.open('Min. 1 interesse er altid påkrævet')
      return;
    }
    if (JSON.stringify(oldTags) === JSON.stringify(tagNames)) {
      return;
    }
    const interestsByLabels = this.interests.reduce((p,c) => {
      p[c.attributes.label] = c;
      return p;
    }, {});
    this.user.tags = tagNames.map(d => interestsByLabels[d]);
    this.usersService.saveUser(this.user);
    this.triggerUpdate({user: this.user});
  }

  get tagLabels(): string[] {
    return this.user?.tags.map(d => d?.attributes?.label);
  }

  updateTagsOptions() {
    this.tagsOptions = this.interests.reduce((previousValue, currentValue) => {
      previousValue[currentValue.id] = currentValue.attributes.label;
      return previousValue;
    }, {})
  }


  search(target: EventTarget, tagSearch: AlgoliaTagSearchComponent) {
    tagSearch.searchForItems((target as any).value)
  }

  get rangeFilterValue() {
    const localValue = localStorage.getItem('search-distance');
    if (localValue) {
      return parseInt(localValue);
    }
    return 60 * 1000;
  }

  get rangeFilterOptions() {
    const max = 100000
    return {
      floor: 1000,
      ceil: max,
      step: 1000,
      hideLimitLabels: true,
      translate: (value2) => {
        if(value2 === max) {
          return Math.ceil(value2 / 1000).toFixed(0) + '+ km.';
        }
        //todo spørg om måneder vs mdr
        return Math.ceil(value2 / 1000).toFixed(0) + ' km.';
      },
    }
  }

  rangeSliderChange($event: number) {
    localStorage.setItem('search-distance', $event.toFixed(0));
    this.triggerUpdate({user: this.user});
  }
}
