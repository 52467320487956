/**
 * Created by ModelParser
 * Date: 19-11-2019.
 * Time: 11:43.
 */
import { PreferenceDefinition, PreferenceDefinitionInterface } from './definitions/PreferenceDefinition';

export interface PreferenceInterface extends PreferenceDefinitionInterface {

}

export class Preference extends PreferenceDefinition implements PreferenceInterface {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault(
        type?: string,
        lowValue?: number,
        highValue?: number): Preference {
        const item = new Preference();
        item.id = 0;
        item.low_value = lowValue;
        item.high_value = highValue;
        item.type = type;
        // item.gender // TODO:
        return item;
    }

}
