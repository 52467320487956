<ng-container *ngIf="inputChange$|async"></ng-container>
<form class="w-full" [formGroup]='usersForm'>
    <mat-form-field appearance="outline" class="w-full ">
        <input matInput placeholder="Adressesøgning" [matAutocomplete]="auto" formControlName='userInput' type="search" >

    </mat-form-field>
    <mat-error *ngIf="error" class="mb-2 small">
        Vælg venligst en adresse med vejnavn, nummer og by
    </mat-error>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <ng-container *ngIf="inputChange$|async as items">
            <mat-option *ngFor="let item of items" [value]="item" class="w-full flex flex-row" (onSelectionChange)="select(item)">
                <span>{{item.forslagstekst}}</span>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</form>

