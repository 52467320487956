import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StrapiImage } from '../core/models/definitions/UserDefinition';
import { ApiService } from './api.service';

export type FileUploadType = 'profile_image'|'group_banner';
export type StrapiImageFormats = 'thumbnail'|'medium'|'large'|'small';

@Injectable({
  providedIn: 'root',
})
export class StrapiService {

  constructor(
    private apiService: ApiService
  ) {
  }

  getStrapiImageSrc(image: StrapiImage, format: StrapiImageFormats = 'thumbnail') {
    const imageAttributes = image?.attributes;
    let imageUrl = format ? imageAttributes?.formats?.[format]?.url : null;

    if (!imageUrl) {
      imageUrl = imageAttributes?.url;
    }
    if (!imageUrl) {
      return undefined;
    }
    if (imageUrl.indexOf('data:image') === 0) {
      return imageUrl;
    }
    if (imageUrl.indexOf('http://') === 0 || imageUrl.indexOf('https://') === 0) {
      return imageUrl;
    }
    return `${environment.strapiUrl}${imageUrl}`
  }

  async uploadFile(type: FileUploadType, file: File): Promise<number> {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    const result = await this.apiService.api.strapi().upload(type).executePromise(formData as any);
    return result[0][0]?.id;
  }
}
