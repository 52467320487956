import { ApiCollectionBase } from '../ApiCollectionBase';
import { BaseGetResourceApi } from '../BaseGetResourceApi';
import { FamobaProduct } from '../../../models/definitions/SubscriptionDefinition';

export class ApiSubscriptions extends ApiCollectionBase {

  public get(): BaseGetResourceApi<[FamobaProduct[]]> {
    return new BaseGetResourceApi<[FamobaProduct[]]>(this.config, {
      uri: '/subscriptions',
    });
  }

}
