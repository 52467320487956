/**
 * Created by ModelParser
 * Date: 23-06-2020.
 * Time: 08:08.
 */
import { PlanDefinition, PlanDefinitionInterface } from './definitions/PlanDefinition';

export interface PlanInterface extends PlanDefinitionInterface {

}

export class Plan extends PlanDefinition implements PlanInterface {

    constructor(json?: any) {
        super(json);
    }

}
