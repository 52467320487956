/**
 * Created by ModelParser
 * Date: 11-06-2020.
 * Time: 12:26.
 */
import {
  NotificationDataDefinition, NotificationDataDefinitionInterface,
} from './definitions/NotificationDataDefinition';

export interface NotificationDataInterface extends NotificationDataDefinitionInterface {

}

export class NotificationData extends NotificationDataDefinition implements NotificationDataInterface {

    constructor(json?: any) {
        super(json);
    }

}
