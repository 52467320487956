import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, take} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {StoredCacheObservable} from "../core/stored-cache-observable";
import {ApiService} from "./api.service";
import {Activity} from "../pages/my-page/components/my-page-notifications/my-page-notifications.component";
import {User} from "../core/models";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificationEmitters: {[feed: string]: BehaviorSubject<void>} = {};
  private feedList: BehaviorSubject<Activity[]> = new BehaviorSubject([]);
  private countFeed: BehaviorSubject<number> = new BehaviorSubject(0);
  private isLoadedFeed = new BehaviorSubject<boolean>(false);
  private activityReaded: BehaviorSubject<Activity[]> = new BehaviorSubject([]);

  constructor(
    private apiService: ApiService
  ) {
    (window as any).test = this;
  }

  getFeedFromAPI(feed: string) {
    return new StoredCacheObservable({
      localStorageKey: `notifications-${feed}`,
      observable: this.apiService.api.notifications().getFeedById(feed).execute().pipe(
        map(d => d[0])
      )
    })
  }

  getUserFeedId(user: User): string {
    if (!user?.strapi_user_id) {
      return undefined;
    }
    return 'user:' + user.strapi_user_id
  }

  reloadUserFeed(user: User) {
    this.reloadFeed(this.getUserFeedId(user));
  }

  protected reloadFeed(feed: string) {
    if (!this.notificationEmitters[feed]) {
      return;
    }
    this.notificationEmitters[feed].next();
  }


  getFeed(feed: string): Observable<Activity[]> {
    if (!this.notificationEmitters[feed]) {
      this.notificationEmitters[feed] = new BehaviorSubject<void>(undefined)
    }
    return this.notificationEmitters[feed].pipe(
      switchMap(() => this.getFeedFromAPI(feed)),
    ) as any;
  }
  //get
  getFeedList(): Observable<Activity[]> {
    return this.feedList.asObservable();
  }

  getCountFeed(): Observable<number> {
    return this.countFeed.asObservable();
  }

  getIsLoadedFeed(): Observable<boolean> {
    return this.isLoadedFeed.asObservable();
  }
  //handle
  updateFeedList(res: any) {
    this.feedList.next(res);
  }

  updateFeed(feedList: Activity[]){
    //check and set count and readed
    const activityReaded = this.activityReaded.getValue()
    if(activityReaded.length > 0 && feedList.length > 0){
      feedList.forEach(function (value, index) {
        if(activityReaded.filter((activity) => activity.id === value.id).length > 0){
          feedList[index].read = true
        }
      });
    }
    const count = feedList.filter((feed) => !feed?.read).length;
    this.countFeed.next(count);
    //set viewlist
    this.feedList.next(feedList);
    if(!this.isLoadedFeed.getValue()){
      this.isLoadedFeed.next(true);
    }
  }
  reduceCountFeed(activity: Activity){
    const count = this.countFeed.getValue();
    if(count > 0){
      this.countFeed.next(count - 1);
    }
    this.activityReaded.next(this.activityReaded.getValue().concat([activity]));
  }
}
