/**
 * Created by ModelParser
 * Date: 13-09-2019.
 * Time: 11:05.
 */
import { CategoriesEventDefinition, CategoriesEventDefinitionInterface } from './definitions/CategoriesEventDefinition';

export interface CategoriesEventInterface extends CategoriesEventDefinitionInterface {

}

export class CategoriesEvent extends CategoriesEventDefinition implements CategoriesEventInterface {

    constructor(json?: any) {
        super(json);
    }

}
