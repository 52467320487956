import { ApiCollectionBase } from '../ApiCollectionBase';
import { BaseGetResourceApi } from '../BaseGetResourceApi';
import { Category } from '../../../models';
import { BaseResourceApi } from '../BaseResourceApi';

export class ApiCategories extends ApiCollectionBase {

  public get(): BaseGetResourceApi<Category> {
    return new BaseGetResourceApi<Category>(this.config, {
      uri: `/categories`,
      resourceClass: Category
    });
  }

  public getById(id: number): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      uri: `/categories/${id}`,
      resourceClass: Category
    });
  }

  public post(): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      uri: `/categories`,
      resourceClass: Category,
      method: 'post'
    });
  }

  public putById(id: number): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      uri: `/categories/${id}`,
      method: 'put',
      resourceClass: Category
    });
  }

  public put(): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      uri: `/categories`,
      method: 'put',
      resourceClass: Category
    });
  }

  public patchById(id: number): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      method: 'patch',
      uri: `/categories/${id}`,
      resourceClass: Category
    });
  }

  public patch(): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      method: 'patch',
      uri: `/categories`,
      resourceClass: Category
    });
  }

  public deleteById(id: number): BaseResourceApi<Category> {
    return new BaseResourceApi<Category>(this.config, {
      method: 'delete',
      uri: `/categories/${id}`,
      resourceClass: Category,
    });
  }

}
