    /**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */

    import { SearchprofileDefinition, SearchprofileDefinitionInterface } from './definitions/SearchprofileDefinition';
    import { Interest } from './Interest';

    export interface SearchprofileInterface extends SearchprofileDefinitionInterface {

 }

 export class Searchprofile extends SearchprofileDefinition implements SearchprofileInterface {

     constructor(json?: any) {
         super(json);
     }

     public static createDefault(): Searchprofile {
        const item = new Searchprofile();
        item.min_parent_age_in_years = 15;
        item.max_parent_age_in_years = 99;
        item.min_child_age_in_months = -36;
        item.max_child_age_in_months = 36;
        item.min_interest_count = 0;
        item.parent_age_active = true;
        item.max_distance = 76;
        item.max_distance_active = true;
        item.user_group = 1;
        item.interests = [new Interest()];

        return item;
    }

 }
