import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Interest } from '../../../core/models';

@Component({
  selector: 'app-matched-card',
  templateUrl: './matched-card.component.html',
  styleUrls: ['./matched-card.component.scss']
})
export class MatchedCardComponent {
  @Input() componentName: string;
  @Input() tagsMatched: Interest[];
  @Input() tagsNoMatched: Interest[];
  @Input() isShowMatched: boolean;
  @Output() toggleShowMatched = new EventEmitter<boolean>();

  emitToggleShowMatched() {
    this.toggleShowMatched.emit();
  }
}
