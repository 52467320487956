import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { User } from '../../../../core/models';

@Component({
  selector: 'app-friend-request-card',
  templateUrl: './friend-request-card.component.html',
  styleUrls: ['./friend-request-card.component.scss'],
})
export class FriendRequestCardComponent implements OnInit {
  @Input() user: User
  @Output() emitFilterUsers = new EventEmitter<any>();
  constructor(
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
  }

  async rejectFriendRequest(userReceive: User) {
    await this.apiService.api.matches()
      .pullackMatchRequest()
      .addQueryParameter({'matchId': parseInt(userReceive?.strapi_user_id)})
      .executePromise();
    this.filterUsers()
  }

  filterUsers(){
    this.emitFilterUsers.emit()
  }

}
