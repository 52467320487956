import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { User } from '../../../core/models';
import { UsersService } from '../../../services/users.service';

type ImageSize = 'big' | 'small' | 'medium'

@Component({
  selector: 'app-user-simple-card',
  templateUrl: './user-simple-card.component.html',
  styleUrls: ['./user-simple-card.component.scss'],
})
export class UserSimpleCardComponent implements OnInit {
  @Input() user: User
  @HostBinding('class') class;
  @Input() imageSize: ImageSize = 'big';

  protected _imageSize;

  get imageSizeClass() {
    return this._imageSize;
  }

  constructor(protected userService: UsersService) { }

  ngOnInit(): void {}
  ngOnChanges(): void {

    if (this.imageSize === 'big') {
      this._imageSize = 'w-[123px] h-[123px]';
    } else if (this.imageSize === 'medium') {
      this._imageSize = 'w-[97px] h-[97px]';
    } else {
      this._imageSize = 'w-16 h-16';
    }
  }

}
