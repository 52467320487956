import {Component, ElementRef, Inject, OnDestroy, OnInit, TrackByFunction, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {TwilioService} from "../../../services/twilio.service";
import {Client, Conversation, Message, Paginator, Participant} from "@twilio/conversations";
import {UsersService} from "../../../services/users.service";
import {distinct, from, Observable, switchMap} from "rxjs";
import {User} from "../../../core/models";
import {tap} from "rxjs/operators";

@Component({
  selector: 'app-single-chat-dialog',
  templateUrl: './single-chat-dialog.component.html',
  styleUrls: ['./single-chat-dialog.component.scss']
})
export class SingleChatDialogComponent implements OnInit, OnDestroy {

  user$: Observable<User|undefined>

  messages: Message[] = [];
  conversation$: Observable<Conversation>;
  currentConversation: Conversation;
  client: Client;
  prevInput: string;
  initialized = false;
  lastMessageResult: Paginator<Message>;
  userObservables: {[author: string]: Observable<User>} = {};
  events: {[event: string]: { obj: any, listener: any }} = {};

  @ViewChild('messageInput') messageInput: ElementRef<HTMLTextAreaElement>;

  typing: Participant|undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public conversationSID: Observable<{ sid?: string, uniqueName?: string }>,
    private twilioService: TwilioService,
    private usersService: UsersService,
    private dialog: MatDialog
  ) { }

  getConversation(): Observable<Conversation> {
    return this.conversationSID.pipe(
      distinct(e => e.sid ?? e.uniqueName),
      switchMap(({uniqueName, sid}) => {
        if (sid) {
          return from(this.client.getConversationBySid(sid))
        } else if (uniqueName) {
          return from(this.client.getConversationByUniqueName(uniqueName))
        } else {
          throw new Error('Invalid options')
        }
      }),
      tap(conversation => this.initConversation(conversation))
    )
  }

  protected listen(obj, event, listener) {
    const existingEvent = this.events[event]
    if (existingEvent) {
      existingEvent.obj.off(event, existingEvent.listener);
    }
    this.events[event] = {obj, listener} as any;
    return obj.on(event, listener);
  }

  async initConversation(conversation: Conversation) {
    this.resetConversation();
    this.currentConversation = conversation;
    this.messages = [];
    const previousMessages = await conversation.getMessages(20);
    this.lastMessageResult = previousMessages;
    this.listen(conversation, 'messageAdded', this.onMessageAdded(conversation));

    this.listen(conversation, 'typingStarted', participant => this.typing = participant);
    this.listen(conversation, 'typingEnded', participant => this.typing = undefined);

    this.addMessage(previousMessages.items);
    this.user$ = this.twilioService.getUserByConversationUniqueName(conversation);
  }

  protected onMessageAdded(conversation: Conversation) {
    return (message: Message) => {
      conversation.setAllMessagesRead();
      this.addMessage([message]);
    }
  }

  protected resetConversation() {
    if (!this.currentConversation) {
      return;
    }
    this.messages = [];
    const events = Object.keys(this.events);
    for (const event of events) {
      const existingEvent = this.events[event];
      existingEvent.obj.off(event, existingEvent.listener);
      delete this.events[event];
    }
    console.log('RESET CONVERSATION');
  }

  async ngOnInit() {
    this.client = await this.twilioService.getClient();
    this.conversation$ = this.getConversation();
  }

  ngOnDestroy() {
    console.log('destroy');
    this.resetConversation();
  }

  async loadPrevMessage() {
    if (!this.lastMessageResult) {
      return;
    }
    this.lastMessageResult = await this.lastMessageResult.prevPage()
    this.addMessage(this.lastMessageResult.items);
  }

  getUserObservable(author: string): Observable<User> {
    if (!this.userObservables[author]) {
      this.userObservables[author] = this.usersService.getUserById(parseInt(author.split(':')[1]));
    }
    return this.userObservables[author];
  }

  updateUserObservables() {
    const authors = this.messages.reduce((previousValue, currentValue) => {
      if (previousValue.indexOf(currentValue.author) === -1) {
        previousValue.push(currentValue.author);
      }
      return previousValue;
    }, []);

    for (const author of authors) {
      if (this.userObservables[author]) {
        continue;
      }
      this.userObservables[author] = this.usersService.getUserById(author.split(':')[1])
    }
  }

  addMessage(messages: Message[]) {
    this.messages.unshift(...messages);
    this.messages = this.messages.sort(this.sortMessages());
    this.updateUserObservables();
  }

  sortMessages() {
    return (a: Message, b: Message) => {
      return b.dateCreated.getTime() - a.dateCreated.getTime();
    }
  }

  tracker(): TrackByFunction<Message> {
    return (index, item) => {
      return item.sid;
    }
  }

  isAuthorSelf(message: Message): boolean {
    const strapiUserId = this.usersService.me().strapi_user_id;
    const uid =  `uid:${strapiUserId}`;
    return message.author === uid;
  }

  triggerTyping(conversation: Conversation, event: KeyboardEvent) {
    if (event.key == 'Enter' && !event.shiftKey && !event.ctrlKey) {
      event.preventDefault();
      this.submit(conversation)
    }
    const target = event.target as HTMLInputElement;
    const newVal = target.value.trim();
    const diffLength = newVal.length - (this.prevInput?.length ?? 0);
    this.prevInput = newVal;
    if (diffLength > 0) {
      conversation?.typing();
    }
  }

  resizeMessageField() {
    const textarea = this.messageInput.nativeElement;
    const style = this.messageInput.nativeElement.style;
    style.height = '';
    style.height = Math.min(textarea.scrollHeight - 4, 67) + 'px';
  }

  submit(conversation: Conversation, event?: SubmitEvent, ) {
    event?.preventDefault();
    const input = this.messageInput.nativeElement;
    const value = input.value.trim();
    if (!(value.length > 0)) {
      return;
    }
    input.value = '';
    this.resizeMessageField();
    conversation.sendMessage(value);
  }

  openProfile(user: User) {
    this.dialog.closeAll();
    this.usersService.openDefaultProfileDialog({user})
  }

}
