import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs'

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: string, args?: [dayjs.OptionType]): string {
    const date = dayjs(value, args?.[0]);
    const today = dayjs();

    const yearDiff = today.diff(date, 'year', true);
    if (yearDiff < 1) {
      return today.diff(date, 'month') + ' mdr.';
    } else if (yearDiff < 3) {
      return (Math.floor(yearDiff * 2) / 2).toString().replace('.', ',') + ' år';
    }
    return Math.floor(yearDiff) + ' år';
  }

}
