import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { UsersService } from '../../../services/users.service';
import { User } from '../../../core/models';

@Component({
  selector: 'app-signup-name-form',
  templateUrl: './signup-name-form.component.html',
  styleUrls: ['./signup-name-form.component.scss']
})
export class SignupNameFormComponent implements OnInit {

  formGroup: FormGroup;

  @Input() user: User;
  @ViewChild('checkbox') public checkInput;

  constructor(
    private apiService: ApiService,
    private usersService: UsersService
  ) {
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      email: new FormControl(this.user?.email, [Validators.required]),
      first_name: new FormControl(this.user?.first_name, [Validators.required]),
      last_name: new FormControl(this.user?.last_name, [Validators.required]),
      newsletter: new FormControl(this.user?.newsletter),
    })
  }

  async saveValues() {
    await this.apiService.api.strapi().updateMe().executePromise(this.formGroup.value);
    this.usersService.reloadUser();
  }

}
