/**
 * Created by ModelParser
 * Date: 13-09-2019.
 * Time: 11:05.
 */
import { TagDefinition, TagDefinitionInterface } from './definitions/TagDefinition';

export interface TagInterface extends TagDefinitionInterface {

}

export class Tag extends TagDefinition implements TagInterface {

    constructor(json?: any) {
        super(json);
    }

    static createDefault(): Tag {
        const tag: Tag = new Tag();
        tag.id = 0;
        return tag;
    }


}
