import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyPageTopComponent } from './components/my-page-top/my-page-top.component';
import { MyPageComponent } from './my-page.component';
import { RouterModule } from '@angular/router';
import { AtomicModule } from '../../components/atomic.module';
import { MyPageSubscriptionComponent } from './components/my-page-subscription/my-page-subscription.component';
import { MyPageLikeMindedComponent } from './components/my-page-like-minded/my-page-like-minded.component';
import { MyPageNotificationsComponent } from './components/my-page-notifications/my-page-notifications.component';
import { MyPageGroupsComponent } from './components/my-page-groups/my-page-groups.component';
import { MyPageMatchesComponent } from './components/my-page-matches/my-page-matches.component';
import { MyPageSupportComponent } from './components/my-page-support/my-page-support.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgAisConfigureModule, NgAisHitsModule, NgAisInstantSearchModule} from "angular-instantsearch";
import { PipesModule } from './../../core/pipes/pipes.module';

@NgModule({
  declarations: [
    MyPageTopComponent,
    MyPageComponent,
    MyPageSubscriptionComponent,
    MyPageLikeMindedComponent,
    MyPageNotificationsComponent,
    MyPageGroupsComponent,
    MyPageSupportComponent,
    MyPageMatchesComponent
  ],
  exports: [
    MyPageComponent
  ], imports: [
    CommonModule,
    RouterModule,
    AtomicModule,
    MatProgressSpinnerModule,
    NgAisInstantSearchModule,
    NgAisHitsModule,
    NgAisConfigureModule,
    PipesModule
  ]
})
export class MyPageModule { }
