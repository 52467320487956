/**
 * Created by ModelParser
 */
import { Event, EventInterface } from '../Event';
import { User, UserInterface } from '../User';
import { Group } from '../Group';
import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';
import { GroupAttributes } from './GroupDefinition';

export interface FileDefinitionInterface {
    type?: string;
    path?: string;
    name?: string;
    event_id?: string;
    event?: EventInterface;
    user_images?: UserInterface[];
    group_images?: StrapiModel<GroupAttributes>[];
    content?: string;
    file_name?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class FileDefinition extends BaseStoredModel implements FileDefinitionInterface {
    type?: string;
    path?: string;
    name?: string;
    event_id?: string;
    event?: Event;
    user_images?: User[];
    group_images?: Group[];
    content?: string;
    file_name?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.type;
            delete this.path;
            delete this.name;
            delete this.event_id;
            delete this.event;
            delete this.user_images;
            delete this.group_images;
            delete this.content;
            delete this.file_name;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.path != null) {
            this.path = data.path;
        }
        if (data.name != null) {
            this.name = data.name;
        }
        if (data.event_id != null) {
            this.event_id = data.event_id;
        }
        if (data.event != null) {
            this.event = new Event(data.event);
        }
        if (data.user_images != null) {
            this.user_images = data.user_images.map((i: any) => new User(i));
        }
        if (data.group_images != null) {
            this.group_images = data.group_images;
        }
        if (data.content != null) {
            this.content = data.content;
        }
        if (data.file_name != null) {
            this.file_name = data.file_name;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
