import {
  AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TrackByFunction,
} from '@angular/core';
import { ChildDefinitionAttributes } from '../../../core/models/definitions/ChildDefinition';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StrapiModel } from '../../../core/models/StrapiModel';

export interface ChildrenChangeEvent {
  children: StrapiModel<ChildDefinitionAttributes>[];
  valid: boolean;
  touched: boolean;
}

@Component({
  selector: 'app-edit-children-form',
  templateUrl: './edit-children-form.component.html',
  styleUrls: ['./edit-children-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditChildrenFormComponent implements OnInit, AfterViewInit {

  formArray: FormArray;
  valueChange$: Observable<any>;
  isInitialized = false;
  lastEmit: StrapiModel<ChildDefinitionAttributes>[];
  originalEmit: StrapiModel<ChildDefinitionAttributes>[];

  @Input() children: StrapiModel<ChildDefinitionAttributes>[];
  @Input() showIcon = false;
  @Input() addChildPosition: 'bottom'|'top' = 'bottom';
  @Input() isAccessComponent:boolean = true;
  @Output() childrenChange: EventEmitter<ChildrenChangeEvent> = new EventEmitter<ChildrenChangeEvent>();

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  get isValid() {
    if (!this.formArray.valid) {
      return false;
    }

    const emptyControls = this.formArray.controls.find(d => Object.keys((d as FormGroup).controls).length === 0);
    return !emptyControls;
  }
  protected hasChange(oldData: StrapiModel<ChildDefinitionAttributes>[], newData: StrapiModel<ChildDefinitionAttributes>[]) {
    return JSON.stringify(oldData) !== JSON.stringify(newData);
  }

  hasChangeFromOriginal(): boolean {
    return this.hasChange(this.lastEmit, this.originalEmit);
  }

  ngOnInit(): void {
    this.formArray = this.formBuilder.array(this.children?.map(d => {
      return this.formBuilder.group({});
    }) ?? []);
    if (!this.children || this.children.length === 0) {
      this.addChild();
    }
    this.valueChange$ = this.formArray.valueChanges.pipe(
      tap(d => {
        const oldEmit = this.lastEmit;
        const newEmit = this.formValuesToChildren(d);
        this.lastEmit = newEmit
        const hasChange = this.hasChange(oldEmit, newEmit);
        if (!this.isInitialized) {
          return;
        }
        return this.childrenChange.emit({children: this.formValuesToChildren(d), valid: this.isValid, touched: this.hasChangeFromOriginal()});
      })
    )
  }

  ngAfterViewInit() {
    this.isInitialized = true;
    this.originalEmit = this.lastEmit;
  }

  protected formValuesToChildren(values: {id: string, name: string, gender: string, birthdate: string}[]): StrapiModel<ChildDefinitionAttributes>[] {
    return values.map((d, i) => {
      const child = {...(this.children[i] ?? {attributes: {}})};
      delete d.id;
      child.attributes = {...child.attributes, ...d};
      return child as any;
    })
  }

  get groups(): { group: FormGroup, child: StrapiModel<ChildDefinitionAttributes> }[] {
    return this.children?.map((child, i) => {
      let group = this.formArray.at(i) as FormGroup;
      if (!group) {
        group = this.formBuilder.group({});
        this.formArray.push(group);
      }

      return {group, child}
    })
  }


  get trackBy(): TrackByFunction<any> {
    return (index) => {
      return index;
    }
  }


  addChild() {
    if (!this.children) {
      this.children = [];
    }
    this.children?.push({} as any);
    this.formArray.push(this.formBuilder.group({}));
  }

  removeChild(index: number) {
    this.children.splice(index, 1);
    this.formArray.removeAt(index);
  }

}
