// `.env.ts` is generated by the `npm run env` command

import { NgxLoggerLevel } from 'ngx-logger';
import { versions } from './versions';

export const environment = {
  production: true,
  version: versions.version,
  environment: 'Production',
  strapiUrl: 'https://strapi.famoba.dk',
  apiUrl: 'https://api.app.famoba.dk',
  apiPath: '',
  defaultLanguage: 'da-DK',
  supportedLanguages: ['da-DK'],
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.OFF,
  sentryDSN: "https://5d4cbceed6234b7483ca175bbc4e2064@o1138475.ingest.sentry.io/6194266",
  auth0: {
    domain: 'auth.famoba.dk',
    clientId: 'dNsTDz38jhxuEnQkjmAdo6BzFNsITYma'
  },
  twilioRegion: 'us1',
  algolia: {
    appId: '68GGBP2ZQX',
    apiKey: 'ec12d75f1897e732beb91d72e3da10bf'
  },
  tawkURL: 'https://embed.tawk.to/627534957b967b11798e1ba7/1g2ctaqd2'
};
