import { Injectable } from '@angular/core';

export interface LatLng {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  /**
   * Returns distance between two coordinates in meters
   * @param a
   * @param b
   */
  computeDistanceBetween(a: LatLng, b: LatLng): number {
    return this.google.maps.geometry.spherical.computeDistanceBetween(a, b);
  }

  get google() {
    return (window as any).google;
  }

}
