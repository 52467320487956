import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';

export interface ChildDefinitionAttributes {
  name?: string;
  birthdate?: string;
  user_id?: number;
  gender?: string;
  active_child?: boolean;
  created?: string;
  updated?: string;
}

export interface ChildDefinitionInterface {
  id?: number;
  attributes?: ChildDefinitionAttributes;
}

export class ChildDefinition extends BaseStoredModel implements StrapiModel<ChildDefinitionAttributes> {
  id: number;
  attributes: ChildDefinitionAttributes;

  constructor(data?: any) {
    super();
    this.populate(data);
  }

  public populate(data?: any, patch: boolean = false) {
    if (!patch) {
      delete this.id;
      delete this.attributes;
    }

    if (!data) return;

    if (data.id != null) {
      this.id = data.id;
    }

    if (data.attributes != null) {
      this.attributes = data.attributes;
    }
  }
}
