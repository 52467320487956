
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { UploadService } from '../../../services/upload.service';
 
@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit {
  @Output() getPicture = new EventEmitter<WebcamImage>();
  @Output() getFile = new EventEmitter<File>();
  isCameraExist = true;
 
  errors: WebcamInitError[] = [];
 
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
 
  constructor(protected uploadService: UploadService) {}

  get isTakeImage(): boolean {
    return this.uploadService.isTakeImage;
  }

  get isTookImage(): boolean {
    return this.uploadService.isTookImage;
  }
  
  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.isCameraExist = mediaDevices && mediaDevices.length > 0;
      });
  }
 
  takeSnapshot(): void {
    this.trigger.next();
  }

  reTakeSnapshot(): void {
    this.uploadService.setIsTookImage(false);
  }

  changeToUploadImage(): void {
    this.uploadService.setIsTakeImage(false);
  }
 
 
  handleInitError(error: WebcamInitError) {
    this.errors.push(error);
  }
 
 
  handleImage(webcamImage: WebcamImage) {
    const fileName = this.makeRandomString() + '-' + Date.now();
    const arr = webcamImage.imageAsDataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], fileName, { type: 'image/png' })

    this.getFile.emit(file);
    this.getPicture.emit(webcamImage);
    this.uploadService.setIsTookImage(true);
  }

   makeRandomString() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }
 
  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
 
  get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
}