import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInjector } from './app-injector.service';
import { AlertDialogComponent } from '../shared/dialogs/alert-dialog/alert-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    // https://stackoverflow.com/questions/52394349/emit-event-to-parent-component-from-modal-in-ng-bootstrap

    closeEvent: EventEmitter<any> = new EventEmitter();
    public dialog: MatDialog;


    constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: AlertDialogComponent) {
        this.dialog = AppInjector.getInstance().getInjector().get(MatDialog);
    }

    emit(data) {
        this.closeEvent.emit(data);
    }

    getCloseEvent() {
        return this.closeEvent;
    }

    alert(event: HttpErrorResponse) {
        // dialogRef
        this.dialog.open(AlertDialogComponent, {
            data: {
                error: event,
            },
            width: '800px',
            height: 'fit-content'
        });

    }
}
