import { BaseResourceApi } from './BaseResourceApi';

export class BaseGetResourceApi<T> extends BaseResourceApi<T> {

  protected scope = '';
  protected summary = '';

  protected convertToResource(data: any): T {
    const classObject = this.resourceClass;
    if (!classObject) {
      return data;
    }
    return new classObject(data);
  }

  public where(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().where(name, value);
    return this;
  }

  public whereEquals(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().whereEquals(name, value);
    return this;
  }

  public whereIn(name: string, value: any[]): BaseGetResourceApi<T> {
    this.filter().whereIn(name, value);
    return this;
  }

  public whereInArray(name: string, value: any[]): BaseGetResourceApi<T> {
    this.filter().whereInArray(name, value);
    return this;
  }

  public whereNot(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().whereNot(name, value);
    return this;
  }

  public whereNotIn(name: string, value: any[]): BaseGetResourceApi<T> {
    this.filter().whereNotIn(name, value);
    return this;
  }

  public whereGreaterThan(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().whereGreaterThan(name, value);
    return this;
  }

  public whereGreaterThanOrEqual(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().whereGreaterThanOrEqual(name, value);
    return this;
  }

  public whereLessThan(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().whereLessThan(name, value);
    return this;
  }

  public whereLessThanOrEqual(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().whereLessThanOrEqual(name, value);
    return this;
  }

  public search(name: string, value: any): BaseGetResourceApi<T> {
    this.filter().search(name, value);
    return this;
  }

  public orderBy(name: string, direction: string): BaseGetResourceApi<T> {
    this.ordering().orderBy(name, direction);
    return this;
  }

  public orderAsc(name: string): BaseGetResourceApi<T> {
    this.ordering().orderAsc(name);
    return this;
  }

  public orderDesc(name: string): BaseGetResourceApi<T> {
    this.ordering().orderDesc(name);
    return this;
  }

  public limit(value: number): BaseGetResourceApi<T> {
    this.limitValue = value;
    return this;
  }

  public offset(value: number): BaseGetResourceApi<T> {
    this.offsetValue = value;
    return this;
  }

}
