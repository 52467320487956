/**
 * Created by ModelParser
 * Date: 15-07-2019.
 * Time: 07:57.
 */
import {
  StrapiUser,
  UserDefinition,
  UserDefinitionInterface,
} from './definitions/UserDefinition';
import { Child } from './Child';
import Constants, { UserTypes } from '../constants';
import { UserStar } from './UserStar';
import { PhoneNumber } from './PhoneNumber';
import { Searchprofile } from './Searchprofile';
import { Preference } from './index';
import { environment } from '../../../environments/environment';

export interface UserInterface extends UserDefinitionInterface {}

export class User extends UserDefinition implements UserInterface {
    public password: string;
    public current_password: string;
    public age: number;
    public user_stars: UserStar[];

    constructor(json?: Partial<UserInterface>) {
        super(json);
        if (!this.interests) {
            this.interests = [];
        }
        if (!this.group_members) {
            this.group_members = [];
        }
        if (!this.user_stars) {
            this.user_stars = [];
        }
    }

    public static fromStrapiModel(strapiModel: StrapiUser): User {
      const user = new User(strapiModel?.attributes);
      user.id = strapiModel?.id;
      user.strapi_user_id = strapiModel?.id?.toString();
      return user;
    }

    public static toStrapiModel(user: User): StrapiUser {
      return {
        id: user.id,
        attributes: user
      }
    }

    public toStrapiModel(): StrapiUser {
      return User.toStrapiModel(this);
    }

    public static createDefault(userTypeId: number = UserTypes.Member) {
        const item = new User();
        item.id = 0;
        //item.user_type_id = userTypeId;
        item.address = {};
        item.phone_number = PhoneNumber.createDefault();
        item.interests = [];
        item.preferences = this.createPreferences();
        item.gender = 'mom';
        item.children = [new Child()];
        if (!item.children[0].attributes) {
          item.children[0].attributes = {};
        }
        item.children[0].attributes.active_child = true;
        item.children[0].attributes.gender = '0';
        item.searchprofile = Searchprofile.createDefault();

        console.log('User.createDefault() : ', item);

        return item;
    }

    public static createPreferences() {
        return [
            Preference.createDefault(Constants.PreferenceTypes.Distance, 10),
            Preference.createDefault(Constants.PreferenceTypes.AgeGroup, 18, 40),
            Preference.createDefault(Constants.PreferenceTypes.ChildBirthday),
        ];
    }

    get fullname() {
        if (this.first_name && this.last_name) {
            return `${this.first_name} ${this.last_name}`;
        }
        if (this.first_name) {
            return this.first_name;
        }
        if (this.last_name) {
            return this.last_name;
        }
        return '';
    }

    get developer() {
        // return false;
        return (this.id === 1 || this.id === 2) && !environment.production;
    }

    preference(type: string): Preference {
        return this.preferences ? this.preferences.find((value) => value.type === type) : null;
    }
}
