import { Component, HostBinding, Input, OnInit } from '@angular/core';

export type ChipStyle = 'white'|'cyan';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @HostBinding('class') class: string;

  @Input() style: ChipStyle = 'cyan';
  @Input() size: 'large' | 'medium'|'small' = 'small'
  private _outline: boolean = false;


  get outline(): boolean {
    return this._outline;
  }

  @Input()
  set outline(value: boolean) {
    this._outline = value;
    this.class = this.getClassList();
  }

  constructor() { }

  ngOnInit(): void {
    this.class = this.getClassList();
  }

  getClassList() {
    let classList = ['cursor-pointer rounded-full border-solid border-1 px-3 py-1.5 justify-center text-center'];

    classList.push(this.size === 'large' ? 'text-base px-6 py-2 grow' : 'text-xs')

    classList.push(this.size === 'medium' ? 'text-sm' : 'text-xs')

    if (!this._outline) {
      classList.push('border-cyan bg-cyan')
    }
    if (this.style === 'white') {
      classList.push('text-white border-white')
    } else if (this.style === 'cyan') {
      classList.push('border-cyan text-black');
    }
    return classList.join(' ');
  }

}
