import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Group, Interest, User } from '../core/models';
import { ApiService } from './api.service';

export class Tooltip {

  sharedInterests: Interest[];
  uniqueInterests: Interest[];

}

export interface ExploreInterface {
  me: User,
  interests: Interest[],
  interestUsers: { userData: User[], tooltips: Tooltip[] }
  distanceUsers: { userData: User[], tooltips: Tooltip[] },
  interestGroups: { groupData: Group[], tooltips: Tooltip[] },
  distanceGroups: { groupData: Group[], tooltips: Tooltip[] },
  highlightedGroup: Group,
  highlightedGroupTooltip: Tooltip,
  friendRequestSend: boolean,
}

@Injectable()

export class ExploreService {

  private explore: BehaviorSubject<ExploreInterface>;
  public explore$: Observable<ExploreInterface>;
  public dialog: MatDialog;

  public exploreData: ExploreInterface = {
    me: new User(),
    interests: [],
    interestUsers: {userData: [], tooltips: []},
    distanceUsers: {userData: [], tooltips: []},
    interestGroups: {groupData: [], tooltips: []},
    distanceGroups: {groupData: [], tooltips: []},
    highlightedGroup: new Group(),
    highlightedGroupTooltip: new Tooltip(),
    friendRequestSend: false

  };

  constructor(
    private apiService: ApiService
  ) {
    this.explore = new BehaviorSubject(this.exploreData) as BehaviorSubject<ExploreInterface>;
    this.explore$ = this.explore.asObservable();
    //this.loadInitialData();
  }

  updateData() {

    this.getGroupsByDistance();
    this.getGroupsByInterests();
    this.getMatchesByDistance();
    this.getMatchesByInterests();

  }


  patchSearchprofile(activeInterests, maxDistance, maxNegativeChildAgeDiff, maxPositiveChildAgeDiff, minParentAge, maxParentAge, userGroup): void {

    const API = this.apiService.api.searchprofile();

    API.patch()
      .addQueryParameter({'interests': activeInterests.join(', ')})
      .addQueryParameter({'max_distance': maxDistance > 75 ? 1000 : maxDistance})
      .addQueryParameter({'min_child_age_in_months': maxNegativeChildAgeDiff})
      .addQueryParameter({'max_child_age_in_months': maxPositiveChildAgeDiff})
      .addQueryParameter({'min_parent_age_in_years': minParentAge})
      .addQueryParameter({'max_parent_age_in_years': maxParentAge})
      .addQueryParameter({'user_group': userGroup})
      .execute().subscribe(value => {
        this.updateData();
      });
    this.explore.next(this.exploreData);
  }

  getMe() {
  }

  updateActiveChild(index: number) {

    const API = this.apiService.api.users();

    API.patchActiveChild().addQueryParameter({'child_id': this.exploreData.me.children[index].id}).execute().subscribe(value => {
      this.updateData();
    })

    //Updates data (checkboxes) in frontend
    this.exploreData.me.children = this.exploreData.me.children;
    this.explore.next(this.exploreData);
  }

  getAllInterests(): void {

    this.apiService.api.interests()
      .get()
      .find().subscribe((items: any) => {
      this.exploreData.interests = items;
    });

    this.explore.next(this.exploreData);
  }

  getGroupsByDistance(): void {

    const API = this.apiService.api.matchingGroups().getGroupsByDistance();

    API.include("image");

    API.find().subscribe((items) => {
      this.exploreData.distanceGroups.groupData = items as any;
      this.exploreData.distanceGroups.tooltips = this.generateTooltipsData(items);
      console.log("testests", this.exploreData.distanceGroups)
    });

    this.explore.next(this.exploreData);
  }

  getGroupsByInterests(): void {

    const API = this.apiService.api.matchingGroups().getGroupsByInterests();

    API.include("image");

    API.find().subscribe((items) => {

      this.exploreData.interestGroups.groupData = items as any;
      this.exploreData.interestGroups.tooltips = this.generateTooltipsData(items);

      if (this.exploreData.interestGroups.groupData.length > 0) {
        this.exploreData.highlightedGroup = this.exploreData.interestGroups.groupData.shift();
        this.exploreData.highlightedGroupTooltip = this.exploreData.interestGroups.tooltips.shift();
      }


    });

    this.explore.next(this.exploreData);
  }

  getMatchesByDistance(): void {


    const API = this.apiService.api.matchingUsers().getMatchesByDistance();

    API.include("image");

    API.find().subscribe((items) => {

      this.exploreData.distanceUsers.userData = items as any;
      this.exploreData.distanceUsers.tooltips = this.generateTooltipsData(items);

    });

    this.explore.next(this.exploreData);

  }

  getMatchesByInterests(): void {

    const API = this.apiService.api.matchingUsers().getMatchesByInterests();

    API.include("image");

    API.find().subscribe((items: any) => {
      this.exploreData.interestUsers.userData = items;
      this.exploreData.interestUsers.tooltips = this.generateTooltipsData(items);

    });

    this.explore.next(this.exploreData);

  }

  generateTooltipsData(items) {

    var tooltips: Tooltip[] = [];

    items.forEach(item => {

      var tempTooltip = new Tooltip();
      tempTooltip.sharedInterests = this.generateSharedInterests(item).slice(0, 3);
      tempTooltip.uniqueInterests = this.generateDifferentiatingInterests(item).slice(0, 2);
      tooltips.push(tempTooltip);

    });

    return tooltips;
  }

  generateSharedInterests(item) {

    if (typeof this.exploreData.me.searchprofile === 'undefined') {
      return undefined;
    }

    return this.exploreData.me.searchprofile.interests.filter(n => item.interests.some(n2 => n.id == n2.id));
  }

  generateDifferentiatingInterests(item) {

    if (typeof item.interests === 'undefined') {
      return
    }

    return item.interests.filter(n => !this.exploreData.me.searchprofile.interests.some(n2 => n.id == n2.id));
  }

  sendFriendRequest(matchId: number) {
    this.apiService.api.matches()
      .sendMatchRequest()
      .addQueryParameter({'matchId': matchId})
      .execute(null).subscribe(value => {
      });

    this.explore.next(this.exploreData);
  }

  acceptFriendRequest(matchId: number) {
    this.apiService.api.matches()
      .acceptMatchRequest()
      .addQueryParameter({'matchId': matchId})
      .execute(null).subscribe( value => {
      });

    this.explore.next(this.exploreData);
  }

  rejectFriendRequest(matchId: number) {

    this.apiService.api.matches()
      .rejectMatchRequest()
      .addQueryParameter({'matchId': matchId})
      .execute().subscribe();

    this.explore.next(this.exploreData);
  }

  checkFriendRequestStatus(matchId: number) {

    this.apiService.api.matches()
      .checkIfRequestIsAlreadySend()
      .addQueryParameter({'matchId': matchId})
      .find().subscribe((value: any) => {
      this.exploreData.friendRequestSend = value.length > 0;
    });

    this.explore.next(this.exploreData);

  }


}


