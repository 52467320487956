/**
 * Created by ModelParser
 * Date: 16-07-2019.
 * Time: 11:07.
 */
import { SystemDefinition, SystemDefinitionInterface } from './definitions/SystemDefinition';

export interface SystemInterface extends SystemDefinitionInterface {

}

export class System extends SystemDefinition implements SystemInterface {

    constructor(json?: any) {
        super(json);
    }

}
