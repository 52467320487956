import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatIconRegistry } from '@angular/material/icon';
import {ApiService, ObservableCache} from "./services/api.service";
import {UsersService} from "./services/users.service";
import {User} from "./core/models";
import {TawkService} from './services/tawk.service'
import {tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {environment} from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  user$: Observable<User|undefined>;

  constructor(
    public matIconRegistry: MatIconRegistry,
    private swUpdate: SwUpdate,
    private TawkService: TawkService,
    protected apiService: ApiService,
    protected usersService: UsersService
  ) {
    this.user$ = this.usersService.meAsync().pipe(
      tap(user => {
        this.TawkService.initTawk(user)
      })
    );

    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    matIconRegistry.registerFontClassAlias('fontawesome', 'fal');
    matIconRegistry.registerFontClassAlias('fontawesome', 'fab');

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  get isStage() {
    return environment.environment === 'Stage';
  }

}
