/**
 * Created by ModelParser
 */
import { NotificationChannel, NotificationChannelInterface } from '../NotificationChannel';
import { NotificationTrigger, NotificationTriggerInterface } from '../NotificationTrigger';
import { User, UserInterface } from '../User';
import { Notification, NotificationInterface } from '../Notification';
import { BaseStoredModel } from '../BaseStoredModel';

export interface NotificationSubscriptionDefinitionInterface {
  notification_channel_id?: number;
  notification_channel?: NotificationChannelInterface;
  notification_trigger_id?: number;
  notification_trigger?: NotificationTriggerInterface;
  user_id?: number;
  user?: UserInterface;
  notifications?: NotificationInterface[];
  id?: number;
  created?: string;
  updated?: string;
  created_by_id?: number;
  created_by?: UserInterface;
  updated_by_id?: number;
  updated_by?: UserInterface;
}

export class NotificationSubscriptionDefinition extends BaseStoredModel implements NotificationSubscriptionDefinitionInterface {
  notification_channel_id?: number;
  notification_channel?: NotificationChannel;
  notification_trigger_id?: number;
  notification_trigger?: NotificationTrigger;
  user_id?: number;
  user?: User;
  notifications?: Notification[];
  id?: number;
  created?: string;
  updated?: string;
  created_by_id?: number;
  created_by?: User;
  updated_by_id?: number;
  updated_by?: User;

  constructor(data?: any) {
    super();
    this.populate(data);
  }

  public populate(data?: any, patch: boolean = false) {
    if (!patch) {
      delete this.notification_channel_id;
      delete this.notification_channel;
      delete this.notification_trigger_id;
      delete this.notification_trigger;
      delete this.user_id;
      delete this.user;
      delete this.notifications;
      delete this.id;
      delete this.created;
      delete this.updated;
      delete this.created_by_id;
      delete this.created_by;
      delete this.updated_by_id;
      delete this.updated_by;
    }

    if (!data) return;
    if (data.notification_channel_id != null) {
      this.notification_channel_id = data.notification_channel_id;
    }
    if (data.notification_channel != null) {
      this.notification_channel = new NotificationChannel(data.notification_channel);
    }
    if (data.notification_trigger_id != null) {
      this.notification_trigger_id = data.notification_trigger_id;
    }
    if (data.notification_trigger != null) {
      this.notification_trigger = new NotificationTrigger(data.notification_trigger);
    }
    if (data.user_id != null) {
      this.user_id = data.user_id;
    }
    if (data.user != null) {
      this.user = new User(data.user);
    }
    if (data.notifications != null) {
      this.notifications = data.notifications.map((i: any) => new Notification(i));
    }
    if (data.id != null) {
      this.id = data.id;
    }
    if (data.created != null) {
      this.created = data.created;
    }
    if (data.updated != null) {
      this.updated = data.updated;
    }
    if (data.created_by_id != null) {
      this.created_by_id = data.created_by_id;
    }
    if (data.created_by != null) {
      this.created_by = new User(data.created_by);
    }
    if (data.updated_by_id != null) {
      this.updated_by_id = data.updated_by_id;
    }
    if (data.updated_by != null) {
      this.updated_by = new User(data.updated_by);
    }
  }

}
