<ng-container *ngIf="group">
  <app-image-link
      [componentName]="componentName"
      [tagsMatched]="tagsMatched"
      [tagsNoMatched]="tagsNoMatched"
      class="h-44 w-full"
      [group]="group"
      [backgroundImageURL]="strapiService.getStrapiImageSrc(group?.attributes?.banner_image, 'small')"
  ></app-image-link>
  <div class="mt-2 mb-4">
    <p class="text-sm text-dgray mb-2">{{membersCount}} {{ membersCount == 1 ? 'medlem' : 'medlemmer'}}</p>
    <p class="text-sm">{{group?.attributes?.name}}</p>
    <ng-container *ngIf="group?.attributes?.addressInfo">
      <p class="text-sm">{{group?.attributes?.addressInfo['city']}}</p>
    </ng-container>
  </div>
</ng-container>
