<app-user-profile-icon class="hidden md:block h-24 w-24 my-4" [user]="user"></app-user-profile-icon>
<span class="text-white block text-lg md:text-sm mt-10 md:mt-0">Din søgeprofil</span>
<div class="w-full pt-6 pb-3">
  <p class="text-left text-white text-xs font-bold mb-2">Søg</p>
  <form>
    <div class="relative w-full">
      <app-icon class="h-[16.5px] w-[16.5px] absolute z-10 pin-r pin-t mt-[0.5rem] ml-2 mr-4" color="white" icon="search"></app-icon>
      <input
        class="text-white bg-blue border border-white rounded w-full h-[33px] pl-8"
        placeholder=""
        (keyup)="search($event.currentTarget, tagSearch)"
      >
    </div>
  </form>
</div>
<app-filter-container
  class="py-3.5"
  [label]="'Interesser'"
  [active]="true"
>
  <app-algolia-tag-search
    #tagSearch
    [initialSearchValue]="tagLabels"
    [attribute]="'tags.label'"
    [indexName]="'members'"
    (change)="tagsUpdated($event, tagSearch)"
  ></app-algolia-tag-search>
</app-filter-container>
<app-filter-container
  class="py-3.5 distance-slider"
  label="Afstand"
  [active]="false"
>
  <ngx-slider
    [value]="rangeFilterValue"
    [options]="rangeFilterOptions"
    (valueChange)="rangeSliderChange($event)"
  >
  </ngx-slider>
</app-filter-container>
<app-filter-container
  class="py-3.5 distance-slider"
  label="Forældres alder"
  [active]="false"
>
  <app-range-slider
    #birthdateFilter
    attribute="birthdate"
    [min]="18"
    [max]="60"
    [step]="1"
    (filterChange)="triggerUpdate({birthdate: birthdateFilter})"
  ></app-range-slider>
</app-filter-container>
<app-filter-container
  class="py-3.5 distance-slider mb-24"
  label="Børns alder"
  [active]="false"
>
  <app-range-slider
    #childrenFilter
    attribute="childrenBirthdates"
    [min]="0"
    [max]="6"
    [step]="0.5"
    (filterChange)="triggerUpdate({childrenBirthdates: childrenFilter})"
  ></app-range-slider>
</app-filter-container>
<button class="my-8 md:hidden" (click)="sidenav.toggleMobileMenu()">Anvend</button>
