<a
  [routerLink]="disabledText ? undefined : href"
  class="bg-cyan w-full h-full flex justify-center items-center rounded bg-cover bg-center relative cursor-pointer hover:text-black"
  [class.no-background]="!backgroundImageURL"
  [class.link-disabled]="disabledText"
  [ngStyle]="{'background-image': backgroundImageURL ? 'url(\'' + backgroundImageURL + '\')' : undefined}"
>
  <app-matched-card
    [componentName]="componentName"
    [tagsMatched]="tagsMatched"
    [tagsNoMatched]="tagsNoMatched"
    [isShowMatched]="isShowMatched"
    (toggleShowMatched)="toggleShowMatched()"
  >
  </app-matched-card>
    <ng-container *ngIf="!isShowMatched">
      <app-icon *ngIf="!backgroundImageURL && !disabledText" [icon]="'logo-famoba'"
        class="duration-200 absolute z-10 fill-green stroke-0 w-[22px] h-6"></app-icon>
      <app-icon *ngIf="backgroundImageURL && !disabledText" [icon]="'arrow'" 
        class="duration-200 opacity-0 absolute fill-black w-7 h-[21px]"></app-icon>
      <div *ngIf="backgroundImageURL || disabledText"
        class="background-filter absolute opacity-0 inset-0 bg-white duration-200"
      ></div>
    </ng-container>
    <p class="m-0 text-blue font-semibold absolute top-2.5 right-2.5 bg-white rounded uppercase px-4 py-2 text-xs z-10" *ngIf="label">{{label}}</p>
    <p class="m-0 font-semibold absolute uppercase px-4 py-2 z-10 text-lg" *ngIf="disabledText">{{disabledText}}</p>
</a>
