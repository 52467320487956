<mat-icon mat-dialog-close="close" class="dialog-close-icon z-[1000]">close</mat-icon>
<h3 class="w-full text-center pt-4 md:pt-0">{{data.title ?? 'Profil'}}</h3>
<ng-container *ngIf="buttons$|async as result; else loading">
  <ng-container *ngIf="isShowDropdown">
    <div class="absolute top-2 right-16">
      <mat-icon class="cursor-pointer !w-12 !h-8 text-5xl	text-[#919191]" [matMenuTriggerFor]="menu">more_horiz
      </mat-icon>
      <mat-menu #menu="matMenu" xPosition="before">
        <div mat-menu-item class="cursor-pointer" (click)="deleteMatch(result.user)">
          <mat-icon class="text-sm !w-3.5 !h-5 text-[#919191]">cancel</mat-icon>
          <span>Fjern ven</span>
        </div>
      </mat-menu>
    </div>
  </ng-container>
  <app-user-detailed 
    class="pt-8 md:pt-14 pb-2 md:pb-0 max-w-[600px] mx-auto" 
    [user]="result.user"
    [class.pb-2]="result.buttons.length > 0"
  ></app-user-detailed>
  <ng-container *ngIf="!isShowDropdown">
    <div *ngIf="result.buttons.length > 0" class="flex justify-center pt-[30px] md:pt-9 pb-1.5 md:pb-0 -mx-6 md:-mx-2.5 shadow-[0_-10px_20px_-5px_rgba(0,0,0,0.13)] md:shadow-none">
      <button *ngFor="let button of result.buttons" [disabled]="button.disabled || buttonClicked"
        [class]="'min-w-[120px] w-auto !px-[25px] !py-0 h-[44px] mx-2.5 ' + button.className" (click)="button.click && buttonClick(button)">{{button.title}}</button>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="px-12 flex justify-center items-center flex-col">
    <div class="mx-auto w-32 h-32">
      <app-spinner></app-spinner>
    </div>
  </div>
</ng-template>
