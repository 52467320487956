/**
 * Created by ModelParser
 */
import { Event, EventInterface } from '../Event';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface AttendeeDefinitionInterface {
    type?: string;
    event_id?: number;
    event?: EventInterface;
    user_id?: number;
    user?: UserInterface;
    status?: number;
    payment_link?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class AttendeeDefinition extends BaseStoredModel implements AttendeeDefinitionInterface {
    type?: string;
    event_id?: number;
    event?: Event;
    user_id?: number;
    user?: User;
    status?: number;
    payment_link?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.type;
            delete this.event_id;
            delete this.event;
            delete this.user_id;
            delete this.user;
            delete this.status;
            delete this.payment_link;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.event_id != null) {
            this.event_id = data.event_id;
        }
        if (data.event != null) {
            this.event = new Event(data.event);
        }
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.status != null) {
            this.status = data.status;
        }
        if (data.payment_link != null) {
            this.payment_link = data.payment_link;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
