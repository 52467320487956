import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
  host: {
      class: 'flex h-full w-full justify-center items-center bg-center bg-no-repeat bg-contain bg-[url("/assets/images/spinners/barnevogn_orange.png")]'
  }
})
export class SpinnerComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
