import {Component, forwardRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTabGroup} from "@angular/material/tabs";
import {ExploreComponent} from "../../explore.component";
import {UsersService} from "../../../../services/users.service";
import {User} from "../../../../core/models";
import {Router} from "@angular/router";

@Component({
  selector: 'app-explore-content',
  templateUrl: './explore-content.component.html',
  styleUrls: ['./explore-content.component.scss']
})
export class ExploreContentComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;


  constructor(
    @Inject(forwardRef(() => ExploreComponent))
    public explore: ExploreComponent,
    protected usersService: UsersService,
    private router: Router
    ) {
  }

  changeIndex(index) {
    this.explore.changeIndex(index);
  }

  ngOnInit(): void {
  }

  get selectedIndex() {
    return this.explore.selectedIndex;
  }

  get activeSubscription() {
    return this.usersService.hasUserFeature(this.me, 'friends');
  }

  get me(): User {
    return this.usersService.me();
  }

  createGroup() {
     if(this.activeSubscription) {
       this.router.navigateByUrl('/groups/edit/0');
     } else {
       this.usersService.openSubscriptionContainer();
     }
  }

}
