import { Component, Input, OnInit} from '@angular/core';
import {Interest, User} from '../../../core/models';
import {UsersService} from '../../../services/users.service';
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../services/api.service";
import {GoogleService} from "../../../services/google.service";

export interface userCardProduct {
  name: string;
  address: string;
  childrenCount: number;
}

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
  host: {
    class: 'relative flex items-center bg-slgray p-8 mt-2 md:mt-0 hover:shadow-md transition duration-200 cursor-pointer rounded',
  }
})
export class UserCardComponent implements OnInit {
  genderLabel: string;
  @Input() componentName: string = 'user-card';
  @Input() tagsMatched: Interest[];
  @Input() tagsNoMatched: Interest[];
  isShowMatched:boolean = false

  get user(): User {
    return this._user;
  }

  @Input()
  set user(value: User) {
    this._user = value;
    this.genderLabel = this.userService.getGenderLabel(value.gender);
  }

  private _user: User;

  constructor(
    protected userService: UsersService,
    protected apiService: ApiService,
    protected dialog: MatDialog,
    protected googleService: GoogleService
  ) {}

  get distanceFromMe() {
    return (this.googleService.computeDistanceBetween({
      lat: this.user.address.lat,
      lng: this.user.address.long,
    },{
      lat: this.userService.me().address.lat,
      lng: this.userService.me().address.long,
    }) / 1000).toFixed(2);
  }

  get matchingTagsCount() {
    const userATagIds = this.user.tags.map(d => d.id);
    const userBTagIds = this.userService.me().tags.map(d => d.id);
    return userATagIds.filter(d => userBTagIds.indexOf(d) !== -1).length;
  }

  ngOnInit(): void {}

  toggleShowMatched(){
    if(this.isShowMatched){
      this.isShowMatched = false;
    }else{
      this.isShowMatched = true;
    }
  }

  get showDebugInfo() {
    return localStorage.getItem('show-debug') === 'true';
  }
}
