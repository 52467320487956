<ng-container *ngIf="isAccessComponent">
  <app-address-search-dawa class="w-full" (addressSelected)="selectAddress($event)"></app-address-search-dawa>
</ng-container>

<fieldset [class.disabled]="readonly">
  <mat-form-field class="w-full" appearance="outline">
    <input matInput name="street" [required]="!readonly" placeholder="Adresse" [(ngModel)]="street">
  </mat-form-field>
  <mat-form-field class="w-full" appearance="outline">
    <input matInput name="city" [required]="!readonly" placeholder="By" [(ngModel)]="city">
  </mat-form-field>
  <mat-form-field class="w-full" appearance="outline">
    <input matInput name="zip" [required]="!readonly" placeholder="Postnummer" [(ngModel)]="zip">
  </mat-form-field>
</fieldset>
