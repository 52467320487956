import {Component, Inject, OnInit} from '@angular/core';
import {User} from "../../../core/models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import dayjs from "dayjs";

@Component({
  selector: 'app-free-trial-popup',
  templateUrl: './free-trial-popup.component.html',
  styleUrls: ['./free-trial-popup.component.scss'],
  host: {
    class: 'block p-8'
  }
})
export class FreeTrialPopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: User },
    public dialogRef: MatDialogRef<FreeTrialPopupComponent>,
  ) { }

  ngOnInit(): void {
  }

  get expire(): string {
    return dayjs(this.me?.active_subscription.stripe_subscription.trial_end * 1000).toISOString()
  }

  get me() {
    return this.data.user
  }

  close() {
    this.dialogRef.close();
  }

}
