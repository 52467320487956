/**
 * Created by ModelParser
 * Date: 22-11-2019.
 * Time: 12:25.
 */
import { UserStarDefinition, UserStarDefinitionInterface } from './definitions/UserStarDefinition';

export interface UserStarInterface extends UserStarDefinitionInterface {

}

export class UserStar extends UserStarDefinition implements UserStarInterface {

    constructor(json?: any) {
        super(json);
    }

}
