/**
 * Created by ModelParser
 */
import { Notification, NotificationInterface } from '../Notification';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface EmailNotificationDefinitionInterface {
    notification_id?: number;
    notification?: NotificationInterface;
    receiver_email?: string;
    receiver_name?: string;
    subject?: string;
    body?: string;
    attachments?: string;
    from?: string;
    language?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class EmailNotificationDefinition extends BaseStoredModel implements EmailNotificationDefinitionInterface {
    notification_id?: number;
    notification?: Notification;
    receiver_email?: string;
    receiver_name?: string;
    subject?: string;
    body?: string;
    attachments?: string;
    from?: string;
    language?: string;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.notification_id;
            delete this.notification;
            delete this.receiver_email;
            delete this.receiver_name;
            delete this.subject;
            delete this.body;
            delete this.attachments;
            delete this.from;
            delete this.language;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.notification_id != null) {
            this.notification_id = data.notification_id;
        }
        if (data.notification != null) {
            this.notification = new Notification(data.notification);
        }
        if (data.receiver_email != null) {
            this.receiver_email = data.receiver_email;
        }
        if (data.receiver_name != null) {
            this.receiver_name = data.receiver_name;
        }
        if (data.subject != null) {
            this.subject = data.subject;
        }
        if (data.body != null) {
            this.body = data.body;
        }
        if (data.attachments != null) {
            this.attachments = data.attachments;
        }
        if (data.from != null) {
            this.from = data.from;
        }
        if (data.language != null) {
            this.language = data.language;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
