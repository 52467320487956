import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Event, Interest, User } from '../core/models';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from './api.service';

export interface EventInterface {
  me: User,
  interests: Interest[],
  matchingEvents: Event[],
  featuredEvent: Event,
}

@Injectable()

export class EventService {

  private event: BehaviorSubject<EventInterface>;
  public event$: Observable<EventInterface>;
  public dialog: MatDialog;

  public eventData: EventInterface = {
    me: new User(),
    interests: [],
    matchingEvents: [],
    featuredEvent: new Event(),
};

  constructor(
    protected apiService: ApiService
  ) {

      this.event = new BehaviorSubject(this.eventData) as BehaviorSubject<EventInterface>;
      this.event$ = this.event.asObservable();
      //this.loadInitialData();
   }

  loadInitialData() {

    this.getMe();
    this.getMatchingEvents();
    this.getAllInterests();

  }

  updateData(){
    this.getMe();
    this.getMatchingEvents();
  }

  getAllInterests() : void {

    this.apiService.api.interests()
        .get()
        .find().subscribe((items: any) => {
            this.eventData.interests = items;
        });

    this.event.next(this.eventData);
  }


  getMe(){
    this.apiService.api.users().meGet().include("searchprofile?include=interest").find().subscribe(items => {
      this.eventData.me = items[0];
    });

    this.event.next(this.eventData);
  }

  getMatchingEvents(keyword: string = "") {

    this.apiService.api.matchingEvents().getMatchingEvents().addQueryParameter({'keyword': keyword}).find().subscribe((value) => {
      this.eventData.matchingEvents = value as any;
      this.eventData.featuredEvent = this.eventData.matchingEvents.shift();
    });

    this.event.next(this.eventData);
  }

  patchSearchprofile(activeInterests): void {

    const API = this.apiService.api.searchprofile();

    API.patch()
        .addQueryParameter({'interests': activeInterests.join(', ')})
        .save(null).subscribe(value => {
          this.updateData();
        });

    this.event.next(this.eventData);

  }






}
