import { Component, Input, OnInit } from '@angular/core';
import {UserInterface} from "../../../core/models/User";

export interface INotification {
  id: number;
  label: string;
  user: UserInterface
  date?: string
  isRead: boolean;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  host: {
    class: 'flex items-center w-full',
  }
})
export class NotificationComponent implements OnInit {

  constructor() { }

  @Input() notification: INotification

  ngOnInit(): void {}

  isStatusNew(date: string): boolean {
    const dateStringToDate = new Date(date)
    const createdAt = dateStringToDate.getTime();
    const oneDay = 86400000;
    const oneDayAgo = Date.now() - oneDay;

    if (createdAt > oneDayAgo) {
      return true
    } else {
      return false
    }
  }
  
  isPostSeen(): boolean {
      return false
  }
}
