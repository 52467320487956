import {Component, Input, OnInit} from '@angular/core';
import {User} from "../../../../core/models";
import {UsersService} from '../../../../services/users.service';

@Component({
  selector: 'app-my-page-matches',
  templateUrl: './my-page-matches.component.html',
  styleUrls: ['./my-page-matches.component.scss']
})
export class MyPageMatchesComponent implements OnInit {

  @Input() matches: User[]

  constructor(
    private usersService: UsersService,
  ) {
  }

  ngOnInit(): void {
  }

  openDialog(user: User) {
    const userId = parseInt(user?.strapi_user_id)
    if (userId > 0) {
      this.usersService.openDefaultProfileDialog({userId})
    }
  }

  openDialogFriendRequests() {
    return this.usersService.openDialogFriendRequests();
  }
}
