import { Injectable } from '@angular/core';

export const UserTypes = {
    Administrator: 10,
    Member: 20,
};

export const GroupUserTypes = {
    Administrator: 'administrator',
    Member: 'member',
};

export const SubscriptionTypes = {
    Free: 1,
    Basis: 2,
    Plus: 3,
    Trial: 4,
};

export const GroupStatusTypes = {
    Approved: 'approved',
    Denied: 'denied',
    Pending: 'pending',
};

export const GroupMemberTypes = {
    Admin: 'admin',
    Member: 'member',
};

export const GroupStatusTypeNames = {
    'approved': 'godkendt',
    'denied': 'afvist',
    'pending': 'afventer',
};

/*
// Like minded
const CanAccessLikeMinded = 'can-access-like-minded';
const CanReadContactInfo = 'can-read-contact-info';
const CanLikeLikeMinded = 'can-like-like-minded';

// Groups
const CanAccessGroups = 'can-access-like-groups';
const CanLikeGroups = 'can-like-groups';
const CanJoinGroupA = 'can-join-group-a'; // 3 active groups
const CanJoinGroupB = 'can-join-group-b'; // 5 active groups

// Events
const CanAccessEvents = 'can-access-like-events';
const CanJoinEvent = 'can-join-event';
const CanLikeEvents = 'can-like-events';

// Global
const CanChat = 'can-chat';
 */

export const UserPermissionTypes = {
    // CanReadContactInfo: 'can-read-contact-info',
    CanAccessLikeMinded: 'can-access-like-minded',
    CanAccessGroups: 'can-access-groups',
    CanLikeGroups: 'can-like-groups',
    CanJoinGroupA: 'can-join-group-a',
    CanChat: 'can-chat',
};

export const NotificationTriggers = {
    UnreadMessages: 1,
    VideoChat: 2,
    GroupMembership: 3,
    Matches: 4,
};

export const NotificationTriggerNames = {
    1: 'Nye beskeder i din indbakke',
    2: 'Videmøde',
    3: 'Optagelse i gruppe',
    4: 'Nyt matches',
};

export const NotificationChannels = {
    Email: 1,
    // SMS: 2,
    // Push: 3,        // Firebase + Mobil
    // FrontPage: 4,   // Legacy
};

export const NotificationChannelsNames = {
    1: 'E-mail',
    2: 'SMS',
    3: 'Push (Mobil & Firebase)',
    4: 'Push (Legacy)'
};

const Scopes = {
    Administrator: 'admin',
    Customer: 'customer',
    Driver: 'driver',
    ThirdParty: 'third_party',
    Member: 'member',
};

export const FileTypes = {
    Image: 0,
    Other: 1,
};
export const FileTypesIcons = {
    0: 'fa-image',
    1: 'fa-file',
};
export const FileTypesNames = {
    0: 'Billede',
    1: 'Andet',
};


export const Tabs = {
    Basic: 'tab-basic',
    Logs: 'tab-logs',
    Debug: 'tab-debug',
    Who: 'tab-who',
    AttachTo: 'tab-attach-to',
};
export const UI = {
    ListPageSize: 10
};



export const SignupTypes = {
    Events: 'events',
    Likeminded: 'likeminded',
    Groups: 'groups',
};

export const StatusTypes = {
    NOT_DEFINED: 0,
    GREEN: 2,
    YELLOW: 3,
    RED: 4,
    GREY: 1,
    /*
    1: 'badge-secondary',
    2: 'badge-warning',
    3: 'badge-success',
    4: 'badge-danger',
    */
};

export const PreferenceTypes = {
    ChildBirthday: 'child-birthday',
    Distance: 'distance',
    AgeGroup: 'age-group',
};
export const FilterTypes = {
    ChildBirthday: 'child-birthday',
    Distance: 'distance',
    AgeGroup: 'age-group',
};

export const Icons = {
    Administrator: 'fa-user-shield',
    Company: 'fa-building',
    User: 'fa-user',
    ThirdPaty: 'fa-file-code',
    Applicant: 'fa-clipboard-list',
    Car: 'fa-car',
    Bike: 'fa-bicycle',
};

export const StatusTypesAll = [
    StatusTypes.GREEN,
    StatusTypes.YELLOW,
    StatusTypes.RED,
    StatusTypes.GREY
];


export const CONSTANTS = {
    Scopes,
    Tabs,
    Icons,
};


Injectable();

export default class Constants {
    static GroupMemberTypes = GroupMemberTypes;
    static UserTypes = UserTypes;
    static UserPermissionTypes = UserPermissionTypes;
    static GroupUserTypes = GroupUserTypes;
    static GroupStatusTypes = GroupStatusTypes;
    static SubscriptionTypes = SubscriptionTypes;
    static GroupStatusTypeNames = GroupStatusTypeNames;
    static FileTypes = FileTypes;
    static FileTypesNames = FileTypesNames;
    static Scopes = Scopes;
    static Icons = Icons;
    static UI = UI;
    static PreferenceTypes = PreferenceTypes;
    static FilterTypes = FilterTypes;
    static SignupTypes = SignupTypes;
    static NotificationTriggers = NotificationTriggers;
    static NotificationTriggerNames = NotificationTriggerNames;
    static NotificationChannels = NotificationChannels;
    static NotificationChannelsNames = NotificationChannelsNames;

    static TodayDate: Date = new Date();

    // public ItemTypes       = ItemTypes;

    /*
    static getPermissions(user_type_id: number) {
        switch (user_type_id) {
            case this.UserTypesValues.Developer:
                return [this.UserTypes.Developer];
                break;
            case this.UserTypesValues.Admin:
                return [this.UserTypes.Admin];
                break;
            case this.UserTypesValues.Partner:
                return [this.UserTypes.Partner];
                break;
            case this.UserTypesValues.Customer:
                return [this.UserTypes.Customer];
                break;

        }

        return [];
    }
    */

}
