/**
 * Created by ModelParser
 */
import { Event, EventInterface } from '../Event';
import { CategoriesEvent, CategoriesEventInterface } from '../CategoriesEvent';
import { Group } from '../Group';
import { CategoriesGroup, CategoriesGroupInterface } from '../CategoriesGroup';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';
import { StrapiModel } from '../StrapiModel';
import { GroupAttributes } from './GroupDefinition';

export interface CategoryDefinitionInterface {
  name?: string;
  events?: EventInterface[];
  categories_events?: CategoriesEventInterface[];
  groups?: StrapiModel<GroupAttributes>[];
  categories_groups?: CategoriesGroupInterface[];
  id?: number;
  created?: string;
  updated?: string;
  created_by_id?: number;
  created_by?: UserInterface;
  updated_by_id?: number;
  updated_by?: UserInterface;
}

export class CategoryDefinition extends BaseStoredModel implements CategoryDefinitionInterface {
  name?: string;
  events?: Event[];
  categories_events?: CategoriesEvent[];
  groups?: Group[];
  categories_groups?: CategoriesGroup[];
  id?: number;
  created?: string;
  updated?: string;
  created_by_id?: number;
  created_by?: User;
  updated_by_id?: number;
  updated_by?: User;

  constructor(data?: any) {
    super();
    this.populate(data);
  }

  public populate(data?: any, patch: boolean = false) {
    if (!patch) {
      delete this.name;
      delete this.events;
      delete this.categories_events;
      delete this.groups;
      delete this.categories_groups;
      delete this.id;
      delete this.created;
      delete this.updated;
      delete this.created_by_id;
      delete this.created_by;
      delete this.updated_by_id;
      delete this.updated_by;
    }

    if (!data) return;
    if (data.name != null) {
      this.name = data.name;
    }
    if (data.events != null) {
      this.events = data.events.map((i: any) => new Event(i));
    }
    if (data.categories_events != null) {
      this.categories_events = data.categories_events.map((i: any) => new CategoriesEvent(i));
    }
    if (data.groups != null) {
      this.groups = data.groups;
    }
    if (data.categories_groups != null) {
      this.categories_groups = data.categories_groups.map((i: any) => new CategoriesGroup(i));
    }
    if (data.id != null) {
      this.id = data.id;
    }
    if (data.created != null) {
      this.created = data.created;
    }
    if (data.updated != null) {
      this.updated = data.updated;
    }
    if (data.created_by_id != null) {
      this.created_by_id = data.created_by_id;
    }
    if (data.created_by != null) {
      this.created_by = new User(data.created_by);
    }
    if (data.updated_by_id != null) {
      this.updated_by_id = data.updated_by_id;
    }
    if (data.updated_by != null) {
      this.updated_by = new User(data.updated_by);
    }
  }

}
