import {ApiCollectionBase} from "../ApiCollectionBase";
import {BaseGetResourceApi} from "../BaseGetResourceApi";
import {Message} from "../../../models";
import {BaseResourceApi} from "../BaseResourceApi";


export class MessageApi extends ApiCollectionBase {

  /**
   * @deprecated
   */
  public get(): BaseGetResourceApi<Message> {
    return new BaseGetResourceApi<Message>(this.config, {
      resourceClass: Message,
      uri: '/messages'
    });
  }

  /**
   * @deprecated
   */
  public getById(id: number): BaseResourceApi<Message> {
    return new BaseResourceApi<Message>(this.config, {
      resourceClass: Message,
      uri: `/messages/${id}`
    });
  }

  /**
   * @deprecated
   */
  public readPutById(id: number): BaseResourceApi<Message> {
    return new BaseResourceApi<Message>(this.config, {
      uri: `/messages/${id}/read`,
      method: 'put',
      resourceClass: Message
    });
  }

  public sendMessageTo() {
    return new BaseResourceApi(this.config, {
      uri: '/messages/send_message_to',
      method: 'post'
    })
  }

}
