import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../../services/authentication.service';
import {Observable} from "rxjs";

export interface ApiCollectionBaseConfig {
  authenticationService: AuthenticationService,
  httpClient: HttpClient,
  headers: () => Observable<(HttpHeaders|({ [header: string]: string | string[]; }))>
}

export class ApiCollectionBase {

  constructor(
    protected config: ApiCollectionBaseConfig
  ) {
  }
}
