import { Component, OnInit } from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'app-email-already-used',
  templateUrl: './email-already-used.component.html',
  styleUrls: ['./email-already-used.component.scss'],
  host: {
    class: 'h-full flex flex-col items-center'
  }
})
export class EmailAlreadyUsedComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  signOut() {
    this.authService.logout();
  }

}
