/**
 * Created by ModelParser
 * Date: 11-06-2020.
 * Time: 12:26.
 */
import {
  NotificationSubscriptionDefinition, NotificationSubscriptionDefinitionInterface,
} from './definitions/NotificationSubscriptionDefinition';

export interface NotificationSubscriptionInterface extends NotificationSubscriptionDefinitionInterface {

}

export class NotificationSubscription extends NotificationSubscriptionDefinition implements NotificationSubscriptionInterface {

    constructor(json?: any) {
        super(json);
    }

}
