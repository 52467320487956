import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {
  FamobaProduct, StrapiSubscriptionAttributes, StripePlan, StripeRecurringInterval,
} from '../../../core/models/definitions/SubscriptionDefinition';
import {User} from '../../../core/models';
import {StripeSubscription} from '../../../core/models/definitions/UserDefinition';
import {ApiService} from "../../../services/api.service";
import {UsersService} from "../../../services/users.service";

const showdown  = require('showdown')

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {

  @HostBinding('class') hostClass: string;
  @Input() adjustHeight: boolean

  get user(): User {
    return this._user;
  }

  @Input()
  set user(value: User) {
    this._user = value;
    this.updateState();
  }
  get product(): FamobaProduct {
    return this._product;
  }

  @Input()
  set product(value: FamobaProduct) {
    this._product = value;
    this.description = this.convertMarkdown(this.strapiAttributes.description);
    this.updateState();
  }

  private _user: User;

  description: string;
  activePlanId: string;

  private _product: FamobaProduct;

  constructor(
    private apiService: ApiService,
    private usersService: UsersService
  ) { }

  get isInvited(): boolean {
    return this.usersService.getUserActiveSubscriptionSource(this.user) === 'invited';
  }

  updateState() {
    this.activePlanId = this.getActivePlanId();
    this.hostClass = this.getHostClass();
  }

  ngOnInit(): void {
  }

  get strapiAttributes(): StrapiSubscriptionAttributes {
    return this._product?.strapi?.attributes;
  }

  get plan(): StripePlan {
    return this._product?.plan;
  }

  getRecurringLabel(recurring?: StripeRecurringInterval): string {
    return 'mdn.';
  }

  convertMarkdown(markdown: string): string {
    if (!markdown) {
      return undefined;
    }
    const converter = new showdown.Converter();
    return converter.makeHtml(markdown);
  }

  async getPaymentLink() {
    return this.apiService.api.users()
      .getStripeCheckoutSession()
      .addQueryParameter({productId: this.strapiAttributes.stripeId, priceId: this.plan.id, returnURL: location.href})
      .executePromise();
  }

  async getBillingLink() {
    return await this.apiService.api.users().getStripeBillingPortalSession().addQueryParameter({returnURL: window.location.href}).executePromise();
  }

  async btnClick() {
    if (this.hasSubscription) {
      const billingLink = await this.getBillingLink();
      location.href = `${billingLink[0].sessionURL}/subscriptions/${this.subscription.id}/preview/${this.plan.id}`;
    } else {
      const paymentLink = await this.getPaymentLink();
      location.href = paymentLink[0].sessionURL;
    }
  }

  async handleRedirectBilling(action: string) {
    if (!this.hasSubscription) return;
    const billingLink = await this.getBillingLink();
    location.href = `${billingLink[0].sessionURL}/subscriptions/${this.subscription.id}/${action}`;
  }

  get subscription(): StripeSubscription {
    return this.user?.stripe_customer?.subscriptions?.data?.[0];
  }

  get hasSubscription() {
    return this.usersService.hasUserFeature(this.user, 'friends');
  }

  getActivePlanId(): string|undefined {
    const planId = this.plan?.product
    const subscriptionPlanId = this.user?.active_subscription?.['strapi_subscription']?.stripeId;
    if (!planId || !subscriptionPlanId) {
      return undefined;
    }
    if(subscriptionPlanId === planId){
      return planId;
    }
  }
  getHostClass() {
    const classList = ['border-cyan border-solid px-[25px] py-[30px] rounded-lg flex flex-col md:h-full'];
    if (this.focused) {
      classList.push('border-[3px]');
      if (this.adjustHeight) {
        classList.push('focused');
      }
    } else{
      classList.push('border-1');
    }
    if (this.activePlanId) {
      classList.push('bg-cyan');
    }

    return classList.join(' ');
  }

  get focused() {
    return this.strapiAttributes.focused;
  }

  get label() {
    if (this.hasSubscription) {
      return undefined;
    }
    return this.focused && this.strapiAttributes.focusedLabel
  }


}
