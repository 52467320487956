/**
 * Created by ModelParser
 * Date: 26-11-2019.
 * Time: 14:15.
 */
import {
  MessageRecipientDefinition, MessageRecipientDefinitionInterface,
} from './definitions/MessageRecipientDefinition';

export interface MessageRecipientInterface extends MessageRecipientDefinitionInterface {

}

export class MessageRecipient extends MessageRecipientDefinition implements MessageRecipientInterface {

    constructor(json?: any) {
        super(json);
    }

}
