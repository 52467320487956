import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../core/http/Api/Api';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import {map, tap} from 'rxjs/operators';

export interface ObservableCache<T> {
  cached: null|T;
  observable: Observable<T>;
}

@Injectable()
export class ApiService {

  protected _api: Api;

  constructor(
    private authenticationService: AuthenticationService,
    @Optional()
    private httpClient: HttpClient
  ) {
  }

  observableCaching<T>(observable: Observable<T>): ObservableCache<T> {
    const obj = {
      cached: null,
      observable: observable.pipe(
        tap(d => obj.cached = d)
      )
    }
    return obj;
  }

  get api(): Api {
    if (!this.httpClient) {
      throw new Error('No HttpClient provided')
    }
    if (!this._api) {
      this._api = new Api(
        {
          authenticationService: this.authenticationService,
          httpClient: this.httpClient,
          headers: () => {
            return this.authenticationService.assessToken().pipe(
              map(authToken => {
                const headers = {};
                if (authToken) {
                  headers['Authorization'] = `Bearer ${authToken}`
                }
                return headers;
              })
            )
          }
        }
      );
    }

    return this._api;
  }

}
