

<div class="container flex flex-col pb-24 items-center" *ngIf="matches?.length >= 3">
  <div class="w-full">
    <div class="flex justify-between">
      <h3 class="text-left font-semibold text-lg mb-4">Ligesindede</h3>
      <a class="!underline text-xs" (click)="openDialogFriendRequests()">Afsendte venneanmodninger</a>
    </div>
    <div class="mt-1 mb-14 flex overflow-x-auto">
      <div class="w-full grid gap-6 lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-1">
        <div *ngFor="let user of matches">
          <app-user-matches-card
            [user]="user"
            (click)="openDialog(user)"
          ></app-user-matches-card>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <a class="btn-orange" routerLink="/explore/like-minded">Find ligesindede</a>
    </div>
  </div>
</div>
