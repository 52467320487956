<ng-container *ngIf="url$|async"></ng-container>
<ng-container *ngIf="feedUpdater$|async"></ng-container>
<a routerLink="https://famoba.dk/" class="w-32">
  <img src="/assets/logo.svg" alt="famoba-logo">
</a>

<div class="flex-grow justify-center hidden md:inline-block">
  <div class="h-14 md:h-[70px]" mat-align-tabs="center" mat-tab-nav-bar>
    <a mat-tab-link [ngClass]="classMatTabLink" [active]="url === '/my-page'" routerLink="/my-page">
      Min side
      <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
    </a>
    <a mat-tab-link [ngClass]="classMatTabLink"[active]="['/explore/groups', '/explore/like-minded', '/explore'].indexOf(url) !== -1" routerLink="/explore">Udforsk</a>
    <a mat-tab-link [ngClass]="classMatTabLink" href="https://famoba.dk/raadgivning/">Rådgivning</a>
    <a mat-tab-link [ngClass]="classMatTabLink" href="https://famoba.dk/kurser/">Kurser</a>
    <a mat-tab-link [ngClass]="classMatTabLink" href="https://famoba.dk/om-os/" >Om Os</a>
    <a mat-tab-link [ngClass]="classMatTabLink" href="https://famoba.dk/kontakt/">Kontakt</a>
  </div>
</div>

<div class="items-center cursor-pointer hidden md:flex" [matMenuTriggerFor]="menu">
  <p class="mr-5">{{user?.first_name}}</p>
  <app-user-profile-icon class="inline-block w-11 h-11" [user]="user"></app-user-profile-icon>
</div>

<div class="flex-grow md:hidden flex justify-end items-center" (click)="mobileMenuHidden = !mobileMenuHidden">
  <mat-icon class="text-orange">{{mobileMenuHidden ? 'menu' : 'close'}}</mat-icon>
</div>

<mat-menu #menu="matMenu">
  <a mat-menu-item routerLink="/profile/settings">Indstillinger</a>
</mat-menu>

<div class="md:hidden fixed top-14 bottom-0 bg-white opacity-100 w-full left-0 flex flex-col items-center px-5 z-1000" [class.hidden]="mobileMenuHidden">
  <app-user-profile-icon class="inline-block w-12 h-12 mt-16 mb-4" [user]="user"></app-user-profile-icon>
  <div class="flex flex-col">
    <a routerLink="/my-page" [class]="getMobileMenuClass(url === '/my-page')">
      Min side
      <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
    </a>
    <a routerLink="/explore/like-minded" [class]="getMobileMenuClass(['/explore/groups', '/explore/like-minded', '/explore'].indexOf(url) !== -1)">Udforsk</a>
    <a href="https://famoba.dk/raadgivning/" [ngClass]="classMatTabLink" [class]="getMobileMenuClass(false)">Rådgivning</a>
    <a href="https://famoba.dk/kurser/" [ngClass]="classMatTabLink" [class]="getMobileMenuClass(false)">Kurser</a>
    <a href="https://famoba.dk/om-os/" [ngClass]="classMatTabLink" [class]="getMobileMenuClass(false)">Om os</a>
    <a href="https://famoba.dk/kontakt/" [ngClass]="classMatTabLink" [class]="getMobileMenuClass(false)">Kontakt</a>
  </div>
  <app-divider class="my-3.5"></app-divider>
  <div class="flex flex-col">
    <a [class]="getMobileMenuClass(url?.indexOf('/profile/') === 0)" routerLink="/profile/settings">Indstillinger</a>
  </div>
</div>

<ng-template #notificationTemplate>
  <ng-container *ngIf="notificationData$ | async as notification">
    <app-badge-notification
      *ngIf="notification.countConversations >= 0 && notification.countFeed >= 0 && notification.isLoadedConversations && notification.isLoadedFeed"
      [isNav]="true" [count]="notification.countConversations + notification.countFeed"></app-badge-notification>
  </ng-container>
</ng-template>