import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';


@Component({
  selector: 'app-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss'],
})
export class UploadImageDialogComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<UploadImageDialogComponent>,
        protected snackbar: MatSnackBar,
    ) {
    }

    ngOnInit(): void {
    }
    
    async saveFile(): Promise<any> {
        this.dialogRef.close({croppedImage: this.croppedImage});
    }
    fileChangeEvent(event: any): void {
       this.imageChangedEvent = event; 
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded(image?: LoadedImage) {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        this.imageChangedEvent = '';
        // show message
    }
}
