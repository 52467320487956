import { DiffAgeService } from './services/diff-age.service';
import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoggerModule } from 'ngx-logger';
import { CoreModule } from './core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressModule } from '@ngx-progressbar/core';
import { SharedModule } from './shared';
import { PageComponent } from './pages/page.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { AppInjector } from './services/app-injector.service';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import localeDa from '@angular/common/locales/da';
import { registerLocaleData } from '@angular/common';
import { DateAdapter } from 'angular-calendar';
import { PlatformModule } from '@angular/cdk/platform';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { ProfileDialogComponent } from './shared/dialogs/profile-dialog/profile-dialog.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { SignupNameFormComponent } from './pages/signup-page/signup-name-form/signup-name-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SignupCityFormComponent } from './pages/signup-page/signup-city-form/signup-city-form.component';
import { environment } from '../environments/environment';
import { AtomicModule } from './components/atomic.module';
import { DialogService } from './services/dialog.service';
import { APP_DATE_FORMATS, AppDateAdapter } from './core/format-datepicker';
import { VideoMeetingDialogComponent } from './shared/dialogs/video-meeting-dialog/video-meeting-dialog.component';
import { EditEventDialogComponent } from './shared/dialogs/edit-event-dialog/edit-event-dialog.component';
import { SettingsDialogComponent } from './shared/dialogs/settings-dialog/settings-dialog.component';
import { AlertDialogComponent } from './shared/dialogs/alert-dialog/alert-dialog.component';
import { RegisterDialogComponent } from './shared/dialogs/register-dialog/register-dialog.component';
import { ShellModule } from './core/shell/shell.module';
import { MyPageModule } from './pages/my-page/my-page.module';
import { NgAisModule } from 'angular-instantsearch';
import { AuthModule } from './auth.module';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import {SingleChatDialogComponent} from "./shared/dialogs/single-chat-dialog/single-chat-dialog.component";
import {ExploreModule} from "./pages/explore/explore.module";
import {MatStepperModule} from "@angular/material/stepper";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { TawkService } from './services/tawk.service';
import {FreeTrialPopupComponent} from "./shared/dialogs/free-trial-popup/free-trial-popup.component";
// https://onthecode.co.uk/angular-logging-made-easy-with-ngx-logger/

// @ts-ignore
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    PageComponent,
    SignupPageComponent,
    SignupNameFormComponent,
    SignupCityFormComponent,
  ],
  exports: [], imports: [
    NgAisModule.forRoot(),
    MyPageModule,
    ReactiveFormsModule,
    AuthModule,
    BrowserModule,
    PlatformModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
    HttpClientModule,
    RouterModule,
    CoreModule,
    ShellModule,
    SharedModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ClickOutsideModule,
    NgxSliderModule,
    NgxPaginationModule,
    PerfectScrollbarModule,

    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: environment.production,
    }),
    NgProgressModule.withConfig({
      trickleSpeed: 100, min: 20, spinner: false, meteor: true, thick: true, debounceTime: 100, color: '#b71455', // 01aaa0
    }),
    NgProgressHttpModule, // https://github.com/MurhafSousli/ngx-progressbar/blob/master/CHANGELOG.md

    // Pages

    // must be imported as the last module as it contains the fallback activatedRoute
    AppRoutingModule,
    AtomicModule,
    ExploreModule,
    MatStepperModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    HttpClientModule,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    TawkService,
    DialogService,
    { provide: MAT_DATE_LOCALE, useValue: 'da-DK' },
    { provide: LOCALE_ID, useValue: 'da-DK' },
    { provide: MAT_DATE_LOCALE, useValue: 'da' },
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: AppDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS, PlatformModule],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'outline' },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 1500,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: 'snackBarInfo',
      },
    },
    DiffAgeService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AlertDialogComponent,
    RegisterDialogComponent,
    EditEventDialogComponent,
    SettingsDialogComponent,
    VideoMeetingDialogComponent,
    ProfileDialogComponent,
    SingleChatDialogComponent,
    FreeTrialPopupComponent
  ],
})
export class AppModule {
  constructor(injector: Injector) {
    registerLocaleData(localeDa, 'da');
    try {
      const appInjector: AppInjector = AppInjector.getInstance();
      appInjector.setInjector(injector);
    } catch (e) {
      console.error(
        '🚫 app.module.ts :: Not able to store injector! Error : ',
        e,
        'injector : ',
        injector
      );
    }
  }
}
