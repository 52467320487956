import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../../shared';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { AtomicModule } from '../../components/atomic.module';
import { UpdatesNotificationComponent } from '../updates-notification/updates-notification.component';
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatTabsModule} from "@angular/material/tabs";

@NgModule({
    declarations: [
        NavigationBarComponent,
        UpdatesNotificationComponent,
        FooterComponent,

    ], exports: [
    NavigationBarComponent, FooterComponent
  ], imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    FlexLayoutModule,
    SharedModule,
    AtomicModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule
  ]
})
export class ShellModule {
}
