<ng-container *ngIf="interests$|async"></ng-container>
<app-extended-infinite-hits [generateFilters]="generateFilters" #extendedInfiniteHits></app-extended-infinite-hits>

<div class="grid grid-cols-3 gap-x-5 gap-y-10 xl:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 pb-28 mt-7">
  <ng-container *ngFor="let hit of reduceArr(extendedInfiniteHits.combinedHits); trackBy: trackBy(); let i = index">
    <a [routerLink]="'/groups/group/' + hit.objectID" class="w-full" *ngIf="!isHidden(hit)">
      <app-group-card [group]="hitToGroup(hit)" [tagsMatched]="tagsMatched" [tagsNoMatched]="tagsNoMatched">
      </app-group-card>
    </a>
  </ng-container>
</div>
<div class="w-full flex justify-center pb-28" *ngIf="extendedInfiniteHits.canShowMore()">
  <button (click)="extendedInfiniteHits.showMore()">Indlæs flere</button>
</div>
