import { Component, Input, OnInit } from '@angular/core';

export type IconColor = 'black' | 'green' | 'white' | 'dgray' | 'orange'

export type IconName =
  'arrow' |
  'arrow-back' |
  'arrow-down' |
  'arrow-long' |
  'child' |
  'calendar' |
  'cart' |
  'profile-image' |
  'profile-male' |
  'profile-female' |
  'location' |
  'x' |
  'logo-famoba' |
  'search' |
  string

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() icon: IconName;
  @Input() color: IconColor;
  @Input() svgIcon: string;
  constructor() {}

  ngOnInit(): void {}

  get isFaIcon() {
    return this.icon?.indexOf('fa-') === 0;
  }

  protected get colorClassList() {
    return [
      'stroke-green',
      'stroke-black',
      'stroke-white',
      'stroke-dgray',
      'stroke-orange',
      'fill-green',
      'fill-dgray',
      'fill-black',
      'fill-white',
      'fill-orange',
    ];
  }

  getClassList(type: 'stroke'|'fill') {
    const classList = [];
    if (this.color) {
      const target = `${type}-${this.color}`;
      const className = this.colorClassList.find(d => d === target);
      if (!className) {
        throw new Error(`Could not find match for '${target}'`)
      }
      classList.push(className);
    } else if (type ==='stroke') {
      classList.push('stroke-inherit');
    } else if (type === 'fill') {
      classList.push('fill-inherit');
    }

    return classList;
  }
}
