import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClickOutsideModule} from 'ng-click-outside';
import {LoadingIndicatorComponent} from './loading-indicator/loading-indicator.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RegisterDialogComponent} from './dialogs/register-dialog/register-dialog.component';
import {EditEventDialogComponent} from './dialogs/edit-event-dialog/edit-event-dialog.component';
import {SettingsDialogComponent} from './dialogs/settings-dialog/settings-dialog.component';
import {InterestPickerComponent} from './interest-picker/interest-picker.component';
import {ProfileComponent} from './profile/profile.component';
import {AlertDialogComponent} from './dialogs/alert-dialog/alert-dialog.component';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {RequiredPermissionsComponent} from './required-permissions/required-permissions.component';
import {VideoMeetingDialogComponent} from './dialogs/video-meeting-dialog/video-meeting-dialog.component';
import {ProfileDialogComponent} from './dialogs/profile-dialog/profile-dialog.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxPaginationModule} from 'ngx-pagination';
import {AddressSearchDawaComponent} from './address-search-dawa/address-search-dawa.component';
import {BaseDialogComponent} from './dialogs/base-dialog/base-dialog.component';
import {PhonenumberComponent} from './phonenumber/phonenumber.component';
import {AtomicModule} from '../components/atomic.module';
import {PipesModule} from '../core/pipes/pipes.module';
import {SingleChatDialogComponent} from "./dialogs/single-chat-dialog/single-chat-dialog.component";
import {NewChatDialogComponent} from "./dialogs/new-chat-dialog/new-chat-dialog.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {AddressComponent} from "./forms/address/address.component";
import {MatInputModule} from "@angular/material/input";
import {ReportDialogComponent} from './dialogs/report-dialog/report-dialog.component';
import {FriendRequestsDialogComponent} from './dialogs/friend-requests-dialog/friend-requests-dialog.component';
import {MatMenuModule} from '@angular/material/menu';
import { EmailAlreadyUsedComponent } from './email-already-used/email-already-used.component';
import {FreeTrialPopupComponent} from "./dialogs/free-trial-popup/free-trial-popup.component";
import { ImageCropperModule } from 'ngx-image-cropper';
import { UploadImageDialogComponent } from "./dialogs/upload-image-dialog/upload-image-dialog.component";

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    FlexLayoutModule,
    NgxSliderModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    AtomicModule,
    PipesModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatMenuModule,
    ImageCropperModule
  ], declarations: [
    // Shared helper components
    LoadingIndicatorComponent,
    RegisterDialogComponent,
    AddressSearchDawaComponent,
    EditEventDialogComponent,
    SettingsDialogComponent,
    BaseDialogComponent,
    PhonenumberComponent,
    InterestPickerComponent,
    ProfileComponent,
    AlertDialogComponent,
    RequiredPermissionsComponent,
    VideoMeetingDialogComponent,
    ProfileDialogComponent,
    FreeTrialPopupComponent,
    SingleChatDialogComponent,
    NewChatDialogComponent,
    AddressComponent,
    ReportDialogComponent,
    FriendRequestsDialogComponent,
    EmailAlreadyUsedComponent,
    UploadImageDialogComponent
  ], exports: [
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    LoadingIndicatorComponent,
    AddressSearchDawaComponent,
    BaseDialogComponent,
    PhonenumberComponent,
    InterestPickerComponent,
    ProfileComponent,
    RequiredPermissionsComponent,
    RegisterDialogComponent,
    VideoMeetingDialogComponent,
    AddressComponent,
    ReportDialogComponent,
    FriendRequestsDialogComponent,
    UploadImageDialogComponent
  ],

  providers: []

})
export class SharedModule {
}
