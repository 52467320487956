/**
 * Created by ModelParser
 * Date: 17-09-2019.
 * Time: 09:45.
 */
import { GroupMemberDefinition, GroupMemberDefinitionInterface } from './definitions/GroupMemberDefinition';

export interface GroupMemberInterface extends GroupMemberDefinitionInterface {

}

export class GroupMemberOld extends GroupMemberDefinition implements GroupMemberInterface {

    constructor(json?: any) {
        super(json);
    }

}
