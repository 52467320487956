/**
 * Created by ModelParser
 */
import { User } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';
import { Interest } from '../Interest';
import { StrapiModel } from '../StrapiModel';
import { InterestAttributes } from './InterestDefinition';


export interface SearchprofileDefinitionInterface {
     min_interest_count?: number;
     min_interest_count_active?: boolean;
     min_parent_age_in_years?: number;
     max_parent_age_in_years?: number;
     parent_age_active?: boolean;
     min_child_age_in_months?: number;
     max_child_age_in_months?: number;
     child_age_active?: boolean;
     max_distance?: number;
     max_distance_active?: boolean;
     created?: string;
     updated?: string;
     created_by_id?: number;
     created_by?: User;
     updated_by_id?: number;
     updated_by?: User;
     interests?: StrapiModel<InterestAttributes>[];
     user_group?: number;

 }

 export class SearchprofileDefinition extends BaseStoredModel implements SearchprofileDefinitionInterface {

    min_interest_count?: number;
    min_interest_count_active?: boolean;
    min_parent_age_in_years?: number;
    max_parent_age_in_years?: number;
    parent_age_active?: boolean;
    min_child_age_in_months?: number;
    max_child_age_in_months?: number;
    child_age_active?: boolean;
    max_distance?: number;
    max_distance_active?: boolean;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;
    interests?: Interest[];
    user_group?: number;

     constructor(data?: any) {
         super();
         this.populate(data);
     }

     public populate(data?: any, patch: boolean = false) {
         if (!patch) {
             delete this.min_interest_count;
             delete this.min_interest_count_active;
             delete this.min_parent_age_in_years;
             delete this.max_parent_age_in_years;
             delete this.parent_age_active;
             delete this.min_child_age_in_months;
             delete this.max_child_age_in_months;
             delete this.child_age_active;
             delete this.max_distance;
             delete this.max_distance_active;
             delete this.created;
             delete this.updated;
             delete this.created_by_id;
             delete this.created_by;
             delete this.updated_by_id;
             delete this.updated_by;
             delete this.interests;
             delete this.user_group;
         }

         if (!data) return;

         if (data.min_interest_count != null) {
             this.min_interest_count = data.min_interest_count;
         }
         if (data.min_interest_count_active != null) {
            this.min_interest_count_active = data.min_interest_count_active;
        }
        if (data.min_parent_age_in_years != null) {
            this.min_parent_age_in_years = data.min_parent_age_in_years;
        }
        if (data.max_parent_age_in_years != null) {
            this.max_parent_age_in_years = data.max_parent_age_in_years;
        }
        if (data.parent_age_active != null) {
            this.parent_age_active = data.parent_age_active;
        }
        if (data.min_child_age_in_months != null) {
            this.min_child_age_in_months = data.min_child_age_in_months;
        }
        if (data.max_child_age_in_months != null) {
            this.max_child_age_in_months = data.max_child_age_in_months;
        }
        if (data.child_age_active != null) {
            this.child_age_active = data.child_age_active;
        }
        if (data.max_distance != null) {
            this.max_distance = data.max_distance;
        }
        if (data.max_distance_active != null) {
            this.max_distance_active = data.max_distance_active;
        }


         if (data.created != null) {
             this.created = data.created;
         }
         if (data.updated != null) {
             this.updated = data.updated;
         }
         if (data.created_by_id != null) {
             this.created_by_id = data.created_by_id;
         }
         if (data.created_by != null) {
             this.created_by = new User(data.created_by);
         }
         if (data.updated_by_id != null) {
             this.updated_by_id = data.updated_by_id;
         }
         if (data.updated_by != null) {
             this.updated_by = new User(data.updated_by);
         }
         if (data.interests != null) {
            this.interests = data.interests.map((i: any) => new Interest(i));
        }
        if (data.user_group != null) {
            this.user_group = data.user_group;
        }
     }

 }
