<ng-container *ngIf="tagsMatched?.length > 0 || tagsNoMatched?.length > 0">
  <div class="absolute top-0 right-0 flex flex-col items-end z-10 p-2 h-[175px]">
    <button
    (mouseover)="emitToggleShowMatched()"
    (mouseout)="emitToggleShowMatched()"
    (touchstart)="emitToggleShowMatched()"
    (touchend)="emitToggleShowMatched()"
    [ngClass]="{'rounded-bl-none rounded-br-none': isShowMatched}"
    class="inline-flex !bg-white items-center !border-none !rounded-[20px] max-w-[190px] h-[40px] !tracking-normal hover:!rounded-bl-none hover:!rounded-br-none"
    >
      <span class="text-black text-sm pr-[14px]">Bedste match</span>
      <span class="block border-[0.5px] text-black border-black rounded-[50%] w-[18px] h-[18px] text-[14px] tracking-[-0.5px] text-center">
        ?
      </span>
    </button>
    <ng-container *ngIf="isShowMatched">
      <div class="min-h-fit h-auto w-full bg-white rounded-tl-[20px] rounded-bl-[20px] rounded-br-[20px]">
        <div class="max-w-xs p-5">
          <div>
            <ng-container *ngIf="tagsMatched?.length > 0">
              <p class="text-xs font-semibold">Matchende tags</p>
              <div class="pt-2.5 flex flex-row flex-wrap gap-2">
                <app-chip class="w-auto h-[28px]"
                  *ngFor="let tag of tagsMatched | slice:0:5">
                  {{tag.attributes.label}}
                </app-chip>
              </div>
            </ng-container>
            <ng-container *ngIf="tagsNoMatched?.length > 0">
              <p class="text-xs font-semibold" [ngClass]="{'pt-5': tagsMatched.length > 0}">
                Andre
                interesser i gruppen</p>
              <div class="pt-2.5 flex flex-row flex-wrap gap-2">
                <app-chip
                  class="border-cyan !bg-white w-auto h-[28px]"
                  *ngFor="let tag of tagsNoMatched | slice:0:5">
                  {{tag.attributes.label}}</app-chip>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
