/**
 * Created by ModelParser
 */
import { NotificationSubscription, NotificationSubscriptionInterface } from '../NotificationSubscription';
import { Message, MessageInterface } from '../Message';
import { EmailNotification, EmailNotificationInterface } from '../EmailNotification';
import { NotificationData, NotificationDataInterface } from '../NotificationData';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';

export interface NotificationDefinitionInterface {
    date?: string;
    notification_subscription_id?: number;
    notification_subscription?: NotificationSubscriptionInterface;
    body?: string;
    read?: string;
    handled?: string;
    message_id?: number;
    message?: MessageInterface;
    email_notifications?: EmailNotificationInterface[];
    notification_datas?: NotificationDataInterface[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class NotificationDefinition extends BaseStoredModel implements NotificationDefinitionInterface {
    date?: string;
    notification_subscription_id?: number;
    notification_subscription?: NotificationSubscription;
    body?: string;
    read?: string;
    handled?: string;
    message_id?: number;
    message?: Message;
    email_notifications?: EmailNotification[];
    notification_datas?: NotificationData[];
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.date;
            delete this.notification_subscription_id;
            delete this.notification_subscription;
            delete this.body;
            delete this.read;
            delete this.handled;
            delete this.message_id;
            delete this.message;
            delete this.email_notifications;
            delete this.notification_datas;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.date != null) {
            this.date = data.date;
        }
        if (data.notification_subscription_id != null) {
            this.notification_subscription_id = data.notification_subscription_id;
        }
        if (data.notification_subscription != null) {
            this.notification_subscription = new NotificationSubscription(data.notification_subscription);
        }
        if (data.body != null) {
            this.body = data.body;
        }
        if (data.read != null) {
            this.read = data.read;
        }
        if (data.handled != null) {
            this.handled = data.handled;
        }
        if (data.message_id != null) {
            this.message_id = data.message_id;
        }
        if (data.message != null) {
            this.message = new Message(data.message);
        }
        if (data.email_notifications != null) {
            this.email_notifications = data.email_notifications.map((i: any) => new EmailNotification(i));
        }
        if (data.notification_datas != null) {
            this.notification_datas = data.notification_datas.map((i: any) => new NotificationData(i));
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
