<div class="w-full flex flex-col md:flex-row md:min-h-[683px] bg-blue">
  <img class="md:w-6/12 w-full object-cover min-h-[400px]" src="/assets/Famoba_2sh2.jpg"/>
  <div class="md:w-6/12 w-full flex justify-center items-center md:h-auto h-96 overflow-hidden relative min-h-[400px]">
    <div class="md:w-8/12 md:px-0 container z-10">
      <h2 class="text-white text-2xl mb-2">Rådgivning</h2>
      <p class="text-white text-sm mb-[30px] leading-7 font-light">Vi står klar til at hjælpe dig videre med faglig rådgivning. Du kan vælge at gøre brug af vores online chat eller finde svar i vores opslagsværk.
        Du kan også booke et videomøde med en fagperson her hvis du foretrækker et personligt møde. <br>Hold dig ikke tilbage - vi er her for dig.</p>
      <button class="w-[204px]" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/bookraadgivning?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=2e2e3a&primary_color=f4a266'});return false;">Book et møde</button>
    </div>
    <div class="absolute bottom-[-176px] left-[-212px]">
      <img src="/assets/images/watermark_logo_part.svg">
    </div>
  </div>
</div>
