<ng-container *ngIf="url$|async"></ng-container>

<app-sidenav title="Din søgeprofil">
  <app-filter
    class="w-full bg-blue flex-col flex md:px-5 items-center md:h-auto container md:top-20 md:sticky"
    #filter
    *ngIf="interests$|async as interests"
    sidenav
    [user]="user"
    [defaultInterests]="interests"
    [interests]="interests"
  >
  </app-filter>
  <app-explore-content class="container px-4 my-1 w-full mx-auto block" content></app-explore-content>
</app-sidenav>

