/**
 * Created by ModelParser
 * Date: 08-07-2019.
 * Time: 13:50.
 */
import { PhoneNumberDefinition, PhoneNumberDefinitionInterface } from './definitions/PhoneNumberDefinition';

export interface PhoneNumberInterface extends PhoneNumberDefinitionInterface {

}

export class PhoneNumber extends PhoneNumberDefinition implements PhoneNumberInterface {

    constructor(json?: any) {
        super(json);
    }

    public static createDefault() {
        const item = new PhoneNumber();
        item.id = 0;
        // item.country_code = null;
        // item.number = null;
        return item;
    }
}
