/**
 * Created by ModelParser
 * Date: 11-06-2020.
 * Time: 12:26.
 */
import {
  EmailNotificationDefinition, EmailNotificationDefinitionInterface,
} from './definitions/EmailNotificationDefinition';

export interface EmailNotificationInterface extends EmailNotificationDefinitionInterface {

}

export class EmailNotification extends EmailNotificationDefinition implements EmailNotificationInterface {

    constructor(json?: any) {
        super(json);
    }

}
