import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DawaAutocomplete } from './dawa-autocomplete';
import { AddressDefinitionInterface } from '../../core/models/definitions/AddressDefinition';

/** Error when invalid control is dirty, touched, or submitted. */

export interface DawaAddressChange { dawa: DawaAutocomplete, address: AddressDefinitionInterface }

@Component({
  selector: 'app-address-search-dawa',
  templateUrl: './address-search-dawa.component.html',
  styleUrls: ['./address-search-dawa.component.scss']
})
export class AddressSearchDawaComponent implements OnInit {

  usersForm: FormGroup;

  inputChange$: Observable<DawaAutocomplete[]>;

  @Output() addressSelected: EventEmitter<DawaAddressChange> = new EventEmitter<DawaAddressChange>();
  error: boolean;


  constructor(private fb: FormBuilder, private http: HttpClient) {
  }

  ngOnInit() {

    this.usersForm = this.fb.group({
      userInput: null
    });
    this.inputChange$ = this.usersForm
      .get('userInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => this.search(value)),
      ) as Observable<DawaAutocomplete[]>
  }

  displayFn(item: DawaAutocomplete) {
    if (item) {
      return `${item.forslagstekst}`;
    }
    return undefined;
  }

  search(value: string) {
    return this.http.get(`//dawa.aws.dk/autocomplete/?q=${value}&fuzzy&struktur=mini`);
  }

  select(selectedAddress: DawaAutocomplete) {
    if (selectedAddress.data.postnr && selectedAddress.data.husnr && selectedAddress.data.vejnavn && selectedAddress.data.postnrnavn) {
      this.error = false;
      const address: AddressDefinitionInterface = {};
      address.street = `${selectedAddress.data.vejnavn} ${selectedAddress.data.husnr}`;

      if (selectedAddress.data.etage) {
        address.street += ', ' + selectedAddress.data.etage;
      }

      if (selectedAddress.data.dør) {
        address.street += ', ' + selectedAddress.data.dør;
      }
      address.zip = parseInt(selectedAddress.data.postnr, 10);
      address.city = selectedAddress.data.postnrnavn;
      this.addressSelected.emit({address, dawa: selectedAddress});
    }else{
       this.error = true;
    }
  }
}
