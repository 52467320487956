/**
 * Created by ModelParser
 * Date: 22-06-2020.
 * Time: 14:05.
 */
import { EventStarDefinition, EventStarDefinitionInterface } from './definitions/EventStarDefinition';

export interface EventStarInterface extends EventStarDefinitionInterface {

}

export class EventStar extends EventStarDefinition implements EventStarInterface {

    constructor(json?: any) {
        super(json);
    }

}
