import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import dayjs from "dayjs";

// https://stackblitz.com/angular/mrjaokybbxl?file=app%2Fdatepicker-locale-example.ts
// https://stackoverflow.com/questions/45019317/matdatepicker-start-week-on-monday/45020144

@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'input') {
            return dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
            // return Helpers.serverDate(date);
        }
        return date.toDateString();
    }

    // parse the date from input component as it only expect dates in
    // mm-dd-yyyy format
    parse(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            const str = value.split('/');

            const year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    getFirstDayOfWeek(): number {
        // We can't tell using native JS Date what the first day of the week is, we default to Sunday.
        // https://github.com/angular/material2/issues/8100
        // https://github.com/angular/material2/issues/10778
        // return 0;
        return 1;
    }


}

export const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
    },
    display: {
        // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
        dateInput: 'input',
        monthYearLabel: {year: 'numeric', month: 'numeric'},
        dateA11yLabel: {
            year: 'numeric', month: 'long', day: 'numeric'
        },
        monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
};


// @NgModule({
//     declarations: [],
//     imports: [],
//     exports: [MdDatepickerModule, MdNativeDateModule],
//     providers: [
//         {
//             provide: DateAdapter, useClass: AppDateAdapter
//         },
//         {
//             provide: MD_DATE_FORMATS, useValue: MY_DATE_FORMATS
//         }
//     ]
// })
//
// export class DatePickerModule {
//
// }
