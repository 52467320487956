import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscribe-action',
  templateUrl: './subscribe-action.component.html',
  styleUrls: ['./subscribe-action.component.scss']
})
export class SubscribeActionComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
