<ng-container *ngIf="searchEvent$|async"></ng-container>
<mat-icon mat-dialog-close="close" class="dialog-close-icon z-[1000]">close</mat-icon>
<h3 class="w-full text-center mb-9 md:mt-0 mt-8">Ny besked</h3>
<div class="relative !max-w-[580px] w-full">
  <mat-form-field class="w-full -mb-5">
    <span class="absolute font-['Metropolis']">Til:</span>
    <input autocomplete="off" matInput #searchInput>
  </mat-form-field>
  <div class="flex flex-col px-2 overflow-auto flex-grow min-h-0 pretty-scrollbar">
    <ng-container *ngIf="!isFetching; else loading">
      <div *ngFor="let user of users" (click)="openChat(user)" class="flex items-center my-2.5 cursor-pointer">
        <app-user-profile-icon class="w-9 h-9 inline-block mr-2.5" [user]="user"></app-user-profile-icon>
        <p>{{user.first_name}} {{user.last_name}}</p>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <div class="mx-auto w-32 h-32">
    <app-spinner></app-spinner>
  </div>
</ng-template>
