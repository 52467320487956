import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { UsersService } from 'src/app/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard implements CanActivate, CanActivateChild {

  constructor(
    private usersService: UsersService,
    private router: Router
  ) {
  }

  getCheck() {
    return this.usersService.meAsync().pipe(
      skipWhile(d => !d),
      map(user => {
        if (this.usersService.isUserSignedUp(user)) {
          return true;
        } else {
          return this.router.createUrlTree(['signup']);
        }
      })
    )
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.getCheck();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.getCheck();
  }

}
