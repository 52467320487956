/**
 * Created by ModelParser
 */
import { User, UserInterface } from '../User';
import { Match, MatchInterface } from '../Match';
import { BaseStoredModel } from '../BaseStoredModel';

export interface UserStarDefinitionInterface {
    user_id?: number;
    user?: UserInterface;
    starred_user_id?: number;
    starred_user?: UserInterface;
    type?: string;
    match?: MatchInterface;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class UserStarDefinition extends BaseStoredModel implements UserStarDefinitionInterface {
    user_id?: number;
    user?: User;
    starred_user_id?: number;
    starred_user?: User;
    type?: string;
    match?: Match;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.user_id;
            delete this.user;
            delete this.starred_user_id;
            delete this.starred_user;
            delete this.type;
            delete this.match;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.user_id != null) {
            this.user_id = data.user_id;
        }
        if (data.user != null) {
            this.user = new User(data.user);
        }
        if (data.starred_user_id != null) {
            this.starred_user_id = data.starred_user_id;
        }
        if (data.starred_user != null) {
            this.starred_user = new User(data.starred_user);
        }
        if (data.type != null) {
            this.type = data.type;
        }
        if (data.match != null) {
            this.match = new Match(data.match);
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
