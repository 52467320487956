import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Interest } from '../core/models';
import { map } from 'rxjs/operators';
import {StoredCacheObservable} from "../core/stored-cache-observable";
import {UsersService} from "./users.service";

@Injectable({
  providedIn: 'root'
})
export class InterestsService {

  protected interestsCached: StoredCacheObservable<Interest[]>;

  constructor(
    protected apiService: ApiService,
    protected usersService: UsersService
  ) {
    this.interestsCached = new StoredCacheObservable<Interest[]>({
      localStorageKey: 'stored-interests',
      observable: (this.apiService.api.strapi().interests().find() as any as Observable<[Interest[]]>).pipe(
        map(d => d[0])
      )
    })
  }

  getInterests(): Observable<Interest[]> {
    return this.interestsCached.asObservable();
  }

  tagIdsToTags(tags: Interest[], tagIds: number[]): Interest[] {
    const tagsById = tags.reduce((previousValue, currentValue) => {
      previousValue[currentValue.id] = currentValue;
      return previousValue;
    }, {});
    return tagIds.map(d => tagsById[d])
  }

  tagIdsToTagsAsync(tagIds: number[]): Observable<Interest[]> {
    return this.getInterests().pipe(
      map(d => this.tagIdsToTags(d, tagIds))
    )
  }

}
