import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { User } from '../../../core/models';
import { UsersService } from '../../../services/users.service';
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../services/api.service";
import {GoogleService} from "../../../services/google.service";

export interface userCardProduct {
  name: string;
  address: string;
  childrenCount: number;
}

@Component({
  selector: 'app-user-matches-card',
  templateUrl: './user-matches-card.component.html',
  styleUrls: ['./user-matches-card.component.scss'],
  host: {
    class: 'relative flex items-start md:items-center bg-zinc-50 p-5 mt-2 md:mt-0 hover:shadow-md transition duration-200 cursor-pointer rounded',
  }
})
export class UserMatchesCardComponent implements OnInit {

  genderLabel: string;
  @Input() component: string;
  @Output() filterUsers = new EventEmitter<any>();
  get user(): User {
    return this._user;
  }

  @Input()
  set user(value: User) {
    this._user = value;
    this.genderLabel = this.userService.getGenderLabel(value.gender);
  }

  private _user: User;

  constructor(
    protected userService: UsersService,
    protected apiService: ApiService,
    protected dialog: MatDialog,
    protected googleService: GoogleService
  ) {}

  get distanceFromMe() {
    return (this.googleService.computeDistanceBetween({
      lat: this.user?.address?.lat,
      lng: this.user?.address?.long,
    },{
      lat: this.userService?.me()?.address?.lat,
      lng: this.userService?.me()?.address?.long,
    }) / 1000).toFixed(2);
  }

  get matchingTagsCount() {
    const userATagIds = this.user.tags.map(d => d.id);
    const userBTagIds = this.userService.me().tags.map(d => d.id);
    return userATagIds.filter(d => userBTagIds.indexOf(d) !== -1).length;
  }

  ngOnInit(): void {
  }

  emitFilterUsers(){
    this.filterUsers.emit()
  }
}
