<div class="w-full flex md:flex-row flex-col">
  <div class="flex-grow flex items-center md:mb-0 mb-2">
    <p
      class="rounded m-0 bg-cyan w-full text-sm flex h-full items-center px-4 py-2"
    >
      Få fuld adgang til Famoba for at kontakte ligesindede og tilmelde dig
      grupper.
    </p>
  </div>
  <a
    [routerLink]="'/profile/plans'"
    class="button outline md:w-auto w-full max-w-fit md:ml-3"
  >Vælg abonnement her</a
  >
</div>
