import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogService } from '../../../services/dialog.service';
import { User } from "../../../core/models";
import { Observable } from 'rxjs';
import { ApiService } from '../../../services/api.service';
@Component({
  selector: 'app-friend-requests-dialog',
  templateUrl: './friend-requests-dialog.component.html',
  styleUrls: ['./friend-requests-dialog.component.scss']
})
export class FriendRequestsDialogComponent implements OnInit {

  component: string = "friend-request";
  users$: Observable<User[]>;
  @Output() closeEditor = new EventEmitter<any>();
  constructor(
    private dialogService: DialogService,
    private apiService: ApiService,
  ) {
    this.users$ = this.apiService.api.users().searchFriendsPending().execute();
  }

  ngOnInit() {
  }


  filterUsers(){
    this.users$ = this.apiService.api.users().searchFriendsPending().execute();
  }

  public close(model?: any) {

    this.closeEditor.emit(model.id !== 0 ? model : null);
    this.dialogService.emit(model.id !== 0 ? model : null);
  }

}
