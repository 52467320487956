import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { ImageLinkComponent } from './molecules/image-link/image-link.component';
import { RouterModule } from '@angular/router';
import { GroupCardComponent } from './organisms/group-card/group-card.component';
import { IconComponent } from './atoms/icon/icon.component';
import { UserProfileIconComponent } from './molecules/user-profile-icon/user-profile-icon.component';
import { FilterContainerComponent } from './organisms/filter-container/filter-container.component';
import { ChipComponent } from './atoms/chip/chip.component';
import { SubscribeActionComponent } from './molecules/subscribe-action/subscribe-action.component';
import { ProductCardComponent } from './organisms/product-card/product-card.component';
import { ProductCardNoPriceComponent } from './organisms/product-card-no-price/product-card-no-price.component';
import { ProductCardInformationTapComponent } from './molecules/product-card-information-tap/product-card-information-tap.component';
import { UserCardComponent } from './organisms/user-card/user-card.component';
import { UserMatchesCardComponent } from './organisms/user-matches-card/user-matches-card.component';
import { FriendRequestCardComponent } from './organisms/user-matches-card/friend-request-card/friend-request-card.component';
import { ProfileIconComponent } from './molecules/profile-icon/profile-icon.component';
import { EditChildFormComponent } from './organisms/edit-child-form/edit-child-form.component';
import { EditChildrenFormComponent } from './organisms/edit-children-form/edit-children-form.component';
import { ChipSelectComponent } from './organisms/chip-select/chip-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { StrapiImageInputComponent } from './molecules/strapi-image-input/strapi-image-input.component';
import { PaginatorComponent } from './molecules/paginator/paginator.component';
import { DividerComponent } from './atoms/divider/divider.component';
import { UserDetailedComponent } from './organisms/user-detailed/user-detailed.component';
import { IconLabelComponent } from './molecules/icon-label/icon-label.component';
import { MessagePreviewCardComponent } from './molecules/message-preview-card/message-preview-card.component';
import { NotificationComponent } from './molecules/notification/notification.component';
import { MessageCardComponent } from './molecules/message-card/message-card.component';
import { SubscriptionCardComponent } from './molecules/subscription-card/subscription-card.component';
import { UserSimpleCardComponent } from './molecules/user-simple-card/user-simple-card.component';
import { PostHeaderComponent } from './molecules/post-header/post-header.component';
import { CommentCardComponent } from './molecules/comment-card/comment-card.component';
import { PostCardComponent } from './molecules/post-card/post-card.component';
import {PipesModule} from "../core/pipes/pipes.module";
import { SidenavComponent } from './organisms/sidenav/sidenav.component';
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import { BigGroupCardComponent } from './organisms/big-group-card/big-group-card.component';
import { RangeSliderComponent } from './instantsearch/range-slider/range-slider.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";
import { SubscriptionContainerComponent } from './organisms/subscription-container/subscription-container.component';
import { SubscriptionInviteContainerComponent } from './organisms/subscription-invite-container/subscription-invite-container.component';
import { SubscriptionRequestsInviteContainerComponent } from './organisms/subscription-requests-invite-container/subscription-requests-invite-container.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import { MatchedCardComponent } from './molecules/matched-card/matched-card.component';
import { MatchedBigCardComponent } from './molecules/matched-big-card/matched-big-card.component';
import { CameraComponent } from './molecules/camera/camera.component';
import {WebcamModule} from 'ngx-webcam';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from "@angular/material/button";
import { AlgoliaTagSearchComponent } from './organisms/algolia-tag-search/algolia-tag-search.component';
import {NgAisModule} from "angular-instantsearch";
import { SpinnerComponent } from "./atoms/spinner/spinner.component";
import { BadgeNotificationComponent } from './atoms/badge-notification/badge-notification.component';
import { GroupCardContainerComponent } from './organisms/group-card-container/group-card-container.component';

@NgModule({
  declarations: [
    IconComponent,
    ImageLinkComponent,
    GroupCardComponent,
    UserProfileIconComponent,
    FilterContainerComponent,
    ChipComponent,
    SubscribeActionComponent,
    ProductCardComponent,
    ProductCardNoPriceComponent,
    ProductCardInformationTapComponent,
    UserCardComponent,
    UserMatchesCardComponent,
    ProfileIconComponent,
    EditChildFormComponent,
    EditChildrenFormComponent,
    ChipSelectComponent,
    StrapiImageInputComponent,
    PaginatorComponent,
    DividerComponent,
    UserDetailedComponent,
    IconLabelComponent,
    MessagePreviewCardComponent,
    NotificationComponent,
    MessageCardComponent,
    SubscriptionCardComponent,
    UserSimpleCardComponent,
    PostHeaderComponent,
    CommentCardComponent,
    PostCardComponent,
    SidenavComponent,
    BigGroupCardComponent,
    RangeSliderComponent,
    SubscriptionContainerComponent,
    SubscriptionInviteContainerComponent,
    SubscriptionRequestsInviteContainerComponent,
    MatchedCardComponent,
    MatchedBigCardComponent,
    FriendRequestCardComponent,
    CameraComponent,
    AlgoliaTagSearchComponent,
    SpinnerComponent,
    BadgeNotificationComponent,
    GroupCardContainerComponent
  ], exports: [
    IconComponent,
    ImageLinkComponent,
    GroupCardComponent,
    UserProfileIconComponent,
    FilterContainerComponent,
    ChipComponent,
    SubscribeActionComponent,
    ProductCardComponent,
    ProductCardNoPriceComponent,
    UserCardComponent,
    UserMatchesCardComponent,
    ProfileIconComponent,
    EditChildFormComponent,
    EditChildrenFormComponent,
    ChipSelectComponent,
    ProductCardInformationTapComponent,
    StrapiImageInputComponent,
    PaginatorComponent,
    DividerComponent,
    UserDetailedComponent,
    IconLabelComponent,
    MessagePreviewCardComponent,
    NotificationComponent,
    MessageCardComponent,
    SubscriptionCardComponent,
    UserSimpleCardComponent,
    PostHeaderComponent,
    CommentCardComponent,
    PostCardComponent,
    SidenavComponent,
    BigGroupCardComponent,
    RangeSliderComponent,
    SubscriptionContainerComponent,
    SubscriptionInviteContainerComponent,
    SubscriptionRequestsInviteContainerComponent,
    MatchedCardComponent,
    MatchedBigCardComponent,
    FriendRequestCardComponent,
    CameraComponent,
    AlgoliaTagSearchComponent,
    SpinnerComponent,
    BadgeNotificationComponent,
    GroupCardContainerComponent
  ], imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    ReactiveFormsModule,
    MatInputModule,
    PipesModule,
    MatSidenavModule,
    MatListModule,
    NgxSliderModule,
    MatMenuModule,
    MatDialogModule,
    WebcamModule,
    MatAutocompleteModule,
    MatButtonModule,
    NgAisModule
  ],
})
export class AtomicModule {}
