import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() title: string;


  public showMobileMenu: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  public toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu;
  }

  public closeMenu() {
    this.showMobileMenu = false;
  }

}
