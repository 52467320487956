<ng-container *ngIf="matches$|async"></ng-container>
<app-my-page-top
  class="w-full bg-blue md:mb-[70px] mb-10 flex banner-image"
  [user]="me"
></app-my-page-top>
<app-subscribe-action
  class="w-full flex container md:flex-row md:pl-4"
  *ngIf="!activeSubscription"
></app-subscribe-action>
<div class="container w-full mb-[50px] md:mb-24 flex flex-col  md:mt-[60px] mt-10">
  <div class="w-full flex md:flex-row flex-col">
    <app-my-page-like-minded
      class="md:w-7/12 md:pr-12 w-full flex flex-col"
    ></app-my-page-like-minded>
    <app-my-page-notifications
      class="md:w-5/12 w-full flex flex-col mt-[50px] md:mt-0"
    ></app-my-page-notifications>
  </div>
  <a class="!underline text-xs text-right mt-3" *ngIf="matches?.length < 3" (click)="openDialogFriendRequests()">Afsendte venneanmodninger</a>
</div>
<app-my-page-matches [matches]="matches" class="bg-white flex"></app-my-page-matches>
<app-my-page-groups class="bg-cyan flex"></app-my-page-groups>
<app-my-page-support></app-my-page-support>
