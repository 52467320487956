import {ApiCollectionBase} from "../ApiCollectionBase";
import {BaseGetResourceApi} from "../BaseGetResourceApi";
import {GroupMember} from "../../../models/GroupMember";
import {Group, GroupMemberOld} from "../../../models";
import {BaseResourceApi} from "../BaseResourceApi";
import {GroupAttributes} from "../../../models/definitions/GroupDefinition";
import {StrapiResult} from "../../../models/StrapiResult";
import {PostsResponse} from "./posts-response";
import {StrapiComment} from "./strapi-comments";


export class GroupApi extends ApiCollectionBase {

  public membersCount(groupId: number): BaseGetResourceApi<{ count: number }[]> {
    return new BaseGetResourceApi<{count: number}[]>(this.config, {
      uri: `/groups/${groupId}/members_count`
    })
  }

  public requestStatus(groupId: number): BaseGetResourceApi<GroupMember[]> {
    return new BaseGetResourceApi<GroupMember[]>(this.config, {
      uri: `/groups/${groupId}/request_status`
    })
  }

  public get(): BaseGetResourceApi<Group> {
    return new BaseGetResourceApi<Group>(this.config, {
      resourceClass: Group,
      uri: '/groups'
    });
  }

  public getById(id: number): BaseResourceApi<Group> {
    return new BaseResourceApi<Group>(this.config, {
      uri: `/groups/${id}`,
      resourceClass: Group
    });
  }

  public post(): BaseResourceApi<GroupAttributes> {
    return new BaseResourceApi<GroupAttributes>(this.config, {
      method: 'post',
      uri: '/groups'
    });
  }

  public putById(id: number): BaseResourceApi<Group> {
    return new BaseResourceApi<Group>(this.config, {
      uri: `/groups/${id}`,
      resourceClass: Group,
      method: 'put'
    });
  }

  public put(): BaseResourceApi<Group> {
    return new BaseResourceApi<Group>(this.config, {
      uri: `/groups`,
      resourceClass: Group,
      method: 'put'
    });
  }

  public patchById(id: number): BaseResourceApi<GroupAttributes> {
    return new BaseResourceApi<GroupAttributes>(this.config, {
      uri: `/groups/${id}`,
      method: 'patch'
    });
  }

  public patch(): BaseResourceApi<Group> {
    return new BaseResourceApi<Group>(this.config, {
      uri: `/groups`,
      resourceClass: Group,
      method: 'patch'
    });
  }

  public deleteById(id: number): BaseResourceApi<Group> {
    return new BaseResourceApi<Group>(this.config, {
      uri: `/groups/${id}`,
      resourceClass: Group,
      method: 'delete'
    });
  }

  public joinPutById(id: number): BaseResourceApi<GroupMember> {
    return new BaseResourceApi<GroupMember>(this.config, {
      uri: `/groups/${id}/join`,
      method: 'put'
    });
  }

  public leavePutById(id: number): BaseResourceApi<Group> {
    return new BaseResourceApi<Group>(this.config, {
      uri: `/groups/${id}/leave`,
      resourceClass: Group,
      method: 'put'
    });
  }

  public membersGetGetById(id: number): BaseGetResourceApi<[StrapiResult<GroupMember>]> {
    return new BaseGetResourceApi<[StrapiResult<GroupMember>]>(this.config, {
      uri: `/groups/${id}/members`,
    });
  }

  public membersApprovePutByGroupIdByMemberId(groupId: number, strapiUserId: number): BaseResourceApi<GroupMemberOld> {
    return new BaseResourceApi<GroupMemberOld>(this.config, {
      uri: `/groups/${groupId}/members/${strapiUserId}/approve`,
      method: 'put',
      resourceClass: GroupMemberOld
    })
  }

  public membersDenyPutByGroupIdByMemberId(groupId: number, memberId: number): BaseResourceApi<GroupMemberOld> {
    return new BaseResourceApi<GroupMemberOld>(this.config, {
      uri: `/groups/${groupId}/members/${memberId}/deny`,
      method: 'put',
      resourceClass: GroupMemberOld
    })
  }

  public getPosts(groupId: number): BaseGetResourceApi<[PostsResponse]> {
    return new BaseGetResourceApi<[PostsResponse]>(this.config, {
      uri: `/groups/${groupId}/posts`
    })
  }
  public getComments(groupId: number, postId: number): BaseGetResourceApi<[StrapiResult<StrapiComment>]> {
    return new BaseGetResourceApi<[StrapiResult<StrapiComment>]>(this.config, {
      uri: `/groups/${groupId}/posts/${postId}/comments`
    })
  }
  public newPost(groupId: number): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/groups/${groupId}/posts`,
      method: 'post'
    })
  }
  public newComment(postId: number, groupId: number): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/groups/${groupId}/posts/${postId}/comments`,
      method: 'post'
    })
  }

  public deletePost(postId: number, groupId): BaseResourceApi<[any]> {
    return new BaseResourceApi(this.config, {
      uri: `/groups/${groupId}/posts/${postId}`,
      method: 'delete'
    })
  }

}
