import { ApiCollectionBaseConfig } from './ApiCollectionBase';
import { BaseApi, RequestMethod } from './BaseApi';
import { Observable } from 'rxjs';

export interface BaseResourceApiProps<T> {
  resourceClass?: new (...args: any[]) => T;
  uri: string;
  topic?: string;
  method?: RequestMethod;
  summary?: string;
}

export class BaseResourceApi<T, QueryParams = { [key: string]: any }> extends BaseApi<T, QueryParams> {

  protected scope = '';
  protected summary = '';

  constructor(
    config: ApiCollectionBaseConfig,
    protected props: BaseResourceApiProps<T>
  ) {
    super(config)
    this.method = this.props.method || 'get';
    this.summary = this.props.summary || '';
    this.topic = this.props.topic || `${this.method}.${this.uri.replace(/\//, '.')}`;
  }

  protected convertToResource(data: any): T {
    const classObject = this.resourceClass;
    if (!classObject) {
      return data;
    }
    return new classObject(data);
  }

  get resourceClass(): any {
    return this.props.resourceClass;
  }

  get uri(): string {
    return this.props.uri;
  }
}
