<div class="rounded-full bg-cyan h-full w-full flex items-center justify-center overflow-hidden">
  <app-icon *ngIf="icon && !imgURL" [icon]="icon" class="stroke-green"></app-icon>
  <img 
    *ngIf="imgURL"
    class="rounded-full max-w-none h-full w-full object-cover" 
    (load)="loadImage()"
    [src]="isLoadingImage ? '/assets/images/spinners/barnevogn_orange.png' : imgURL" 
    [alt]="icon"
  >
</div>
