/**
 * Created by ModelParser
 * Date: 11-06-2020.
 * Time: 12:26.
 */
import { NotificationDefinition, NotificationDefinitionInterface } from './definitions/NotificationDefinition';

export interface NotificationInterface extends NotificationDefinitionInterface {

}

export class Notification extends NotificationDefinition implements NotificationInterface {

    constructor(json?: any) {
        super(json);
    }

}
