/**
 * Created by ModelParser
 */
import { Attendee, AttendeeInterface } from '../Attendee';
import { Attachment, AttachmentInterface } from '../Attachment';
import { Category, CategoryInterface } from '../Category';
import { CategoriesEvent, CategoriesEventInterface } from '../CategoriesEvent';
import { Tag, TagInterface } from '../Tag';
import { EventsTag, EventsTagInterface } from '../EventsTag';
import { File, FileInterface } from '../File';
import { Interest } from '../Interest';
import { User, UserInterface } from '../User';
import { BaseStoredModel } from '../BaseStoredModel';
import { AddressDefinitionInterface } from './AddressDefinition';
import { StrapiModel } from '../StrapiModel';
import { InterestAttributes } from './InterestDefinition';

export interface EventDefinitionInterface {
    google_id?: string;
    status?: string;
    summary?: string;
    description?: string;
    start?: string;
    end?: string;
    location?: string;
    zoom_link?: string;
    spots?: number;
    price?: number;
    address_id?: number;
    address?: AddressDefinitionInterface;
    attendees?: AttendeeInterface[];
    attachments?: AttachmentInterface[];
    categories?: CategoryInterface[];
    categories_events?: CategoriesEventInterface[];
    tags?: TagInterface[];
    events_tags?: EventsTagInterface[];
    files?: FileInterface[];
    interests?: StrapiModel<InterestAttributes>[];
    attendee_count?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: UserInterface;
    updated_by_id?: number;
    updated_by?: UserInterface;
}

export class EventDefinition extends BaseStoredModel implements EventDefinitionInterface {
    google_id?: string;
    status?: string;
    summary?: string;
    description?: string;
    start?: string;
    end?: string;
    location?: string;
    zoom_link?: string;
    spots?: number;
    price?: number;
    address_id?: number;
    address?: AddressDefinitionInterface;
    attendees?: Attendee[];
    attachments?: Attachment[];
    categories?: Category[];
    categories_events?: CategoriesEvent[];
    tags?: Tag[];
    events_tags?: EventsTag[];
    files?: File[];
    interests?: Interest[];
    attendee_count?: number;
    id?: number;
    created?: string;
    updated?: string;
    created_by_id?: number;
    created_by?: User;
    updated_by_id?: number;
    updated_by?: User;

    constructor(data?: any) {
        super();
        this.populate(data);
    }

    public populate(data?: any, patch: boolean = false) {
        if (!patch) {
            delete this.google_id;
            delete this.status;
            delete this.summary;
            delete this.description;
            delete this.start;
            delete this.end;
            delete this.location;
            delete this.zoom_link;
            delete this.spots;
            delete this.price;
            delete this.address_id;
            delete this.address;
            delete this.attendees;
            delete this.attachments;
            delete this.categories;
            delete this.categories_events;
            delete this.tags;
            delete this.events_tags;
            delete this.files;
            delete this.interests;
            delete this.attendee_count;
            delete this.id;
            delete this.created;
            delete this.updated;
            delete this.created_by_id;
            delete this.created_by;
            delete this.updated_by_id;
            delete this.updated_by;
        }

        if (!data) return;
        if (data.google_id != null) {
            this.google_id = data.google_id;
        }
        if (data.status != null) {
            this.status = data.status;
        }
        if (data.summary != null) {
            this.summary = data.summary;
        }
        if (data.description != null) {
            this.description = data.description;
        }
        if (data.start != null) {
            this.start = data.start;
        }
        if (data.end != null) {
            this.end = data.end;
        }
        if (data.location != null) {
            this.location = data.location;
        }
        if (data.zoom_link != null) {
            this.zoom_link = data.zoom_link;
        }
        if (data.spots != null) {
            this.spots = data.spots;
        }
        if (data.price != null) {
            this.price = data.price;
        }
        if (data.address_id != null) {
            this.address_id = data.address_id;
        }
        if (data.address != null) {
            this.address = {};
        }
        if (data.attendees != null) {
            this.attendees = data.attendees.map((i: any) => new Attendee(i));
        }
        if (data.attachments != null) {
            this.attachments = data.attachments.map((i: any) => new Attachment(i));
        }
        if (data.categories != null) {
            this.categories = data.categories.map((i: any) => new Category(i));
        }
        if (data.categories_events != null) {
            this.categories_events = data.categories_events.map((i: any) => new CategoriesEvent(i));
        }
        if (data.tags != null) {
            this.tags = data.tags.map((i: any) => new Tag(i));
        }
        if (data.events_tags != null) {
            this.events_tags = data.events_tags.map((i: any) => new EventsTag(i));
        }
        if (data.files != null) {
            this.files = data.files.map((i: any) => new File(i));
        }

        if (data.interests != null) {
            this.interests = data.interests.map((i: any) => new Interest(i));
        }
        if (data.attendee_count != null) {
            this.attendee_count = data.attendee_count;
        }
        if (data.id != null) {
            this.id = data.id;
        }
        if (data.created != null) {
            this.created = data.created;
        }
        if (data.updated != null) {
            this.updated = data.updated;
        }
        if (data.created_by_id != null) {
            this.created_by_id = data.created_by_id;
        }
        if (data.created_by != null) {
            this.created_by = new User(data.created_by);
        }
        if (data.updated_by_id != null) {
            this.updated_by_id = data.updated_by_id;
        }
        if (data.updated_by != null) {
            this.updated_by = new User(data.updated_by);
        }
    }

}
