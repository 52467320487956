<div class="flex items-end mb-5">
  <h3 class="text-lg font-semibold">Notifikationer</h3>
  <ng-container *ngIf="notificationData$|async as notification">
    <app-badge-notification [count]="notification.countFeed"></app-badge-notification>
  </ng-container>
</div>
<div class="w-full shadow-border rounded flex-grow h-[466px]">
  <ng-container *ngIf="notificationData$|async as notification">
    <div class="flex w-full h-full flex-col md:p-7 p-4" *ngIf="notification.feedList?.length > 0">
      <div class="flex-grow overflow-auto pretty-scrollbar pretty-scrollbar-green">
        <app-notification *ngFor="let ac of notification.feedList; trackBy: trackBy()" (click)="onNotificationClick(ac)"
          [notification]="activityToNotification(ac, users[ac.data?.userId])"></app-notification>
      </div>
    </div>
    <div class="justify-center items-center flex w-full h-full" *ngIf="!notification.feedList?.length">
      <div class="py-10">
        <img src="/assets/logo-icon.svg" class="w-40 opacity-20">
        <p class="text-center m-0 font-light text-sm">Du har ingen notifikationer</p>
      </div>
    </div>
  </ng-container>
</div>
