<div class="container flex items-center pt-9 pb-6 md:py-1.5">
  <div class="transform md:translate-y-10">
    <a routerLink="/profile/settings/user">
      <app-user-profile-icon class="inline-block md:h-[166px] md:w-[166px] h-[118px] w-[118px]" [user]="user"></app-user-profile-icon>
    </a>
  </div>
  <div class="md:pl-[50px] pl-5 flex flex-col">
    <a routerLink="/profile/settings/user">
      <h4 class="capitalize text-white font-semibold mb-2">{{user?.first_name}} {{user?.last_name}}</h4>
    </a>
    <span class="text-white m-0 flex items-center text-sm" *ngIf="user?.address?.city">
      <app-icon class="h-4 w-3 mr-2.5 fill-white" [icon]="'location'"></app-icon>
      <a routerLink="/profile/settings/user">
        <span>{{user?.address?.city}}</span>
      </a>
    </span>
    <a routerLink="/profile/settings/user" class="mt-2 text-white text-xs">Se profil</a>
  </div>
</div>
